import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DragNDrop } from "../../../components";
import { createVisitSheet, editarVisitSheet, getFinca, getSale, getVisitSheet } from "../../../services";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import { comprimirImagen, formatoFechas } from "../../../functions/funciones";
import { toast } from "react-toastify";
import CustomImageList from "../../../components/ImageList/CustomImageList";

const LaeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#92004D",
    "&:hover": {
      backgroundColor: alpha("#92004D", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#92004D",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
}));

const TIPOS_EDIFICIO = [
  {
    value: "edificio",
    label: "EDIFICIO",
  },
  {
    value: "garage",
    label: "GARAGE",
  },
];

const SubQuestion = ({ question, checkBind, onChange, noTakes }) => {
  // console.log(checkBind)
  const [noTakesTrue, setNoTakesTrue] = useState(false);
  return (
    <Box className={`lae-flex lae-flex-column`} sx={{ gap: 0 }}>
      <Box className="lae-flex lae-flex-row lae_alignItems-center lae-justifyContent-spaceBetween">
        <Box sx={{ flex: 5 }}>
          <Typography>{question}</Typography>
        </Box>

        {!noTakesTrue && (
          <Box
            className="lae-flex lae-flex-row lae_alignItems-center lae-justifyContent-spaceBetween"
            sx={{ gap: 1, flex: 5 }}
          >
            <RadioGroup
              aria-labelledby="controlled"
              // defaultValue={true}
              value={checkBind === "no" ? false : checkBind === "si" ? true : ""}
              sx={{ flexDirection: "row" }}
              onChange={(e) => {
                const checked = { target: { checked: e.target.value } };

                console.log(e.target.value)
                // if (e.target.value === "") {
                //   checked.target.checked = false;
                // }
                onChange(checked);
              }}
            >
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      color: "#92004D",
                      "&.Mui-checked": {
                        color: "#92004D",
                      },
                    }}
                  />
                }
                label="No"
              />
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    sx={{
                      color: "#92004D",
                      "&.Mui-checked": {
                        color: "#92004D",
                      },
                    }}
                  />
                }
                label="Si"
              />
              {noTakes && (
                <FormControlLabel
                  value={""}
                  control={
                    <Radio
                      sx={{
                        color: "#92004D",
                        "&.Mui-checked": {
                          color: "#92004D",
                        },
                      }}
                    />
                  }
                  label="No Procede"
                />
              )}
            </RadioGroup>
            {/* <LaeSwitch
              defaultValue={false}
              checked={checkBind}
              inputProps={{
                "aria-label": "controlled",
              }}
              onChange={onChange}
            />
            {checkBind ? "Si" : "No"} */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

function Comunidad( props ) {
  const [formData, setFormData] = useState({});

  const user = useContext(usuarioContext)
  const [additionalComments, setAdditionalComments] = useState("");

  const [imagenComunidad, setImagenComunidad] = useState(null);
  const [imagenUrl, setImagenUrl] = useState(null);

  
  const [imagenesComunidad, setImagenesComunidad] = useState([]);
  const [imagenesUrl, setImagenesUrl] = useState([]);
  const [imagenesNew, setImagenesNew] = useState([]);
  const [finca, setFinca] = useState({});
  const [recargar, setRecargar] = useState({});
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [numImages, setNumImages] = useState(0);

  useEffect(() => {
    fetchData();
  },[recargar]);

  const fetchData = async () => {
    const resSale = await getSale(props.params.id);
    const resVisitSheet = await getVisitSheet(resSale.detailsCae[0].visitSheet_id);
    const { data } = resVisitSheet;

    setImagenUrl(`${process.env.REACT_APP_PUBLIC_URL}${data.img.replace('/storage', '')}`);

    let ArrImagenes = [];
    
    data.images.forEach( (imgURL, i) => {
      ArrImagenes.push({
        indice: i,
        id:imgURL.id,
        img:`${process.env.REACT_APP_PUBLIC_URL}${imgURL.ruta.replace('/storage', '')}`
      });
    });
    setImagenesUrl(ArrImagenes);
    setNumImages(ArrImagenes.length)

    setFormData((prev) => ({
      ...prev,
      visitSheet_id: data.id,
      sale_id: Number(props.params.id),
      aaff_id: data.aaff.id,
      IdComunidad: data.propertie?.id,
      comunidad: data.propertie?.razonSocial,
      IdTecnicoPRL: user.id,
      tecnicoPRL: user.nombre+" "+user.apellidos,
      saleDate: formatoFechas(new Date()),
      comentario: data.comentario,
      edificio: "edificio",
      fechaVisita: formatoFechas(new Date(data.createdAt)),
      numeroPlantas: data.nPlantas,
      p1_0: data.questions.p1_0 === null ? "no" : data.questions.p1_0,
      p1_1: data.questions.p1_1 === null ? "no" : data.questions.p1_1,
      p1_2: data.questions.p1_2 === null ? "no" : data.questions.p1_2,
      p1_3: data.questions.p1_3 === null ? "no" : data.questions.p1_3,
      p1_4: data.questions.p1_4 === null ? "no" : data.questions.p1_4,
      p1_5: data.questions.p1_5 === null ? "no" : data.questions.p1_5,
      p1_6: data.questions.p1_6 === null ? "no" : data.questions.p1_6,
      p1_7: data.questions.p1_7 === null ? "no" : data.questions.p1_7,
      p1_8: data.questions.p1_8 === null ? "no" : data.questions.p1_8,
      p2_0: data.questions.p2_0 === null ? "no" : data.questions.p2_0,
      p2_1: data.questions.p2_1 === null ? "no" : data.questions.p2_1,
      p2_2: data.questions.p2_2 === null ? "no" : data.questions.p2_2,
      p2_3: data.questions.p2_3 === null ? "no" : data.questions.p2_3,
      p2_4: data.questions.p2_4 === null ? "no" : data.questions.p2_4,
      p2_5: data.questions.p2_5 === null ? "no" : data.questions.p2_5,
      p2_6: data.questions.p2_6 === null ? "no" : data.questions.p2_6,
      p2_7: data.questions.p2_7 === null ? "no" : data.questions.p2_7,
      p3_0: data.questions.p3_0 === null ? "no" : data.questions.p3_0,
      p3_1: data.questions.p3_1 === null ? "no" : data.questions.p3_1,
      p3_2: data.questions.p3_2 === null ? "no" : data.questions.p3_2,
      p3_3: data.questions.p3_3 === null ? "no" : data.questions.p3_3,
      p3_4: data.questions.p3_4 === null ? "no" : data.questions.p3_4,
      p4_0: data.questions.p4_0 === null ? "no" : data.questions.p4_0,
      p4_1: data.questions.p4_1 === null ? "no" : data.questions.p4_1,
      p4_2: data.questions.p4_2 === null ? "no" : data.questions.p4_2,
      p4_3: data.questions.p4_3 === null ? "no" : data.questions.p4_3,
      p4_4: data.questions.p4_4 === null ? "no" : data.questions.p4_4,
      p4_5: data.questions.p4_5 === null ? "no" : data.questions.p4_5,
      p4_6: data.questions.p4_6 === null ? "no" : data.questions.p4_6,
      p4_7: data.questions.p4_7 === null ? "no" : data.questions.p4_7,
      p4_8: data.questions.p4_8 === null ? "no" : data.questions.p4_8,
      p4_9: data.questions.p4_9 === null ? "no" : data.questions.p4_9,
      p4_10: data.questions.p4_10 === null ? "no" : data.questions.p4_10,
      p4_11: data.questions.p4_11 === null ? "no" : data.questions.p4_11,
      p4_12: data.questions.p4_12 === null ? "no" : data.questions.p4_12,
      p4_13: data.questions.p4_13 === null ? "no" : data.questions.p4_13,
      p4_14: data.questions.p4_14 === null ? "no" : data.questions.p4_14,
      p4_15: data.questions.p4_15 === null ? "no" : data.questions.p4_15,
      p5_0: data.questions.p5_0 === null ? "no" : data.questions.p5_0,
      p5_1: data.questions.p5_1 === null ? "no" : data.questions.p5_1,
      p5_2: data.questions.p5_2 === null ? "no" : data.questions.p5_2,
      p5_3: data.questions.p5_3 === null ? "no" : data.questions.p5_3,
      p6_0: data.questions.p6_0 === null ? "no" : data.questions.p6_0,
      p6_1: data.questions.p6_1 === null ? "no" : data.questions.p6_1,
      p6_2: data.questions.p6_2 === null ? "no" : data.questions.p6_2,
      p6_3: data.questions.p6_3 === null ? "no" : data.questions.p6_3,
      p7_0: data.questions.p7_0 === null ? "no" : data.questions.p7_0,
      p7_1: data.questions.p7_1 === null ? "no" : data.questions.p7_1,
      p7_2: data.questions.p7_2 === null ? "no" : data.questions.p7_2,
      p7_3: data.questions.p7_3 === null ? "no" : data.questions.p7_3,
      p7_4: data.questions.p7_4 === null ? "no" : data.questions.p7_4,
      p7_5: data.questions.p7_5 === null ? "no" : data.questions.p7_5,
      p8_0: data.questions.p8_0 === null ? "no" : data.questions.p8_0,
      p8_1: data.questions.p8_1 === null ? "no" : data.questions.p8_1,
      p8_2: data.questions.p8_2 === null ? "no" : data.questions.p8_2,
      p9_0: data.questions.p9_0 === null ? "no" : data.questions.p9_0,
      p9_1: data.questions.p9_1 === null ? "no" : data.questions.p9_1,
      p9_2: data.questions.p9_2 === null ? "no" : data.questions.p9_2,
      p10_0: data.questions.p10_0 === null ? "no" : data.questions.p10_0,
      p10_1: data.questions.p10_1 === null ? "no" : data.questions.p10_1,
      p10_2: data.questions.p10_2 === null ? "no" : data.questions.p10_2,
      p10_3: data.questions.p10_3 === null ? "no" : data.questions.p10_3,
      p10_4: data.questions.p10_4 === null ? "no" : data.questions.p10_4,
      p10_5: data.questions.p10_5 === null ? "no" : data.questions.p10_5,
      p10_6: data.questions.p10_6 === null ? "no" : data.questions.p10_6,
      p10_7: data.questions.p10_7 === null ? "no" : data.questions.p10_7,
      p10_8: data.questions.p10_8 === null ? "no" : data.questions.p10_8,
      p10_9: data.questions.p10_9 === null ? "no" : data.questions.p10_9,
      p10_10: data.questions.p10_10 === null ? "no" : data.questions.p10_10,
      p10_11: data.questions.p10_11 === null ? "no" : data.questions.p10_11,
      p10_12: data.questions.p10_12 === null ? "no" : data.questions.p10_12,
      p10_13: data.questions.p10_13 === null ? "no" : data.questions.p10_13,
      p10_14: data.questions.p10_14 === null ? "no" : data.questions.p10_14,
      p10_15: data.questions.p10_15 === null ? "no" : data.questions.p10_15,
      p10_16: data.questions.p10_16 === null ? "no" : data.questions.p10_16,
      p10_17: data.questions.p10_17 === null ? "no" : data.questions.p10_17,
      p10_18: data.questions.p10_18 === null ? "no" : data.questions.p10_18,
      p10_19: data.questions.p10_19 === null ? "no" : data.questions.p10_19,
      p10_20: data.questions.p10_20 === null ? "no" : data.questions.p10_20,
      p10_21: data.questions.p10_21 === null ? "no" : data.questions.p10_21,
    }));
  };
  const handleUploadImg = async (files) => {
    console.log(files[0])
    const firstImage = files[0];

    // Crear una URL de objeto para la imagen seleccionada
    // const imageUrl = URL.createObjectURL(firstImage);
    const imageUrl = await comprimirImagen(firstImage, 14).then((res) => {
      return URL.createObjectURL(res);
    })
    // Actualizar el estado con la URL de la imagen
    setImagenComunidad(firstImage);
    setImagenUrl(imageUrl);
  };
  const handleUploadImgs = async (files) => {
    const ultimoId = imagenesUrl.length > 0 ? imagenesUrl[imagenesUrl.length - 1].indice+1 : 0;
    
    const nuevasImagenes = [];
    await Promise.all(
      files.map((file) => {
        return comprimirImagen(file, 14).then((res) => {
          console.log(file);
          console.log(res);
          nuevasImagenes.push(res);
          return URL.createObjectURL(res);
        });
      })
      );
      
      setImagenesComunidad((prevImagenes) => [...prevImagenes, ...files]);
      console.log(nuevasImagenes)
    setImagenesUrl(prevUrls => 
      [
        ...prevUrls, 
        ...nuevasImagenes.map((image, i) => { return { indice: ultimoId+i, id:ultimoId+i, img:URL.createObjectURL(image) } } )
      ]
    );
  };
  
  const handleSubmit = async () => {
    let toastInfo = toast.info("Guardando datos", { autoClose: false });
    const datos = { ...formData };
    console.log(imagenesComunidad)
    const res = await editarVisitSheet(datos, imagesToDelete, imagenesComunidad, imagenComunidad, props.params.nif);
    if(res.status === 200 ){
      toast.update(toastInfo, {
        render: "Datos guardados correctamente",
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
    }else{
      toast.update(toastInfo, {
        render: "Error al guardar los datos",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    }
  };
  const addImagesToDelete = (image) => {

    // const nuevosImagenesUrl = imagenesUrl.filter((imagen) => imagen.indice !== image.indice);
    // // const indiceEnArchivos = imagenesComunidad.findIndex((archivo) => archivo.name === nuevosImagenesUrl[image.indice].img.split('/').pop());
    // const indiceEnArchivos = imagenesComunidad.find((archivo) => archivo.name === nuevosImagenesUrl[image.indice].img.split('/').pop());
    
    // console.log(imagenesComunidad.find((archivo) => archivo.name), nuevosImagenesUrl[image.indice].img.split('/').pop())
    // if (indiceEnArchivos !== -1) {
    //   const nuevosArchivos = [...imagenesComunidad];
    //   console.log(nuevosArchivos)
    //   nuevosArchivos.splice(indiceEnArchivos, 1);

    //   console.log(nuevosArchivos)
    //   setImagenesComunidad(nuevosArchivos);
    // }
    console.log(numImages)
    console.log(image)
    const imgElement = document.querySelector(`.img-${image.indice}`);
    console.log(imagenesComunidad)
    if (imgElement) {
      const nuevosDatos = imagenesUrl.filter(item => item.indice !== image.indice);
      const l = [...imagenesComunidad]
      console.log(l)
      // console.log(image.indice)
      l.splice(image.indice-numImages, 1)
      setImagenesComunidad(l)
      setImagenesUrl(nuevosDatos)
      setTimeout(() => {

        imgElement.remove();
        setImagesToDelete((prevs) => [...prevs, {indice: image.indice, id:image.id,path:image.img}]);
      }, 200)
    }
    
  };

  return (
    <>
      <Box className="pageHeader" sx={{ backgroundColor: "#f6f7fb" }}>
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Hoja de Visita
        </Typography>
      </Box>
      <Box className="pageContent comunidad-container centered" sx={{ p: 3 }}>
        <Typography
          variant="h6"
          color="#212529"
          fontWeight={"bold"}
          sx={{ mb: 4 }}
        >
          Datos de la Comunidad
        </Typography>
        {/* INFO_COMUNIDAD */}
        <Box className="lae-flex lae-flex-row lae-flex-between" sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              flex: 1,
            }}
          >
            <Box className="lae-flex lae-flex-row">
              <TextField
                id="outlined-select-currency"
                select
                label="Tipo de edificio"
                defaultValue="edificio"
                /* helperText="Seleccione el tipo de edificio" */
                fullWidth
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    edificio: e.target.value,
                  }))
                }
              >
                {TIPOS_EDIFICIO.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                id="outlined-select-community"
                label="Comunidad"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.comunidad}
                disabled
                // defaultValue={formData.comunidad}
              ></TextField>
            </Box>
            <Box className="lae-flex lae-flex-row">
              <TextField
                id="outlined-select-plantas-number"
                label="Nº de plantas"
                fullWidth
                value={formData.numeroPlantas}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    numeroPlantas: e.target.value,
                  }))
                }
              ></TextField>
              <TextField
                id="outlined-select-tecnico-prl"
                label="Técnico PRL"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.tecnicoPRL}
                disabled
                style={{ webkitTextFillColor: 'rgba(0, 0, 0, 0.8)' }}
                // defaultValue={formData.comunidad}
              ></TextField>
            </Box>

            <TextField 
              id="outlined-select-date"
              type="date"
              label="Fecha Visita" 
              InputLabelProps={{ shrink: true }}
              value={formData.fechaVisita}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  fechaVisita: e.target.value,
                }))
              }
            ></TextField>
          </Box>
          <Box
            className="lae-flex lae-flex-column pos-relative"
            sx={{
              flex: 1,
            }}
          >
            <DragNDrop
              onUpload={(files) => {handleUploadImg(files)}}
              count={1}
              formats={["jpg", "png", "svg"]}
              label={
                <Typography variant="caption">Imagen Comunidad</Typography>
              }
              title='"Suelta o elige un archivo..."'
              openDialogOnClick
            />
            {imagenUrl !== null && 
              <img src={imagenUrl} alt={`Imagen principal`} style={{ maxWidth: '100%', height: '350px' }} />
              // <div style={{ paddingTop: '66.66%', backgroundImage: `url(${imagenUrl})`, backgroundPosition: 'center', backgroundSize: 'cover' }} ></div>
            }

            <DragNDrop
              onUpload={(files) => handleUploadImgs(files)}
              count={10}
              formats={["jpg", "png", "svg"]}
              label={
                <Typography variant="caption">Imagenes Comunidad</Typography>
              }
              title='"Suelta o elige un archivo..."'
              openDialogOnClick
            />
            <Box>
              <CustomImageList imgs = {imagenesUrl} addImagesToDelete={addImagesToDelete}/>
              {/* {imagenesUrl.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Imagen ${index + 1}`} style={{ maxWidth: '100%', width:'150px' }} />
              ))} */}
            </Box>
          </Box>
        </Box>
        {/* QUESTIONS */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography
            variant="h6"
            color="#212529"
            fontWeight={"bold"}
            sx={{ mb: 4 }}
          >
            Riesgos del edificio generales
          </Typography>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="1.- ¿El estado general del edificio es correcto?"
                checkBind={formData.p1_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p1_0: e.target.checked === "true" ? "si" : "no",
                  }))
                }
                // noTakes
              />
            </Box>
            
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="1.1.- ¿Hay riesgo de desprendimiento de baldosas o elementos?"
                  checkBind={formData.p1_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_1: formData.p1_0 !== null ? e.target.checked === "true" ? "si" : "no": null,
                    }))
                  }
                />

                <SubQuestion
                  question="1.2.- ¿Hay cristales rotos o en mal estado de puertas/ventanas?"
                  checkBind={formData.p1_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="1.3.- ¿Hay baldosas rotas o ausencia de las mismas?"
                  checkBind={formData.p1_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
                <SubQuestion
                  question="1.4.- ¿Hay cables eléctricos sueltos?"
                  checkBind={formData.p1_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_4: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="1.5.- ¿Las cajas de registro están abiertas?"
                  checkBind={formData.p1_5}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_5: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="1.6.- ¿Hay riesgo de deslizamiento en las rampas de acceso?"
                  checkBind={formData.p1_6}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_6: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="1.7.- ¿El cuadro eléctrico se encuentra abierto?"
                  checkBind={formData.p1_7}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_7: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="1.8.- ¿El cuadro eléctrico se encuentra señalizado?"
                  checkBind={formData.p1_8}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p1_8: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="2.- ¿La comunidad dispone de ascensores?"
                checkBind={formData.p2_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p2_0: e.target.checked === "true" ? "si" : "no",
                    p2_1: e.target.checked === "true" ? "no" : null,
                    p2_2: e.target.checked === "true" ? "no" : null,
                    p2_3: e.target.checked === "true" ? "no" : null,
                    p2_4: e.target.checked === "true" ? "no" : null,
                    p2_5: e.target.checked === "true" ? "no" : null,
                    p2_6: e.target.checked === "true" ? "no" : null,
                    p2_7: e.target.checked === "true" ? "no" : null,
                    p2_8: e.target.checked === "true" ? "no" : null,
                    p2_9: e.target.checked === "true" ? "no" : null,
                    p2_10: e.target.checked === "true" ? "no" : null,
                  }))
                }
              />
            </Box>

            {formData.p2_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="2.1. ¿Están revisados?"
                  checkBind={formData.p2_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="2.2. ¿Dispone de iluminación 24h interior?"
                  checkBind={formData.p2_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="2.3. ¿Dispone de iluminación 24h exterior?"
                  checkBind={formData.p2_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="2.4. ¿Dispone de alarma?"
                  checkBind={formData.p2_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_4: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="2.5. ¿Está cerrada la sala de máquinas?"
                  checkBind={formData.p2_5}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_5: e.target.checked === "true" ? "si" : "no",
                      p2_6: e.target.checked === "false" ? "no" : null,
                      p2_7: e.target.checked === "false" ? "no" : null,
                      p2_8: e.target.checked === "false" ? "no" : null,
                    }))
                  }
                />
                {formData.p2_5 === "no" && 
                  <>
                    <SubQuestion
                      question="2.6. ¿Está protegido el motor de la sala de máquinas?"
                      checkBind={formData.p2_6}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          p2_6: e.target.checked === "true" ? "si" : "no",
                        }))
                      }
                    />

                    <SubQuestion
                      question='2.7. ¿Dispone de señalización de riesgo eléctrico?'
                      checkBind={formData.p2_7}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          p2_7: e.target.checked === "true" ? "si" : "no",
                        }))
                      }
                    />
                    <SubQuestion
                      question='2.8. ¿Existe dentro de la sala de máquinas extintor?'
                      checkBind={formData.p2_8}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          p2_8: e.target.checked === "true" ? "si" : "no",
                        }))
                      }
                    />
                  </>
                }
                <SubQuestion
                  question='2.9. ¿Dispone de extintor en el exterior de la sala de máquinas?'
                  checkBind={formData.p2_9}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_9: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
                <SubQuestion
                  question='2.10. ¿Existe un cartel en las inmediaciones del cuarto de máquinas con el texto:
                  "Cuarto de maquinaria del ascensor. Peligro. Se prohíbe la entrada a toda persona ajena al servicio."?'
                  checkBind={formData.p2_10}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p2_10: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="3.- ¿Dispone de azotea transitable?"
                checkBind={formData.p3_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p3_0: e.target.checked === "true" ? "si" : "no",
                    p3_1: e.target.checked === "true" ? "no" : null,
                    p3_2: e.target.checked === "true" ? "no" : null,
                    p3_3: e.target.checked === "true" ? "no" : null,
                    p3_4: e.target.checked === "true" ? "no" : null,
                  }))
                }
              />
            </Box>

            {formData.p3_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="3.1. ¿El acceso a la azotea se realiza de forma segura?"
                  checkBind={formData.p3_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p3_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="3.2. ¿Si el acceso es mediante escaleras, se accede de forma segura?"
                  checkBind={formData.p3_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p3_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="2.3. ¿Si el acceso es mediante escaleras, se accede de forma segura?"
                  checkBind={formData.p3_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p3_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="3.4. ¿Dispone de anclajes para la línea de vida?"
                  checkBind={formData.p3_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p3_4: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="4.- ¿Dispone de parking?"
                checkBind={formData.p4_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p4_0: e.target.checked === "true" ? "si" : "no",
                    p4_1: e.target.checked === "true" ? "no" : null,
                    p4_2: e.target.checked === "true" ? "no" : null,
                    p4_3: e.target.checked === "true" ? "no" : null,
                    p4_4: e.target.checked === "true" ? "no" : null,
                    p4_5: e.target.checked === "true" ? "no" : null,
                    p4_6: e.target.checked === "true" ? "no" : null,
                    p4_7: e.target.checked === "true" ? "no" : null,
                    p4_8: e.target.checked === "true" ? "no" : null,
                    p4_9: e.target.checked === "true" ? "no" : null,
                    p4_10: e.target.checked === "true" ? "no" : null,
                    p4_11: e.target.checked === "true" ? "no" : null,
                    p4_12: e.target.checked === "true" ? "no" : null,
                    p4_13: e.target.checked === "true" ? "no" : null,
                    p4_14: e.target.checked === "true" ? "no" : null,
                    p4_15: e.target.checked === "true" ? "no" : null,
                  }))
                }
              />
            </Box>

            {formData.p4_0  === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="4.1. ¿Dispone de número suficiente de extintores? (aproximadamente uno cada 15 metros de recorrido hasta la salida más cercana)"
                  checkBind={formData.p4_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.2. ¿Están correctamente revisados los extintores? (si está caducada la fecha indicarlo en observaciones)"
                  checkBind={formData.p4_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.3. ¿Están correctamente señalizados los extintores?"
                  checkBind={formData.p4_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.4. ¿Están colgados a una altura adecuada los extintores?"
                  checkBind={formData.p4_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_4: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.5. ¿Están en buen estado de conservación los extintores?"
                  checkBind={formData.p4_5}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_5: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.6. ¿Están señalizados los recorridos de evacuación?"
                  checkBind={formData.p4_6}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_6: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.7. ¿Están señalizadas las salidas de emergencia?"
                  checkBind={formData.p4_7}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_7: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="4.8. ¿Están señalizadas las direcciones de circulación (+500m²)?"
                  checkBind={formData.p4_8}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_8: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />

                <SubQuestion
                  question="4.9. ¿Están señalizadas las puertas de entrada y salida (2 puertas de acceso)?"
                  checkBind={formData.p4_9}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_9: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
                <SubQuestion
                  question="4.10. ¿Dispone de sistemas automáticos de extinción de incendios (rociadores) (+500m² o 2 pisos)?"
                  checkBind={formData.p4_10}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_10: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />

                <SubQuestion
                  question="4.11. ¿Dispone de sistemas de extracción de humos (+500m² o 2 pisos)?"
                  checkBind={formData.p4_11}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_11: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />

                <SubQuestion
                  question="4.12. ¿Dispone de BIE's?"
                  checkBind={formData.p4_12}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_12: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />

                <SubQuestion
                  question="4.13. ¿Dispone de detectores de humo?"
                  checkBind={formData.p4_13}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_13: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    }))
                  }
                  noTakes
                />

                <SubQuestion
                  question="4.14. ¿El cuadro eléctrico está cerrado/protegido?"
                  checkBind={formData.p4_14}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_14: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question='4.15. ¿Está señalizado el cuadro eléctrico con el panel de "Riesgo eléctrico"?'
                  checkBind={formData.p4_15}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p4_15: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="5.- ¿Dispone de jardin?"
                checkBind={formData.p5_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p5_0: e.target.checked === "true" ? "si" : "no",
                    p5_1: e.target.checked === "true" ? "no" : null,
                    p5_2: null,
                    p5_3: null,
                  }))
                }
              />
            </Box>
            {formData.p5_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="5.1. ¿Se almacenan productos fitosanitarios?"
                  checkBind={formData.p5_1}
                  onChange={(e) =>{
                    setFormData((prev) => ({
                      ...prev,
                      p5_1: e.target.checked === "true" ? "si" : "no",
                      p5_2: e.target.checked === "false" ? null : "no",
                      p5_3: null,
                    }))
                  }}
                />
                {formData.p5_1 === "si" &&
                  <SubQuestion
                    question="5.2. ¿Se guardan los productos bajo llave?"
                    checkBind={formData.p5_2}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        p5_2: e.target.checked === "true" ? "si" : "no",
                        p5_3: e.target.checked === "false" ? null : "no",
                      }))
                    }
                  />
                }
                {formData.p5_2 === "si" &&
                <SubQuestion
                  question="5.3. ¿Se dispone de las fichas de seguridad de los productos fitosanitarios?"
                  checkBind={formData.p5_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p5_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
                }
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="6.- ¿Dispone de piscina?"
                checkBind={formData.p6_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p6_0: e.target.checked === "true" ? "si" : "no",
                    p6_1: e.target.checked === "true" ? "no" : null,
                    p6_2: e.target.checked === "true" ? "no" : null,
                    p6_3: e.target.checked === "true" ? "no" : null,
                  }))
                }
              />
            </Box>

            {formData.p6_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="6.1. ¿Se utilizan productos para el mantenimiento de la piscina?"
                  checkBind={formData.p6_1}
                  onChange={(e) =>
                    formData((prev) => ({
                      ...prev,
                      p6_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="6.2. ¿Se dispone de protección perimetral de la piscina?"
                  checkBind={formData.p6_2}
                  onChange={(e) =>
                    formData((prev) => ({
                      ...prev,
                      p6_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="6.3. ¿Se dispone de aros salvavidas?"
                  checkBind={formData.p6_3}
                  onChange={(e) =>
                    formData((prev) => ({
                      ...prev,
                      p6_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="7.- ¿Dispone de extintores?"
                checkBind={formData.p7_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p7_0: e.target.checked === "true" ? "si" : e.target.checked === "false" ? "no" : "np",
                    p7_1: e.target.checked === "true" ? "no" : null,
                    p7_2: e.target.checked === "true" ? "no" : null,
                    p7_3: e.target.checked === "true" ? "no" : null,
                    p7_4: e.target.checked === "true" ? "no" : null,
                    p7_5: e.target.checked === "true" ? "no" : null,
                  }))
                }
                noTakes
              />
            </Box>

            {formData.p7_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="7.1. ¿Dispone de número suficiente de extintores?"
                  checkBind={formData.p7_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p7_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question={`7.2. ¿Están correctamente revisados? (si está caducada la fecha indicarlo en observaciones)`}
                  checkBind={formData.p7_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p7_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="7.3. ¿Están correctamente señalizados?"
                  checkBind={formData.p7_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p7_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="7.4. ¿Están colgados a una altura adecuada?"
                  checkBind={formData.p7_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p7_4: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="7.5. ¿Están en buen estado de conservación?"
                  checkBind={formData.p7_5}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p7_5: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="8.- ¿Disponen de iluminación de emergencias?"
                checkBind={formData.p8_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p8_0: e.target.checked === "true" ? "si" : "no",
                    p8_1: e.target.checked === "true" ? "no" : null,
                    p8_2: e.target.checked === "true" ? "no" : null,
                  }))
                }
                noTakes
              />
            </Box>

            {formData.p8_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="8.1. ¿Están iluminados los recorridos de evacuación?"
                  checkBind={formData.p8_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p8_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="8.2. ¿Están iluminadas las salidas de la finca?"
                  checkBind={formData.p8_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p8_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="9.- ¿Disponen de señalización de emergencias?"
                checkBind={formData.p9_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p9_0: e.target.checked === "true" ? "si" : "no",
                    p9_1: e.target.checked === "true" ? "no" : null,
                    p9_2: e.target.checked === "true" ? "no" : null,
                  }))
                }
                noTakes
              />
            </Box>

            {formData.p9_0 === 'si' && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="9.1. ¿Disponen de señalización de emergencias?"
                  checkBind={formData.p9_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p9_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="9.2. ¿Están señalizados los recorridos de evacuación?"
                  checkBind={formData.p9_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p9_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <Box className="lae-flex lae-flex-row lae-flex-betweeen">
            <Box
              className="lae-flex lae-flex-row"
              sx={{ flex: 1, alignItems: "start" }}
            >
              <SubQuestion
                question="10.- ¿Dispone de trabajador propio (conserje o portero)?"
                checkBind={formData.p10_0}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    p10_0: e.target.checked === "true" ? "si" : "no",
                    p10_1: e.target.checked === "true" ? "no" : null,
                    p10_2: e.target.checked === "true" ? "no" : null,
                    p10_3: e.target.checked === "true" ? "no" : null,
                    p10_4: e.target.checked === "true" ? "no" : null,
                    p10_5: e.target.checked === "true" ? "no" : null,
                    p10_6: e.target.checked === "true" ? "no" : null,
                    p10_7: e.target.checked === "true" ? "no" : null,
                    p10_8: e.target.checked === "true" ? "no" : null,
                    p10_9: e.target.checked === "true" ? "no" : null,
                    p10_10: e.target.checked === "true" ? "no" : null,
                    p10_11: e.target.checked === "true" ? "no" : null,
                    p10_12: e.target.checked === "true" ? "no" : null,
                    p10_13: e.target.checked === "true" ? "no" : null,
                    p10_14: e.target.checked === "true" ? "no" : null,
                    p10_15: e.target.checked === "true" ? "no" : null,
                    p10_16: e.target.checked === "true" ? "no" : null,
                    p10_17: e.target.checked === "true" ? "no" : null,
                    p10_18: e.target.checked === "true" ? "no" : null,
                    p10_19: e.target.checked === "true" ? "no" : null,
                    p10_20: e.target.checked === "true" ? "no" : null,
                    p10_21: e.target.checked === "true" ? "no" : null,
                  }))
                }
                noTakes
              />
            </Box>

            {formData.p10_0 === "si" && (
              <Box
                className="lae-flex lae-flex-column"
                sx={{
                  flex: 1,
                  p: 2,
                  boxShadow: "0px 0.1px 3px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <SubQuestion
                  question="10.1. ¿Realiza la limpieza de cristales de la puerta?"
                  checkBind={formData.p10_1}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_1: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.2. ¿El punto de operación está a más de dos metros?"
                  checkBind={formData.p10_2}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_2: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.3. ¿Realiza la limpieza general de las escaleras?"
                  checkBind={formData.p10_3}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_3: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.4. ¿Dispone de la ficha de datos de seguridad de los productos utilizados?"
                  checkBind={formData.p10_4}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_4: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.5. ¿Dispone de señalización de suelos mojados?"
                  checkBind={formData.p10_5}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_5: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.6. ¿Realiza el cambio de bombillas?"
                  checkBind={formData.p10_6}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_6: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.7. ¿El punto de operación está a más de dos metros?"
                  checkBind={formData.p10_7}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_7: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.8. ¿Recoge las basuras de los vecinos?"
                  checkBind={formData.p10_8}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_8: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.9. ¿Realiza la gestión postal de los vecinos?"
                  checkBind={formData.p10_9}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_9: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.10. ¿Ha recibido formación en prevención de riesgos específica a su puesto de trabajo?"
                  checkBind={formData.p10_10}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_10: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.11. ¿Ha realizado el reconocimiento médico?"
                  checkBind={formData.p10_11}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_11: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.12. ¿Realiza tratamientos a las piscinas?"
                  checkBind={formData.p10_12}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_12: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.13. ¿Si realiza trabajos de higienización de la piscina, dispone de los EPI's?"
                  checkBind={formData.p10_13}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_13: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.14. ¿Realiza tratamientos fitosanitarios?"
                  checkBind={formData.p10_14}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_14: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.15. ¿Si realiza trabajos fitosanitarios, dispone de los EPI's?"
                  checkBind={formData.p10_15}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_15: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.16. ¿Utiliza herramientas de poda?"
                  checkBind={formData.p10_16}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_16: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.17. ¿Utiliza herramientas de mano?"
                  checkBind={formData.p10_17}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_17: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.18. ¿Utiliza escaleras de mano?"
                  checkBind={formData.p10_18}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_18: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.19. ¿Conoce su Mutua de Accidentes?"
                  checkBind={formData.p10_19}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_19: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.20. ¿Conoce el trabajador el centro asistencial más cercano?"
                  checkBind={formData.p10_20}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_20: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />

                <SubQuestion
                  question="10.21. ¿Existe establecido un protocolo de comunicación entre el trabajador y la comunidad o el administrador de la finca?"
                  checkBind={formData.p10_21}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      p10_21: e.target.checked === "true" ? "si" : "no",
                    }))
                  }
                />
              </Box>
            )}
          </Box>

          <TextField
            id="outlined-multiline-static"
            label="Comentarios adicionales"
            multiline
            rows={4}
            // placeholder="Escribe algo..."
            defaultValue={formData.comentario}
            InputLabelProps={{ shrink: true }}
            sx={{ width: "80%", alignSelf: "center", my: 2 }}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                comentario: e.target.value,
              }))
            }
          />

          <ColorButton sx={{ alignSelf: "center", width: "80%" }}
            onClick={() => handleSubmit()}
          >
            Guardar y Enviar
          </ColorButton>
        </Box>
      </Box>
      <style jsx>{`
        .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
          -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
        }
      `}</style>
    </>
  );
}

export default withRouter(Comunidad);
