import React, {useState, useEffect} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";


const ListadoFincas = (props) => {
    const [fincas, setFincas] = useState([])   

    
    useEffect(() => {
        let f = [];
        props.fincas?.forEach(e => {
            let data = {
            razonSocial:e.razonSocial,
            nif: e.nif,
            /* activo: e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva",
            servicios: e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE",
            empleados: e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No" */
            }
            f.push(data)
            setFincas(f)
        })
    },[props]) 
    return ( 
        <>
            {fincas.length === props.fincas.length ?
                <ExportToExcel apiData={fincas} fileName={"Listado de fincas "+props.aaffNif} />
            :
                <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
            }
        </>
     );
}
 
export default withRouter(ListadoFincas);