import React, { useState, useEffect } from 'react';
import { getAAFF, renovacionFincasAAFF } from '../../../services';
import { withRouter } from '../../../providers/withRouter';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { CircularProgress, Button} from "@mui/material";
import { IoSave } from "react-icons/io5";
import { formatoFechasEs, formatoFechas } from '../../../functions/funciones';
import { toast } from 'react-toastify';

const ListaCheck = (props) => {
  const [seleccionados, setSeleccionados] = useState([]);
  const [seleccionGeneral, setSeleccionGeneral] = useState(false);
  
  const [aaff, setAAFF] = useState([]);
  const [fincas, setfincas] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [fechaVenta, setFechaVenta] = useState(formatoFechas(new Date()));
//   const fincas = [1,2,3,4,5,6,7,8,9];

//   const fincas = [
//     { nombre: 'finca 1', id: 1 },
//     { nombre: 'finca 2', id: 2 },
//     { nombre: 'finca 3', id: 3 },
//     { nombre: 'finca 4', id: 4 },
//   ];

  useEffect(() => {
    fetchData()
  }, [recargar]);

  const fetchData = async () => {
    const responseAaffs = await getAAFF(props.params.nif);
    setAAFF(responseAaffs);   
    console.log(responseAaffs.properties)
    setfincas(responseAaffs.properties);   
    setIsloading(false);
  }

  // Función para manejar la selección de un elemento individual
  const handleSeleccionIndividual = (id) => {
    const nuevosSeleccionados = { ...seleccionados };
    nuevosSeleccionados[id] = !nuevosSeleccionados[id];
    setSeleccionados(nuevosSeleccionados);
  };

  // Función para manejar la selección general
  const handleSeleccionGeneral = () => {
    setSeleccionGeneral(!seleccionGeneral);
    const nuevosSeleccionados = {};
    fincas.forEach((finca) => {
        if(!finca.renovada){
            nuevosSeleccionados[finca.id] = !seleccionGeneral;
        }
    });
    setSeleccionados(nuevosSeleccionados);
  };

  // Obtén los elementos seleccionados
  const elementosSeleccionados = fincas.filter((finca) => seleccionados[finca.id]);

  const handleRenovarfincas = async () => {
    try {
        // console.log(elementosSeleccionados)
        let arr = []
        elementosSeleccionados.fechaVenta = fechaVenta
        // elementosSeleccionados.map( e => {
        //     let obj = {id: e.id, razonSocial: e.razonSocial, nifFinca: e.nif, servicioRenovado:3}
        //     arr.push(obj)
        // }) 
        // console.log(arr)
        console.log(fechaVenta)
        if(elementosSeleccionados.length === 0){
            toast.error("No has seleccionado ninguna comunidad")
        }
        else if(fechaVenta === undefined){
            toast.error("No has una fecha")
        }else{
            const response = await renovacionFincasAAFF(elementosSeleccionados)
            console.log(response)
            setRecargar(!recargar)
        }
        
    } catch (error) {
        console.log(error)
    }
  }

  const handleDate = (evt) => {
    console.log(evt.target.value)
    setFechaVenta(evt.target.value)
  }


  return (
    <>
        <BreadCrumb rutas={[{path:'/aaff', label:`Administrador`}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
        <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  ADMINISTRADORES DE FINCAS
              </div>
          </div>
        </div>
        <div className="pageContent tableContainer">
            <div className="contentBar">
                <div className="buscador" style={{justifyContent: 'space-between'}}>
                    <label htmlFor="fechaVenta">
                        Introduce la fecha de venta: 
                        <input id="fechaVenta" name="fechaVenta" type="date" onChange={handleDate} value={fechaVenta} />
                    </label>
                    <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleRenovarfincas()}>
                        <span style={{fontSize:'15px'}}>Renovar</span>
                    </Button>
                </div>
            </div>
            <div className='tableWrapper'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <input id="check" className='checkBoxCustomTop' style={{ color: '#ffffff', marginLeft: '5px'}} type="checkbox" checked={seleccionGeneral} onChange={handleSeleccionGeneral} />
                                <label for="check" className='labelCustom'  style={{width: '25px', borderRadius: '5px', border: '3px solid #ffffff'}}></label>
                            </th>
                            <th>NIF</th>
                            <th>Comunidad</th>
                            <th>Fecha</th>
                            <th>Servicio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="15" style={{ textAlign: "center" }}>
                                    <CircularProgress style={{ color: "#fc7271" }} />{" "}
                                </td>
                            </tr>
                        ): fincas.length === 0 ? (
                            <tr>
                                <td colSpan="17" style={{ textAlign: "center" }}>
                                No hay fincas
                                </td>
                            </tr>
                        ): fincas.map((finca) => (
                            <React.Fragment key={finca.id}>
                                <tr>
                                    <td>
                                        
                                        {!finca.renovada ? 
                                            <>
                                                <input
                                                    id={`check${finca.id}`}
                                                    className='checkBoxCustom'
                                                    type="checkbox"
                                                    checked={seleccionados[finca.id]}
                                                    onChange={() => handleSeleccionIndividual(finca.id)}
                                                />
                                                <label for={`check${finca.id}`} className='labelCustom'></label>
                                            </>
                                        : ''}
                                    </td>
                                    <td>
                                        {finca.nif}
                                    </td>
                                    <td>
                                        {finca.razonSocial}
                                    </td>
                                    <td>
                                        {!finca.fechaRenovacion ? formatoFechasEs(finca.updatedAt) : formatoFechasEs(finca.fechaRenovacion)}
                                    </td>
                                    <td>
                                        Cae
                                    </td>
                                </tr>
                                {/* {finca?.workcenters === undefined || finca?.workcenters.length === 0 ? null : (
                                    <>
                                        {finca?.workcenters.map((c, j) => {
                                            return (
                                            <tr
                                                key={j}
                                                style={{
                                                backgroundColor:
                                                    j % 2 === 0 ? "#fffbf4" : "#fff9ea",
                                                }}
                                            >
                                                <td></td>
                                                <td colSpan={3} width={"100px"}>
                                                {c.razonSocial}
                                                </td>
                                            </tr>
                                            )
                                        })}
                                    </>
                                )} */}
                            </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
        {/* <div>
            <label>
                <input type="checkbox" checked={seleccionGeneral} onChange={handleSeleccionGeneral} />
                Seleccionar Todos
            </label>

            <ul>
                {fincas.map((finca) => (
                <li key={finca.id}>
                    <label>
                    <input
                        type="checkbox"
                        checked={seleccionados[finca.id]}
                        onChange={() => handleSeleccionIndividual(finca.id)}
                    />
                    {finca.razonSocial}, {finca.nif}
                    </label>
                </li>
                ))}
            </ul>

            <div>
                <h3>Elementos Seleccionados:</h3>
                <ul>
                {elementosSeleccionados.map((finca) => (
                    <li key={finca.id}>{finca.razonSocial}, {finca.nif}</li>
                ))}
                </ul>
            </div>
        </div> */}
    </>
  );
};

export default withRouter(ListaCheck);