import React, { useContext, useState } from "react";
import { Box, Button, Select, TextField, Typography } from "@mui/material";
import { usuarioContext } from "../../../providers/userContext";
import { useEffect } from "react";
import { getFinca, getListarDocsFincaProv, getProveedor, getSales, guardarFechaDocLeido } from "../../../services";
import moment from "moment";
import { Iconify } from "../../../utilities";
import { EditarProveedor, LaeButton, SmoothModal } from "../../../components";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { getDocsType } from "../../../services/docsType.service";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import { DOC_TYPES, DOC_TYPES_NAMES } from "../../../models";
import { withRouter } from "../../../providers/withRouter";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Editar from "../../../icons/editar.svg";
import { TiArrowBack } from "react-icons/ti";
import { Link } from "react-router-dom";


const Comunidad = (props) => {
  const user = useContext(usuarioContext);
  const nif = props?.params?.nif ?? null;

  const [recargar, setRecargar] = useState(false);
  const [info, setInfo] = useState(0);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [docsType, setDocsType] = useState([]);
  const [listDocs, setListDocs] = useState([]);
  const [salesCae, setSalesCae] = useState([]);

  const docsLeer = [{id:1, nombre:"Identificación de riesgos y medidas preventivas"}, {id:2, nombre:"Plan de emergencia"}]

  const fetchData = async () => {
    try {
      const response = await getFinca(nif);
      console.log(response.id)
      // const payload = {
      //   filtros: {prop_id:response.id},
      //   params: { },
      // };
      // const respSales = await getSales(payload);
      // console.log(response)
      // const infoSale = respSales.data.find(objeto => objeto.servp_id === 1 || objeto.servp_id === 3);
      // console.log(infoSale)
      // const {rows} = await getListarDocsFincaProv(infoSale.id)
      // console.log(rows)
      setInfo(response);
      // const res = await getDocsType()
      // setDocsType(res)
      // const payload = {
      //   // aaff_id: 7,
      //   filtros,
      //   params: { page: offset, limit },
      // };
      const { data } = await getSales({filtros:{prop_id:response.id}, params:{}});
      console.log(data)
      
      const resultadosFiltrados = data.find(
        objeto => objeto.servp_id === 1 || objeto.servp_id === 3
      );
      console.log(resultadosFiltrados)
      const respListDocs = await getListarDocsFincaProv(resultadosFiltrados.id)
      // const ultimoRegistro = resultadosFiltrados.pop();
      setListDocs(respListDocs);
      console.log(respListDocs);
      setSalesCae(resultadosFiltrados)

      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, response?.provincia)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ target }) =>
    setFormData((prev) => ({
      ...prev,
      [target?.name]: target?.value ?? "",
    }));

  const handlePut = async () => {
    try {
      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const docLeido = async (id, doc_id) => {
    let nombre = ''
    if(id === 1 ){
      nombre = 'identificacion-riesgos'
    }
    if(id === 2 ){
      nombre = 'plan-emergencia'
    }
    console.log("doc leido ahora "+doc_id +" "+ user.id +" "+ props.params.nif +" " + salesCae.id)
    const ruta = `/doc-render/${props.params.nif}/${salesCae.id}/${nombre}`
    window.open(ruta, '_blank');
    await guardarFechaDocLeido(doc_id, user.id).then( setRecargar(!recargar))
  }

  useEffect(() => {
    if (nif) console.log("helloNif");
    console.log(user);
    fetchData();
  }, [recargar]);
  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Comunidad / {info?.razonSocial ?? ""}
        </Typography>
      </div> */}
      <BreadCrumb rutas={[{path:'/comunidades', label:'Comunidades'}, {path:`comunidades/${info?.nif}`, label: `${info?.razonSocial}`}]} />
      {/* <EditarProveedor
        data={info}
        showModal={showModal}
        onSubmit={handlePut}
        handleChange={handleChange}
        onClose={() => setShowModal(false)}
      /> */}

      <div className="listPage">
        <div className="gridCard">
          <div
            className="gridHead"
            style={{
              backgroundColor: "#92004D",
              display: "flex",
              flexDirection: " row",
            }}
          >
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>
            {/* <Box className="lae-flex lae-flex-row">
              <Typography fontWeight="bold">Editar</Typography>
              <Iconify
                icon={"akar-icons:edit"}
                height={24}
                width={24}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => setShowModal(true)}
              />
            </Box> */}
          </div>
          {/* <div className="gridSubhead editarSubhead">
            <div></div>
            <div
              className="editarBtn"
              onClick={() => setShowModal(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div> */}
        </div>
        <Box sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography>DATOS COMUNIDAD:</Typography>
            <Box className="lae-flex lae-flex-row">
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Nombre comunidad: {info?.razonSocial}</Typography>
                <Typography>NIF: {info?.nif}</Typography>
                <Typography>
                  Persona de contacto: {info?.nombreContacto}
                </Typography>
                <Typography>
                  Email de contacto: {info?.emailContacto}
                </Typography>
                <Typography>
                  Telefono de contacto: {info?.telefonoContacto}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Dirección: {info?.direccion}</Typography>
                <Typography>Municipio: {info?.municipio}</Typography>
                <Typography>CP: {info?.codigoPostal}</Typography>
                <Typography>Provincia: {info?.provincia}</Typography>
                <Typography>
                  Fecha de alta: {moment(info?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="lae-flex lae-flex-row" sx={{ width: "100%" }}>
          <Box sx={{ flex: 1 }}>
            <div className="gridCard">
              <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
                <Typography fontWeight="bold">
                  DOCUMENTOS DE COMUNIDAD
                </Typography>
                {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
              </div>
            </div>

            <Box
              className="lae-flex lae-flex-column"
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                mb: 1,
                borderRadius: "0 0 10px 10px",
                boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
                justifyContent: "space-between",
              }}
            >
              <Box className="lae-flex lae-flex-row">
                <Box sx={{ flex: 8 }}>
                  <picture></picture>
                  <Typography></Typography>
                </Box>
                <Box
                  className="lae-flex lae-flex-row"
                  sx={{
                    flex: 2,
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ width:'100px', textAlign:"center"}}>Última lectura</Typography>
                  <Typography style={{ width:'100px', textAlign:"center"}}>Ver</Typography>
                </Box>
              </Box>

              {Object.values(docsLeer).map((dt, i) => {
                const findDoc = info?.docssupps?.find(
                  (doc) => doc.tipoDoc === dt
                );
                
                return (
                  <Box className="lae-flex lae-flex-row" sx={{ pr: 0.4 }}>
                    <Box sx={{ flex: 8 }} style={{display:'flex', alignItems:'center'}}>
                      <Typography>
                        {salesCae?.detailsCae && salesCae?.detailsCae.length > 0 && salesCae.detailsCae[0]?.visitada ?
                          <img src={Tick} alt="Visitada" className="tableIcon" />
                        :
                          <img src={Cross} alt="No visitada" className="tableIcon" />
                        }
                      </Typography>
                      <Typography variant="body2">
                        {listDocs[i]?.nombre === "identificacion de riesgos" ? "Identificación de riesgos y medidas preventivas" : "Plan de emergencia"}
                      </Typography>
                    </Box>
                    <Box
                      className="lae-flex lae-flex-row"
                      sx={{
                        flex: 2,
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ width:'100px', textAlign:"center"}}>
                        {console.log(listDocs[i]?.suppRead)}
                        {listDocs[i]?.suppRead[0]?.r_doc_reads.updatedAt
                          ? moment(listDocs[i].suppRead[0].r_doc_reads.updatedAt).format("DD/MM/YYYY")
                          : "----"}
                      </Typography>
                      <Iconify style={{ width:'100px', textAlign:"center"}}
                        icon={"solar:document-bold"}
                        height={24}
                        width={24}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => salesCae?.detailsCae && salesCae?.detailsCae.length > 0 && salesCae.detailsCae[0]?.visitada ? docLeido(docsLeer[i].id, listDocs[i].id, listDocs[i]?.suppRead[0]?.r_doc_reads) : null}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default withRouter(Comunidad);
