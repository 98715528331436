import { useState, useEffect } from "react";

import { getFincasAAFF, getFincasTech, getSuppListPropertiAaff } from "../services";
import { useContext } from "react";
import { usuarioContext } from "../providers/userContext";
import { Link } from "react-router-dom";
import { formatoFechasEs } from "../functions/funciones";
import { FaEye } from "react-icons/fa";
function useComunidades(props) {
  const user = useContext(usuarioContext)
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  const formatData = (data) => {
    return data.map((row) => ({
      aaff_id: row?.aaff?.id,
      commer_id: row?.aaff?.commer_id,
      tech_id: row?.aaff?.tech_id,
      aff_name: row.aaff?.razonSocial,
      nif: row.nif,
      id: row.id,
      community: <Link to= {`/comunidades/${row.nif}`}>{row.razonSocial || row.propertie?.razonSocial}</Link>,
      community_name: row.razonSocial || row.propertie?.razonSocial,
      direction: row.direccion || row.propertie?.direccion,
      cp: row.codigoPostal || row.propertie?.codigoPostal,
      municipio: row.municipio || row.propertie?.municipio,
      empleados: (row.trabajadores || row.propertie?.trabajadores) ? "Sí" : "No",
      fechaAlta: formatoFechasEs(row.createdAt),
      centrosTrabajo: row.workcenters,
      btn_to: <Link to= {`/comunidades/${row.nif}`} className="btn btn-light"><FaEye/></Link>,
      // servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
      // hojasVisita: {
      //   visitSheet: row?.visitSheets?.length ? row.visitSheets[0] : undefined,
      //   nif: row.nif || row.propertie?.nif,
      // },
      hojasVisita:row.nif
      // visitada: row || row.propertie,
    }));
  };

  const fetchData = async ({ offset, limit, filtros }) => {
   
    setLimite(limit)
    const payload = {
      params: { page: offset, limit },
      filtros,
    };

    try {
      console.log("response")
      setLoading(true);
      let response = []
      if(user.rol === 'SUPP'){
        response = await getSuppListPropertiAaff(payload);
      }else if(user.rol === 'TECH'){
        response = await getFincasTech(payload);
      }else{
        // CORREGIR AQUI
        response = await getFincasAAFF(payload);
        
      }
      // const response = await getSuppListPropertiAaff({ params: { ...payload }});
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetchData,
    loading,
    formatData,
    limite
  };
}

export default useComunidades;
