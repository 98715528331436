import React, { useState, useEffect } from "react";
// import { styled } from '@mui/system';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { getAAFF } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";

/* const useStyles = styled((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
})); */

export default function ControlledAccordions(props) {
  const [expanded, setExpanded] = useState(false);
  const [AAFF, setAAFF] = useState([]);
  const [proveedor, setProveedor] = useState({});
  useEffect(() => {
    // fetchData()
    let promises = [];
    let aaffDatos = []
    // console.log(props.AAFFS)
    props.AAFFS.forEach((af) => {
      let datos = {};
      promises.push(
        getAAFF(af.nif).then((res) => {
          // Obtenemos los NIF únicos del primer array
          let nifsUnicos = new Set(props.fincas.map(obj => obj.nif));
          // Filtramos las fincas cuyo NIF está en el conjunto de NIFs únicos
          let fincasFiltradas = res.properties.filter(finca => nifsUnicos.has(finca.nif));
          let fincas = []
          let proveedor = {}
          
          fincasFiltradas.forEach(f => {
            let docsRead = []
            let idsHojasVisita = []
            let servicio = []
            let propSupp = []
            let finca = {id:f.id, razonSocial:f.razonSocial}
            
            let idsVisitSheets = f.visitSheets.map(objeto => objeto.id);
            
            idsHojasVisita.push(idsVisitSheets)

            propSupp = f.r_propSupp.filter(ps => ps.supp_id === props.proveedor.id)
            proveedor = f.suppliers.find(s => s.nif === props.proveedor.nif )
            propSupp.forEach( ps => {
              servicio.push(proveedor.serviceSupps.find(s => s.id === ps.service))
            })
            proveedor.docsRead.forEach( dr => {
              if(idsHojasVisita.some(subarray => subarray.includes(dr.visitSheet_id))){
                
                docsRead.push(dr)
              }
            })            
            
            fincas.push({...finca, docsRead:docsRead, servicio: servicio})
          })
          
          datos.id = res.id
          datos.nif = res.nif
          datos.razonSocial = res.razonSocial
          datos.fincas = fincas
          datos.proveedores = proveedor
          datos.properties = fincasFiltradas
          aaffDatos.push(datos);
        })
      );
      
    });
    Promise.all(promises).then(() => {
      console.log(aaffDatos)
      setAAFF(aaffDatos);
    });
  }, [props.AAFFS]);

  const fetchData = async () => {
    props.AAFFS.forEach(async af => {
      const res = await getAAFF(af.nif)
      console.log(res)
      setAAFF(res)
    })
  }
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="root" style={{ margin: "15" }}>
      {AAFF.map((e, i) => (
        <Accordion
          key={i}
          style={{ margin: "0" }}
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
        >
          <AccordionSummary
            style={{ background: "rgb(251 251 251)" }}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography style={{ flex: "auto" }} className="heading">
              {e.razonSocial}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {e.fincas.length === 0 ? (
                <span key={0} style={{ margin: "10px 0" }}>
                  No tiene fincas
                </span>
              ) : (
                e.fincas.map((f, j) => (
                  <>
                    <span key={j} style={{ margin: "10px 0" }}>
                      {f.razonSocial} <br />
                      <small>
                        <b>Identificación de riesgos:</b>{" "}
                        {f.docsRead.find( dr => dr.nombre === "identificacion de riesgos" )?.r_doc_reads?.updatedAt !==undefined ?
                          formatoFechasEs(f.docsRead.find( dr => dr.nombre === "identificacion de riesgos" )?.r_doc_reads?.updatedAt)
                        : "Nunca"
                        }
                      </small>
                      <br />
                      <small>
                        <b>Plan de emergencia: </b>
                        {f.docsRead.find( dr => dr.nombre === "plan de emergencia" )?.r_doc_reads?.updatedAt !==undefined ?
                          formatoFechasEs(f.docsRead.find( dr => dr.nombre === "plan de emergencia" )?.r_doc_reads?.updatedAt)
                        : "Nunca"
                        }
                      </small>
                      <br />
                      <small>
                        <b>Servicio: </b>
                        {f?.servicio.map((s, i) => {
                          return (
                            <span key={s?.nombre}>
                              {s?.nombre}
                              {i < f.servicio.length - 1 && ', '}
                            </span>
                          )
                        })}
                      </small>
                    </span>
                    <br />
                  </>
                ))
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
      <style jsx="true">{`
        .root {
          width: "100%";
        }
        .heading: {
          font-size: 0.9375rem;
          flex-basis: "33.33%";
          flex-shrink: 0;
        }
        .secondaryheading: {
          fontsize: 0.9375rem;
          color: theme.palette.text.secondary;
        }
      `}</style>
    </div>
  );
}
