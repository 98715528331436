import React, { useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createTec } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

const NuevoTecnico = () => {
  const [tecnico, setTecnico] = useState({});

  function handleSubmit(data) {
    const toastInfo = toast.info("Guardando técnico");
    createTec(data)
      .then((res) => {
        toast.update(toastInfo, {
          render: "Técnico creado con éxito",
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        });
        window.location.replace("/tecnicos");
      })
      .catch((err) => {
        toast.error("Error al crear el técnico");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setTecnico({
      ...tecnico,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Técnicos / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{path:'/tecnicos', label:"Técnicos"}, {path:'/tecnicos', label:"Nuevo"}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridHead">
          Crear nuevo Técnico
        </div>
        {/* <div className="titleContent">Crear nuevo Técnico</div> */}
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20, padding: '0 20px' }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={tecnico.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={tecnico.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={tecnico.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={tecnico.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Teléfono"
                name="telefono"
                value={tecnico.telefono}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Zona"
                name="zona"
                value={tecnico.zona}
                onChange={handleChange}
              ></input>
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'end', padding: 20 }}>
            <input
              type="button"
              value="Guardar y publicar"
              onClick={() => handleSubmit(tecnico)}
            ></input>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default NuevoTecnico;
