import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../functions/ordenarListas";
import { getCommercials, getConsultores, getTecnicos } from "../../services";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import { usuarioContext } from "../../providers/userContext";
import {
  LinearProgress,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";


const formContainerStyles = css`
  height: 19px !important; 
`;

const FormContainer = styled("div")`
  ${formContainerStyles}
`;
const FiltrosAAFF = ({ onFilter }) => {

  const user = useContext(usuarioContext)
  const [busqueda, setBusqueda] = useState({});
  const [comercial, setComercial] = useState([]);
  const [consultor, setConsultor] = useState([]);
  const [tecnico, setTecnico] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, busqueda.provincia)
    );
  }, [busqueda]);

  const getDatas = async () => {
    const comm = user.rol === "TECH" || user.rol === "SUPP"  ? '' : await getCommercials();
    const consul = user.rol === "TECH" || user.rol === "SUPP"  ? '' : await getConsultores();
    const tech = user.rol === "TECH" || user.rol === "SUPP"  ? '' : await getTecnicos();
    setComercial(comm.data);
    setConsultor(consul);
    setTecnico(tech);
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    if (e.target.name === 'provincia' || e.target.value === '') {
      // Si el campo provincia cambia a vacío, reiniciar el campo municipio
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
        municipio: '', // Establecer el campo municipio en vacío
      });
    } else {
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
      });
    }
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({ ...m, name: cambiarFormatoNombre(m.name) });
      }
    });
    setListadoMunicipios(municipios);
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
    console.log(busqueda);
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    
    console.log(busqueda);
    onFilter(busqueda);
  };

  return (
    <>
      <Box
        mx={1}
        display={"flex"}
        flexDirection={"row"}
        gap={2}
      >
        <TextField
          id="razonSocial-input"
          label="Razón Social"
          variant="outlined"
          name='razonSocial'
          className="filtros"
          style={{ width: '223px', height:'20px !important'}}
          value={busqueda.aaff}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          inputProps={{
          "aria-label": "controlled",
          }}
        />
        <TextField
          id="nif-input"
          label="NIF"
          variant="outlined"
          name='nif'
          className="filtros"
          style={{ width: '223px' }}
          value={busqueda.nif}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          inputProps={{
          "aria-label": "controlled",
          }}
        />
        <TextField
          id="email-input"
          label="Email"
          variant="outlined"
          name='emailContacto'
          className="filtros"
          style={{ width: '223px' }}
          value={busqueda.email}
          onChange={handleChange}
          onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          inputProps={{
          "aria-label": "controlled",
          }}
        />
        {user.rol === "SUPP" ? null :
          <>
            <FormControl variant="outlined" style={{ width: '223px' }}>
              <InputLabel id="provincia-label">Provincia</InputLabel>
              <Select
                labelId="comunidad-label"
                id="province-select"
                label="Provincia"
                name="provincia"
                className="filtros"
                value={busqueda.provincia}
                onChange={seleccionarMunicipios}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.name}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: '223px' }}>
              <InputLabel id="municipio-label">Municipios</InputLabel>
              <Select
                labelId="municipio-label"
                id="municipio-select"
                label="Municipio"
                name="municipio"
                className="filtros"
                value={busqueda?.municipio}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {listadoMunicipios
                  .sort(ordenarProvinciasYMunicipios)
                  ?.map((e, i) => {
                    return (
                      <MenuItem key={i} value={e.name}>
                        {e.name}
                      </MenuItem>
                    );
                  })}
              </Select>

            </FormControl>
          </>
        }
        {/* AQUUIII */}
      </Box>
      <Box 
        mx={1}
        mb={2}
        display={"flex"}
        flexDirection={"row"}
        gap={2}
      >
        {user.rol === "TECH" || user.rol === "SUPP" || user.rol === "COMM" ? null :
          <>
            <FormControl variant="outlined" style={{ width: '223px' }}>
              <InputLabel id="tecnico-label">Comercial</InputLabel>
              <Select
                labelId="tecnico-label"
                id="tecnico-select"
                label="Provincia"
                name="commer_id"
                className="filtros"
                value={busqueda.comercial}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {comercial?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: '223px' }}>
              <InputLabel id="tecnico-label">Técnico</InputLabel>
              <Select
                labelId="tecnico-label"
                id="tecnico-select"
                label="Provincia"
                name="tech_id"
                className="filtros"
                value={busqueda.tecnico}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {tecnico?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: '223px' }}>
              <InputLabel id="tecnico-label">Consultor</InputLabel>
              <Select
                labelId="tecnico-label"
                id="tecnico-select"
                label="Provincia"
                name="consul_id"
                className="filtros"
                value={busqueda.consultor}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              >
                <MenuItem value="" style={{height: 40}}></MenuItem>
                {consultor?.map((e, i) => {
                  return (
                    <MenuItem key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        }
        <Button className="btn-buscador" /*style={{height:'56px'}}*/ onClick={() => getBusqueda()}>
          <BsSearch />
        </Button>
      </Box>
    <style jsx="true">
      {`
        .btn-buscador {
          padding: 5px 10px;
          color: #ffffff;
          background-color: #92004D;
          border-radius: 4px;
          font-weight: bold;
          width: -moz-fit-content;
          width: fit-content;
          align-self: flex-end;
          border: none;
          min-width:40px;
          height:40px;
          margin: 5px 0;
        }
        .btn-buscador:hover{
          color: black;
          background-color: #dee2e6;
        }    
      `}
    </style>
    </>
  );
};

export default FiltrosAAFF;
