import axios from "axios";

const urlPath = "aaff";

export const getList = async () => {
  try {
    const response = await axios.post(`${urlPath}/getlist`);

    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPropertiFromAAFFfilter = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.error(err)
    console.log(err.response);
  }
};

export const getAAFFs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getListadoAaffsSupp = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/ListadoAaffsSupp`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAAFF = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const createAAFF = async (data) => {
  let datos = {
    razonSocial: data.razonSocial,
    nif: data.nif,
    email: data.email,
    telefono: data.telefono,
    nombreContacto: data.contacto,
    municipio: data.municipio,
    provincia: data.provincia,
    commer_id: data.commer_id,
  };
  try {
    const response = await axios.post(urlPath, datos);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const editarAAFF = async (data) => {
  console.log(data);

  try {
    const response = await axios.put(`${urlPath}/${data.nif}`, data);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteAAFF = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const changeAAFF = async (nifFinca, nuevoaaff) => {
  let datos = {
    nifAAFF: nuevoaaff,
  };
  try {
    const response = await axios.put(`propertie/${nifFinca}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
