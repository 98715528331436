import { useState, useEffect } from "react";

import { listCentroTrabajo } from "../services";

function useCentrosTrabajo() {
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  const formatData = (data) => {
    return data.map((row) => ({
      id: row.id,
      name: row.razonSocial,
      renovado: row.renovado,
      prop_id: row.prop_id,
      renovadoVisitado: row.renovadoVisitado,
      aaff_id: row?.propertie?.aaff?.id,
      commer_id: row?.propertie?.aaff?.commer_id
    }));
  };

  const fetchData = async ({ limit, offset, razonSocial, filterby, aaff_id }) => {
    const payload = {
      aaff_id,
      razonSocial,
      filterby,
      limit,
      offset,
    };
    try {
      setLoading(true);
      const response = await listCentroTrabajo({ params: { ...payload } });

      const { meta, data } = response;

      setData({
        meta,
        rows: formatData(data.data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetchData,
    loading,
    formatData,
  };
}

export default useCentrosTrabajo;
