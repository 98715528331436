import axios from "axios";

const formHeaders = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

const urlPath = "visitSheet";

export const getVisitSheet = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);

    return response;
  } catch (error) {
    console.log(error);
  }
};


export const createVisitSheet = async (data, images, img, nifProp   ) => {
  console.log(data);
  console.log(images);
  console.log(img);
  // console.log(nifProp);
  const datos = {
    saleDate:data.saleDate,
    visitSheet_id: data.visitSheet_id,
    sale_id: data.sale_id,
    aaff_id:data.aaff_id,
    work_id:1,
    tech_id:data.IdTecnicoPRL,
    prop_id:data.IdComunidad,
    nPlantas:data.numeroPlantas,
    nVersion:1,
    questions:[
        {
          p1_0:data.p1_0,
          p1_1:data.p1_1,
          p1_2:data.p1_2,
          p1_3:data.p1_3,
          p1_4:data.p1_4,
          p1_5:data.p1_5,
          p1_6:data.p1_6,
          p1_7:data.p1_7,
          p1_8:data.p1_8,
          p2_0:data.p2_0,
          p2_1:data.p2_1,
          p2_2:data.p2_2,
          p2_3:data.p2_3,
          p2_4:data.p2_4,
          p2_5:data.p2_5,
          p2_6:data.p2_6,
          p2_7:data.p2_7,
          p2_8:data.p2_8,
          p2_9:data.p2_9,
          p2_10:data.p2_10,
          p3_0:data.p3_0,
          p3_1:data.p3_1,
          p3_2:data.p3_2,
          p3_3:data.p3_3,
          p3_4:data.p3_4,
          p4_0:data.p4_0,
          p4_1:data.p4_1,
          p4_2:data.p4_2,
          p4_3:data.p4_3,
          p4_4:data.p4_4,
          p4_5:data.p4_5,
          p4_6:data.p4_6,
          p4_7:data.p4_7,
          p4_8:data.p4_8,
          p4_9:data.p4_9,
          p4_10:data.p4_10,
          p4_11:data.p4_11,
          p4_12:data.p4_12,
          p4_13:data.p4_13,
          p4_14:data.p4_14,
          p4_15:data.p4_15,
          p5_0:data.p5_0,
          p5_1:data.p5_1,
          p5_2:data.p5_2,
          p5_3:data.p5_3,
          p6_0:data.p6_0,
          p6_1:data.p6_1,
          p6_2:data.p6_2,
          p6_3:data.p6_3,
          p7_0:data.p7_0,
          p7_1:data.p7_1,
          p7_2:data.p7_2,
          p7_3:data.p7_3,
          p7_4:data.p7_4,
          p7_5:data.p7_5,
          p8_0:data.p8_0,
          p8_1:data.p8_1,
          p8_2:data.p8_2,
          p9_0:data.p9_0,
          p9_1:data.p9_1,
          p9_2:data.p9_2,
          p10_0:data.p10_0,
          p10_1:data.p10_1,
          p10_2:data.p10_2,
          p10_3:data.p10_3,
          p10_4:data.p10_4,
          p10_5:data.p10_5,
          p10_6:data.p10_6,
          p10_7:data.p10_7,
          p10_8:data.p10_8,
          p10_9:data.p10_9,
          p10_10:data.p10_10,
          p10_11:data.p10_11,
          p10_12:data.p10_12,
          p10_13:data.p10_13,
          p10_14:data.p10_14,
          p10_15:data.p10_15,
          p10_16:data.p10_16,
          p10_17:data.p10_17,
          p10_18:data.p10_18,
          p10_19:data.p10_19,
          p10_20:data.p10_20,
          p10_21:data.p10_21,
          }
    ],
    // img: '/storage/fincas/88888888Q/img/2.png',
    comentario:data.comentarios,
    // primeraVisita:true,
    year:new Date().getFullYear()
  }
  
  const json = JSON.stringify(datos);
  // console.log(json)
  const formData = new FormData();
  // formData.append("data", json);
  // formData.append("visitSheet_id", datos.visitSheet_id);
  formData.append("sale_id", datos.sale_id);
  formData.append("aaff_id", datos.aaff_id);
  formData.append("prop_id", datos.prop_id);
  formData.append("work_id", datos.work_id);
  formData.append("tech_id", datos.tech_id);
  formData.append("nPlantas", datos.nPlantas);
  formData.append("comentario", datos.comentario);
  formData.append("nVersion", datos.nVersion);
  formData.append("saleDate", datos.saleDate);
  formData.append("year", datos.year);
  formData.append("questions[0][p1_0]", datos.questions[0].p1_0);
  formData.append("questions[0][p1_1]", datos.questions[0].p1_1);
  formData.append("questions[0][p1_2]", datos.questions[0].p1_2);
  formData.append("questions[0][p1_3]", datos.questions[0].p1_3);
  formData.append("questions[0][p1_4]", datos.questions[0].p1_4);
  formData.append("questions[0][p1_5]", datos.questions[0].p1_5);
  formData.append("questions[0][p1_6]", datos.questions[0].p1_6);
  formData.append("questions[0][p1_7]", datos.questions[0].p1_7);
  formData.append("questions[0][p1_8]", datos.questions[0].p1_8);    
  formData.append("questions[0][p2_0]", datos.questions[0].p2_0);    
  formData.append("questions[0][p2_1]", datos.questions[0].p2_1);    
  formData.append("questions[0][p2_2]", datos.questions[0].p2_2);    
  formData.append("questions[0][p2_3]", datos.questions[0].p2_3);    
  formData.append("questions[0][p2_4]", datos.questions[0].p2_4);    
  formData.append("questions[0][p2_5]", datos.questions[0].p2_5);    
  formData.append("questions[0][p2_6]", datos.questions[0].p2_6);    
  formData.append("questions[0][p2_7]", datos.questions[0].p2_7);    
  formData.append("questions[0][p2_8]", datos.questions[0].p2_8);    
  formData.append("questions[0][p2_9]", datos.questions[0].p2_9);    
  formData.append("questions[0][p2_10]", datos.questions[0].p2_10);    
  formData.append("questions[0][p3_0]", datos.questions[0].p3_0);    
  formData.append("questions[0][p3_1]", datos.questions[0].p3_1);    
  formData.append("questions[0][p3_2]", datos.questions[0].p3_2);    
  formData.append("questions[0][p3_3]", datos.questions[0].p3_3);    
  formData.append("questions[0][p3_4]", datos.questions[0].p3_4);    
  formData.append("questions[0][p4_0]", datos.questions[0].p4_0);    
  formData.append("questions[0][p4_1]", datos.questions[0].p4_1);    
  formData.append("questions[0][p4_2]", datos.questions[0].p4_2);    
  formData.append("questions[0][p4_3]", datos.questions[0].p4_3);    
  formData.append("questions[0][p4_4]", datos.questions[0].p4_4);    
  formData.append("questions[0][p4_5]", datos.questions[0].p4_5);    
  formData.append("questions[0][p4_6]", datos.questions[0].p4_6);    
  formData.append("questions[0][p4_7]", datos.questions[0].p4_7);    
  formData.append("questions[0][p4_8]", datos.questions[0].p4_8);    
  formData.append("questions[0][p4_9]", datos.questions[0].p4_9);    
  formData.append("questions[0][p4_10]", datos.questions[0].p4_10);    
  formData.append("questions[0][p4_11]", datos.questions[0].p4_11);    
  formData.append("questions[0][p4_12]", datos.questions[0].p4_12);    
  formData.append("questions[0][p4_13]", datos.questions[0].p4_13);    
  formData.append("questions[0][p4_14]", datos.questions[0].p4_14);    
  formData.append("questions[0][p4_15]", datos.questions[0].p4_15);    
  formData.append("questions[0][p5_0]", datos.questions[0].p5_0);    
  formData.append("questions[0][p5_1]", datos.questions[0].p5_1);    
  formData.append("questions[0][p5_2]", datos.questions[0].p5_2);    
  formData.append("questions[0][p5_3]", datos.questions[0].p5_3);  
  formData.append("questions[0][p6_0]", datos.questions[0].p6_0);    
  formData.append("questions[0][p6_1]", datos.questions[0].p6_1);    
  formData.append("questions[0][p6_2]", datos.questions[0].p6_2);    
  formData.append("questions[0][p6_3]", datos.questions[0].p6_3);  
  formData.append("questions[0][p7_0]", datos.questions[0].p7_0);    
  formData.append("questions[0][p7_1]", datos.questions[0].p7_1);    
  formData.append("questions[0][p7_2]", datos.questions[0].p7_2);    
  formData.append("questions[0][p7_3]", datos.questions[0].p7_3);    
  formData.append("questions[0][p7_4]", datos.questions[0].p7_4);    
  formData.append("questions[0][p7_5]", datos.questions[0].p7_5);  
  formData.append("questions[0][p8_0]", datos.questions[0].p8_0);    
  formData.append("questions[0][p8_1]", datos.questions[0].p8_1);    
  formData.append("questions[0][p8_2]", datos.questions[0].p8_2);  
  formData.append("questions[0][p9_0]", datos.questions[0].p9_0);    
  formData.append("questions[0][p9_1]", datos.questions[0].p9_1);    
  formData.append("questions[0][p9_2]", datos.questions[0].p9_2);  
  formData.append("questions[0][p10_0]", datos.questions[0].p10_0);    
  formData.append("questions[0][p10_1]", datos.questions[0].p10_1);    
  formData.append("questions[0][p10_2]", datos.questions[0].p10_2);    
  formData.append("questions[0][p10_3]", datos.questions[0].p10_3);    
  formData.append("questions[0][p10_4]", datos.questions[0].p10_4);    
  formData.append("questions[0][p10_5]", datos.questions[0].p10_5);    
  formData.append("questions[0][p10_6]", datos.questions[0].p10_6);    
  formData.append("questions[0][p10_7]", datos.questions[0].p10_7);    
  formData.append("questions[0][p10_8]", datos.questions[0].p10_8);    
  formData.append("questions[0][p10_9]", datos.questions[0].p10_9);    
  formData.append("questions[0][p10_10]", datos.questions[0].p10_10);    
  formData.append("questions[0][p10_11]", datos.questions[0].p10_11);    
  formData.append("questions[0][p10_12]", datos.questions[0].p10_12);    
  formData.append("questions[0][p10_13]", datos.questions[0].p10_13);    
  formData.append("questions[0][p10_14]", datos.questions[0].p10_14);    
  formData.append("questions[0][p10_15]", datos.questions[0].p10_15);  
  formData.append("questions[0][p10_16]", datos.questions[0].p10_16);    
  formData.append("questions[0][p10_17]", datos.questions[0].p10_17);    
  formData.append("questions[0][p10_18]", datos.questions[0].p10_18);    
  formData.append("questions[0][p10_19]", datos.questions[0].p10_19);    
  formData.append("questions[0][p10_20]", datos.questions[0].p10_20);    
  formData.append("questions[0][p10_21]", datos.questions[0].p10_21);  
  // formData.append("img", datos.img);
  formData.append("img", img);
  images.forEach((file, index) => {
    formData.append(`images`, file);
  });
  // formData.append("images", images);
  try {
    const response = await axios.post(`${urlPath}/${nifProp}`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const editarVisitSheet = async (data, imagesToDelete, images, img, nifProp   ) => {

  const id = data.visitSheet_id;
  const datos = {
    saleDate:data.saleDate,
    visitSheet_id: data.visitSheet_id,
    sale_id: data.sale_id,
    aaff_id:data.aaff_id,
    work_id:1,
    tech_id:data.IdTecnicoPRL,
    prop_id:data.IdComunidad,
    nPlantas:data.numeroPlantas,
    nVersion:1,
    questions:[
      {
        p1_0:data.p1_0,
        p1_1:data.p1_1,
        p1_2:data.p1_2,
        p1_3:data.p1_3,
        p1_4:data.p1_4,
        p1_5:data.p1_5,
        p1_6:data.p1_6,
        p1_7:data.p1_7,
        p1_8:data.p1_8,
        p2_0:data.p2_0,
        p2_1:data.p2_1,
        p2_2:data.p2_2,
        p2_3:data.p2_3,
        p2_4:data.p2_4,
        p2_5:data.p2_5,
        p2_6:data.p2_6,
        p2_7:data.p2_7,
        p3_0:data.p3_0,
        p3_1:data.p3_1,
        p3_2:data.p3_2,
        p3_3:data.p3_3,
        p3_4:data.p3_4,
        p4_0:data.p4_0,
        p4_1:data.p4_1,
        p4_2:data.p4_2,
        p4_3:data.p4_3,
        p4_4:data.p4_4,
        p4_5:data.p4_5,
        p4_6:data.p4_6,
        p4_7:data.p4_7,
        p4_8:data.p4_8,
        p4_9:data.p4_9,
        p4_10:data.p4_10,
        p4_11:data.p4_11,
        p4_12:data.p4_12,
        p4_13:data.p4_13,
        p4_14:data.p4_14,
        p4_15:data.p4_15,
        p5_0:data.p5_0,
        p5_1:data.p5_1,
        p5_2:data.p5_2,
        p5_3:data.p5_3,
        p6_0:data.p6_0,
        p6_1:data.p6_1,
        p6_2:data.p6_2,
        p6_3:data.p6_3,
        p7_0:data.p7_0,
        p7_1:data.p7_1,
        p7_2:data.p7_2,
        p7_3:data.p7_3,
        p7_4:data.p7_4,
        p7_5:data.p7_5,
        p8_0:data.p8_0,
        p8_1:data.p8_1,
        p8_2:data.p8_2,
        p9_0:data.p9_0,
        p9_1:data.p9_1,
        p9_2:data.p9_2,
        p10_0:data.p10_0,
        p10_1:data.p10_1,
        p10_2:data.p10_2,
        p10_3:data.p10_3,
        p10_4:data.p10_4,
        p10_5:data.p10_5,
        p10_6:data.p10_6,
        p10_7:data.p10_7,
        p10_8:data.p10_8,
        p10_9:data.p10_9,
        p10_10:data.p10_10,
        p10_11:data.p10_11,
        p10_12:data.p10_12,
        p10_13:data.p10_13,
        p10_14:data.p10_14,
        p10_15:data.p10_15,
        p10_16:data.p10_16,
        p10_17:data.p10_17,
        p10_18:data.p10_18,
        p10_19:data.p10_19,
        p10_20:data.p10_20,
        p10_21:data.p10_21,
        }
    ],
    comentario:data.comentario,
    year:new Date().getFullYear()
  }
  
  const json = JSON.stringify(datos);

  const formData = new FormData();
  formData.append("data", json);
  
  formData.append("img", img);
  images.forEach((file, index) => {
    formData.append(`images-[${index}]`, file);
  });

  
  imagesToDelete.forEach((image, index) => {
    if (image && typeof image === 'object' && 'id' in image && 'path' in image) {
      formData.append(`images_to_delete[${index}][id]`, image.id);
      formData.append(`images_to_delete[${index}][path]`, image.path);
    }
  });

  try {
    console.log(`${urlPath}/${nifProp}/${id}`);
    
    const response = await axios.put(`${urlPath}/${nifProp}/${id}`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};