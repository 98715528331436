export const orderArray = (array) => {
    array.sort((a, b) => {
        if (a.razonSocial > b.razonSocial) {
          return 1;
        }
        if (a.razonSocial < b.razonSocial) {
          return -1;
        }
        return 0;
    })

    return array
}

export const ordenarUltimaReclamacion = () => {

}

export const ordenarProvinciasYMunicipios = (a, b) => {
  //comparar nombreA y nombreB sin tener en cuenta los acentos
  const nombreA = a.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();
  const nombreB = b.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase();

  return nombreA.localeCompare(nombreB)
}