import { toast } from "react-toastify";
import { plantillaProveedores, plantillaFincas, deleteFincasAAFF, plantillaMatcheo, plantillaAAFF, plantillaRenovaciones, plantillaFincasLOPD } from "../services";
import Swal from "sweetalert2";

export const subirListadoProveedores = (evt) => {
    toast.info("Subiendo proveedores...");
    const value = evt.target.files[0];
    console.log(value);
    plantillaProveedores(value)
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.message === "SUPPLIERS_CREATED" ) {
          toast.success("Proveedores subidos con éxito");
          return true
        }
        if (res.status === 201) {
          toast.error("el proveedor existe");
        }
        if (res.status === 403) {
          toast.error("error al subir proveedores");
        }
        if (res.message === "ERROR_SUPPIER_EXIST") {
          toast.error("El proveedor ya existe");
        }
        if (res.error === "ERROR_USER_EXIST") {
          toast.error("El proveedor ya existe");
        }
      })
      .catch((err) => {
        toast.error("error al subir proveedores");
        console.error(err);
      });
  }

export const subirListadoRenovaciones = (evt) => {
  let toastInfo = toast.info("Subiendo Renovaciones...");
  const value = evt.target.files[0];
  plantillaRenovaciones(value).then((res) => {
    if (res.status >= 200 && res.status < 300) {
        toast.update(toastInfo, {
          render: "Fincas renovadas con éxito",
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
    } else {
        toast.update(toastInfo, {
          render: "error al renovar fincas",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
    }
    })
    .catch((err) => {
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      console.error(err);
    });

}
export const subirListadoLOPD = (evt) => {
  let toastInfo = toast.info("Subiendo fincas LOPD...", { autoClose: false });
  const value = evt.target.files[0];
  plantillaFincasLOPD(value).then((res) => {
    if (res.status >= 200 && res.status < 300) {
        toast.update(toastInfo, {
          render: "Fincas subidas con éxito",
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
    } else {
        toast.update(toastInfo, {
          render: "error al subir fincas",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
    }
    })
    .catch((err) => {
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      console.error(err);
    });

}
export const subirListadoAAFF = (evt) => {
toast.info("Subiendo administradores de fincas...");
const value = evt.target.files[0];
plantillaAAFF(value)
    .then((res) => {
    if (res.status >= 200 && res.status < 300) {
        toast.success("Administradores de fincas subidos con éxito");
    } else {
        toast.error("error al subir administradores de fincas");
    }
    })
    .catch((err) => {
    toast.error("Ha ocurrido un error");
    console.error(err);
    });
}

export const subirListado = (evt) => {
toast.info("Subiendo fincas...");
const value = evt.target.files[0];
plantillaFincas(value)
    .then((res) => {
    if (res.status >= 200 && res.status < 300) {
        toast.success("Fincas subidas con éxito");
    } else {
        toast.error("error al subir fincas");
    }
    })
    .catch((err) => {
    toast.error("Ha ocurrido un error");
    console.error(err);
    });
}

export const subirListadoMatcheo = (evt) => {
    toast.info("Realizando matcheo...");
    const value = evt.target.files[0];
    plantillaMatcheo(value)
      .then((res) => {
        if (res.message === "MATCH_CREATED") {
          toast.success("Matcheo realizado con éxito");
        } else {
          toast.error("error al realizar el Matcheo");
        }
        console.log(res);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error");
        console.error(err);
      });
  }
export const eliminarFincas = (aaff) => {
    console.log("eliminarFincasAAFF");
    Swal.fire({
      title: "¿Eliminar todas las fincas del aaff" + aaff.razonSocial + "?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteFincasAAFF(aaff.nif)
          .then((res) => {
            if (res.message === "PROPERTIE_DELETED") {
              toast.success("fincas eliminada");
            } else {
              toast.error("Error al eliminar todas las fincas");
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("Error al eliminar todas las fincas");
          });
      }
    });
  }