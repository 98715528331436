import axios from "axios";

const formHeaders = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  };

const urlPath = "docsSupp";

export const createDocsSupp = async (file, nif, data) => {
  const json = JSON.stringify(data);
  const formData = new FormData();
  formData.append('data', json);
  formData.append("file", file);

  try {
    const response = await axios.post(`${urlPath}/${nif}/doc`, formData, data,{
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const updateDocsSupp = async (file, nif, idDoc, data) => {
    const json = JSON.stringify(data);
    const formData = new FormData();
    formData.append('data', json);
    formData.append("file", file);
    try {
        const response = await axios.put(`${urlPath}/${nif}/doc/${idDoc}`, formData,{
        ...formHeaders,
        });
        return response;
    } catch (err) {
        console.log(err.response);
        return err.response;
    }
};
export const deleteDocsSupp = async (nif, idDoc) => {
    try {
        const response = await axios.delete(`${urlPath}/${nif}/doc/${idDoc}`);
        console.log(response)
        return response;
    } catch (err) {
        console.log(err.response);
        return err.response;
    }
};
export const validateDocsSupp = async (nif, idDoc, data) => {
    try {
        const response = await axios.put(`${urlPath}/validate/${nif}/doc/${idDoc}`, data);
        console.log(response)
        return response;
    } catch (err) {
        console.log(err.response);
        return err.response;
    }
};