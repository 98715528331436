import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import {
  getAAFF,
  deleteAAFF,
  deleteFincasAAFF,
  plantillaFincas,
  plantillaProveedores,
  plantillaMatcheo,
  getConsultores,
} from "../../../services";
import { excelToJson } from "../../../providers/excel-to-json";
import { toast } from "react-toastify";
import Delete from "../../../icons/delete.svg";
import { Grid, Button } from "@mui/material";
import Editar from "../../../icons/editar.svg";
import ModalEditar from "./editarDatosAAFF";
import { formatoFechasEs} from "./../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import { BsDownload, BsUpload } from "react-icons/bs";
import { IoSave, IoDocumentText } from "react-icons/io5";
import ListadoComunidades from "./ListadoComunidades";
import { subirListado, eliminarFincas } from "../../../functions/documentacion";
import Chart from "../../../components/Charts/apexCharts";
import { usuarioContext } from "../../../providers/userContext";
import ObservacionesTecnico from "../../../components/aaff/observacionesTecnico";
import Comentarios from "../../../components/aaff/comentarios";

const Comunidades = (props) => {
  const user = useContext(usuarioContext)
  const [aaff, setAAFF] = useState({});
  const [comentarios, setComentarios] = useState([]);
  const [observaciones, setObservaciones] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(0);
  const [usuarios, setUsuarios] = useState([]);


  useEffect(() => {
    fetchData()
    setRecargar(false);
  }, [recargar]);

  const fetchData = async () => {
    const responseAaffs = await getAAFF(props.params.nif);
    setAAFF(responseAaffs);   
  }

  function eliminarAAFF() {
    deleteAAFF(aaff.nif).then((res) => {
      if (res.message === "AAFF_DELETED") {
        toast.success("aaff eliminado");
      } else {
        toast.error("Error al eliminar el aaff");
      }
      window.location.replace("/aaff");
    });
  }

  function deleteFincas(){
    eliminarFincas(aaff).then(() => {
      setRecargar(!recargar)
    })
  }
  
  function subirFincas(evt){
    subirListado(evt).then(() => {
      setRecargar(!recargar)
    })
  }

  return (
    <>
      <ModalEditar
        aaff={aaff}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <iframe title="pdf" id="iFramePdf" style={{ display: "none" }}></iframe>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Administrador / <b>{aaff.razonSocial}</b>
      </div> */}
      <BreadCrumb rutas={[{path:'/aaff', label:`Administrador`}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to="/aaff">
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Administradores de Fincas"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div className="btnsSubhead">
              <div className="editarBtn" onClick={() => setModalEditarOpened(true)} >
                <span style={{ marginRight: 10 }}>Editar</span>{" "}
                <img src={Editar} alt="Editar" />
              </div>
              <div className="editarBtn" onClick={eliminarAAFF}>
                <span style={{ marginRight: 10 }}>Eliminar</span>{" "}
                <img src={Delete} alt="Eliminar" />
              </div>
            </div>
          </div>
          <div style={{ display:'flex' }}>
            <div className="datosGrid" style={{ width: '75%' }}>
              <p>
                <b>Nombre AAFF:</b> {aaff.razonSocial}
              </p>
              <p>
                <b>NIF:</b> {aaff.nif}
              </p>
              <p>
                <b>Municipio:</b> {aaff.municipio}
              </p>
              <p>
                <b>Provincia:</b> {aaff.provincia}
              </p>
              <p>
                <b>Persona de contacto:</b> {aaff.nombreContacto}
              </p>
              <p>
                <b>Teléfono:</b> {aaff.telefono}
              </p>
              <p>
                <b>Email:</b> {aaff.email}
              </p>
              <p>
                <b>Consultor:</b>{" "}
                {aaff.consultant?.nombre !== undefined
                  ? aaff.consultant?.nombre + " " + aaff.consultant?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Técnico:</b>{" "}
                {aaff.technician?.nombre !== undefined
                  ? aaff.technician?.nombre + " " + aaff.technician?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Comercial:</b>{" "}
                {aaff.commercial?.nombre !== undefined
                  ? aaff.commercial?.nombre + " " + aaff.commercial?.apellidos
                  : "-"}{" "}
              </p>
              <p>
                <b>Fecha de encargo:</b>{" "}
                {aaff.fechaEncargo === undefined || aaff.fechaEncargo === null
                  ? null
                  : formatoFechasEs(aaff.fechaEncargo)}{" "}
              </p>
              <p>
                <b>Fecha de bienvenida:</b>{" "}
                {aaff.fechaBienvenida === undefined ||
                aaff.fechaBienvenida === null
                  ? null
                  : formatoFechasEs(aaff.fechaBienvenida)}{" "}
              </p>
              <p>
                <b>Fecha de Reclamación proveedores:</b>{" "}
                {aaff.fechaReclamacion === undefined ||
                aaff.fechaReclamacion === null
                  ? null
                  : formatoFechasEs(aaff.fechaReclamacion)}{" "}
              </p>
              <p>
                <b>Fecha informe proveedores:</b>{" "}
                {aaff.fechaInforme === undefined || aaff.fechaInforme === null
                  ? null
                  : formatoFechasEs(aaff.fechaInforme)}{" "}
              </p>
            </div>
            {aaff?.properties != undefined ? <Chart datos={aaff?.properties}/> : null}
          </div>
          <div className="datosGrid">
            <Comentarios />
          </div>
          <hr />
          <div className="datosGrid">
            
            <ObservacionesTecnico />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              margin: 10,
            }}
          >
          </div>
          {/* <div className="gridSubhead editarSubhead">
            <div>LISTADOS Y PLANTILLAS</div>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={5}
            style={{ padding: "20px 20px 30px 20px" }}
          >
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoFincas">
                <div className="listadoBtn"><BsUpload alt="subir listado de fincas" style={{ marginRight: 5 }} /> Listado fincas</div>
              </label>
              <input
                type="file"
                id="listadoFincas"
                name="listadoFincas"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirFincas}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaFincas.xlsx"
                  download="plantillaFincas.xlsx"
                >
                  <BsDownload alt="descargar plantilla de fincas" /> Plantilla fincas
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoProveedores">
                <div className="listadoBtn"><BsUpload alt="subir listado de Proveedores" style={{ marginRight: 5 }} />Listado proveedores</div>
              </label>
              <input
                type="file"
                id="listadoProveedores"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirListadoProveedores}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaProveedores.xlsx"
                  download="plantillaProveedores.xlsx"
                >
                  <BsDownload alt="descargar plantilla de proveedores" /> Plantilla proveedores
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoMatcheo">
                <div className="listadoBtn"><BsUpload alt="subir listado de matcheo" style={{ marginRight: 5 }} />Listado matcheo</div>
              </label>
              <input
                type="file"
                id="listadoMatcheo"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={subirListadoMatcheo}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaMatcheo.xlsx"
                  download="plantillaMatcheo.xlsx"
                >
                  <BsDownload alt="descargar plantilla de Matcheo" /> Plantilla matcheo
                </a>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <label htmlFor="listadoRenovacion">
                <div className="listadoBtn"><BsUpload alt="subir listado de renovación" style={{ marginRight: 5 }} />Listado Renovación</div>
              </label>
              <input
                type="file"
                id="listadoRenovacion"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(doc) => {
                  excelToJson(doc.target.files[0], (res) =>
                    this.subirListadoRenovacion(res)
                  );
                }}
                className="inputfile"
              />
              <div className="descargarBtn">
                <a
                  href="/files/plantillaRenovacion.xlsx"
                  download="plantillaRenovacion.xlsx"
                >
                  <BsDownload alt="descargar plantilla de renovación" /> Plantilla renovación
                </a>
              </div>
            </Grid>
          </Grid> */}
          <div className="gridSubhead editarSubhead">
            <div>LISTADO COMUNIDADES</div>
            <div className="subheadBtn" onClick={deleteFincas}>
              <span style={{ marginRight: 10 }}>Eliminar fincas</span>{" "}
              <img src={Delete} alt="Eliminar" />
            </div>
          </div>
          <div className="tableContainer">
            <ListadoComunidades />
          </div>
        </div>
      </div>
      {/* {open && (
        <DialogoCambioAAFF
          open={open}
          finca={fincaCambiarAAFF}
          aaff={props.params.nif}
          tipo={"cambiar"}
          hijoAPadre={hijoAPadre}
        />
      )} */}
    </>
  );
};

export default withRouter(Comunidades);
