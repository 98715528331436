import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getAAFFs, getTecnicos } from '../../services';
import {
    LinearProgress,
    styled,
    Box,
    linearProgressClasses,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel
  } from "@mui/material";
import { usuarioContext } from '../../providers/userContext';

const FiltrosGestion = ({onFilter}) => {
    const [busqueda, setBusqueda] = useState({})
    const [aaff, setAAFF] = useState([])
    const [tecnicos, setTecnicos] = useState([])
    const user = useContext(usuarioContext)

    useEffect(() => {
        getAAFFs({filtros:{}, params:{}}).then( res => {
            setAAFF(res.data)
        })
        if( user.rol !== 'SUPP'){
            getTecnicos().then( res => {
                setTecnicos(res)
            })
        }
    },[])

    function handleChange(evt) {
        const value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
    
      const getBusqueda = () => {
        
        for (let key in busqueda) {
          if (
            busqueda[key] === "" ||
            busqueda[key] === null ||
            busqueda[key] === undefined
          ) {
            delete busqueda[key];
          }
        }
        console.log(busqueda);
        onFilter(busqueda)
      };

    return ( 
        <>
            <div className="buscador">
                <input
                    type="date"
                    name="desde"
                    value={busqueda.desde}
                    placeholder="Desde"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="date"
                    name="hasta"
                    value={busqueda.hasta}
                    placeholder="Hasta"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <select
                    type="text"
                    name="aaff_id"
                    value={busqueda.aaffNif}
                    placeholder="Administrador de fincas"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                    <option value="">Administrador de fincas</option>
                    {aaff.map((e, i) => {
                        return (
                            <option key={i} value={e.id}>
                            {e.razonSocial}
                            </option>
                        );
                    })}
                </select>
                {
                    user.rol !== 'SUPP' && 

                    <select
                        type="text"
                        name="tech_id"
                        value={busqueda.tecnicoNif}
                        placeholder="Técnicos"
                        onChange={handleChange}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                        <option value="">Técnicos</option>
                        {tecnicos.map((e, i) => {
                            return (
                                <option key={i} value={e.id}>
                                {e.nombre+" "+e.apellidos}
                                </option>
                            );
                        })}
                    </select>
                }
                <Button className="btn-buscador" onClick={() => getBusqueda()}>
                    <BsSearch />
                </Button>
                {/* <button onClick={() => getBusqueda()}>
                    <BsSearch />
                </button> */}
            </div>
          <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
        </>
     );
}
 
export default withRouter(FiltrosGestion);