import React, { useState, useEffect, useContext } from "react";
import {
  LinearProgress,
  styled,
  Box,
  linearProgressClasses,
  Typography,
  TextField,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import ReactPaginate from "react-paginate";
import { getAAFF, getAAFFs, getFincasAAFF } from "../../../services";
import { DataTable, LaeButton } from "../../../components";
import { Iconify } from "../../../utilities";
import { useComunidades } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosComunidadesTech from '../../../components/filtros/filtrosComunidadesTech';

const TabFilters = [
  /* { value: "all", label: "TODOS" }, */
  {
    value: "unvisited",
    label: "NO VISITADAS",
  },
  {
    value: "visited",
    label: "VISITADAS",
  },
  {
    value: "refreshed_unvisited",
    label: "RENOVADAS NO VISITADAS",
  },
  {
    value: "refreshed_visited",
    label: "RENOVADAS VISITADAS",
  },
  /*   {
    value: '',
    label: '',
  } */
];

const listaFiltros = [
  { value: "all", label: "TODOS" },
  { value: "comunidades", label: "Comunidades" },
  { value: "aaff", label: "Administrador de Fincas" },
];

const Comunidades = () => {
  const user = useContext(usuarioContext);
  const { loading, data, fetchData, limite } = useComunidades();

  const [aaffs, setAaffs] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const [limit, setLimit] = useState(0);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(0);

  const handleOpenNewTab = ({ nif }) => {
    console.log(nif);
    if (!nif) return;
    window.open(`comunidades/${nif}`, "_blank");
  };

  const handleViewVisitSheet = (url) => {
    // if (!url) return;
    // window.open("localhost:4000" + url.replaceAll("/storage", ""), "_blank");
    window.open(`/doc-render/${url}/hoja-visita`)
  };

  const handleFetchData = async ({ offset, limit }) => {
    console.log(offset, limit)
    
    try {
      await fetchData({ offset, limit, filtros: busqueda });
    } catch (error) {
      console.log(error);
    }
  };

  const comunidadesRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR DE FINCAS",
    },
    {
      id: "community",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "fechaAlta",
      numeric: true,
      disablePadding: false,
      label: "FECHA ALTA",
    },
    {
      id: "direction",
      numeric: false,
      disablePadding: false,
      label: "DIRECCION",
    },
    {
      id: "cp",
      numeric: false,
      disablePadding: false,
      label: "C.P",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    // {
    //   id: "servicios",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "SERVICIOS",
    // },
    {
      id: "empleados",
      numeric: false,
      disablePadding: false,
      label: "EMPLEADOS",
    },
    {
      id: "btn_to",
      numeric: false,
      disablePadding: false,
      label: "",
    },
    

  ];
  useEffect(() => {
    setRecargar(false);
    getData();
  }, [recargar]);

  const getData = async () => {
    const response = await getAAFFs({tech_id: user.id})
    setAaffs(response.data);
  }

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    console.log(filtros);
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Comunidades
        </Typography>
      </div> */}
      <BreadCrumb
        rutas={[{ path: "/comunidades", label: "Comunidades" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
          </div>
          <div className="pageContent tableContainer">
            <FiltrosComunidadesTech onFilter={filtrarDatos} />
            <Box>
              <Box sx={{ mb: 10 }}>
                <DataTable
                  data={data.rows}
                  isLoading={loading}
                  headers={comunidadesRows}
                  fetcher={fetchData}
                  currentPage={data.current_page}
                  meta={data.meta}
                  totalItems={data.total}
                  totalPages={data.totalPages}
                  nextPage={data.next_page}
                  filtros={busqueda}
                  mensaje={"No hay comunidades"}
                >
                  {/* <Box
                    m={2}
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    gap={2}
                  >
                    <Select
                      value={filter}
                      aria-label="controlled"
                      onChange={(e) => setFilter(e.target.value)}
                      sx={{ width: 150 }}
                    >
                      {aaffs.map((filtro) => (
                        <MenuItem value={filtro.id} key={filtro.id}>
                          {filtro.razonSocial}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Razón Social"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                    <LaeButton onClick={handleFetchData} sx={{ width: 100 }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Buscar
                      </Typography>
                    </LaeButton>
                  </Box> */}
                </DataTable>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
