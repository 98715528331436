import React, { useState, useEffect, useContext } from 'react'
import noVisitada from "../icons/noVisitada.svg"
import notaNV from "../icons/notaNV.svg"
import { withRouter } from "../providers/withRouter";
import Swal from 'sweetalert2';
import { Popover, Tooltip, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import { usuarioContext } from "../providers/userContext";
import { formatoFechas, formatoFechasEs } from '../functions/funciones';
import { noRealizada } from '../services';
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
const theme = createTheme();

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1),
//   },
//   customWidth: {
//     maxWidth: 500,
//   },
//   noMaxWidth: {
//     maxWidth: 'none',
//   },
// }));

const NoVisitado = (props) => {
  // const classes = useStyles();
    // const textareaRef = useRef(null);
    const user = useContext(usuarioContext)
    const [rol, setRol] = useState('')
    const finca = props.finca  
    // const initialText = finca.observacionNoVisita === undefined || finca.observacionNoVisita === '' ? new Date().toLocaleDateString()+": "+auth.currentUser.displayName+" - \n" : finca.observacionNoVisita
    const [noRealizado, setNoRealizado] = useState(false);
    const [ObsTexto, setObsTexto] = useState('');
    const [recargar, setRecargar] = useState(false);
    const [visitado, setVisitado] = useState();
    useEffect(() => {
        if(props.tipo === 'finca'){
          setVisitado(finca.detailsCae[0]?.visitada);
          if (finca.detailsCae[0]) {
            if(finca.detailsCae[0]?.unRealized !== false && finca.properties.nif === props.nif){
              
              setNoRealizado(finca.detailsCae[0]?.unRealized);
              setObsTexto(formatoFechasEs(finca.detailsCae[0]?.unrealizedDate)+" - "+finca.detailsCae[0]?.observaciones)
            }
          }
        }
        
        if(props.tipo === 'centroTrabajo'){
          finca.centrosTrabajo.forEach(ct=>{
            if(ct.nif === props.nif && ct.noRealizada !== false){
              setNoRealizado(ct.noRealizada)
              setObsTexto(ct.fechaNoRealizada+" - "+ct.observacionNoRealizada)
            }
          })
        }
    },[finca, recargar])
    
    /* const handleKeydown = (event) => {
      const textarea = textareaRef.current;
      if (textarea) {
        const cursorPosition = textarea.selectionStart;
        if (cursorPosition < initialText.length) {
          event.preventDefault();
        }
      }
    }; */
    const handleCallback = () => {
      setRecargar(!recargar);
      setNoRealizado(true)
    };

    const comprobar = async () => {
      // Llama a la función importada y pasa la devolución de llamada
      handleAdd().then((resultado) => {
        handleCallback(resultado)
      })
    };

    const handleAdd = () => {
      return new Promise(async (resolve, reject) => {
        let toastInfo = undefined
        try {
          Swal.fire({
            title: 'Motivo de la no visita',
            html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" className="swal2-input" >
                    <option>No nos dejan entrar </option>
                    <option>Garaje sin llave</option>
                    <option>Finca no localizada </option>
                    <option>Otros</option>
                    </select>`,
            confirmButtonText: 'Guardar',
            confirmButtonColor: '#05811b',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#d33',
            focusConfirm: false,

            preConfirm: () => {
              toastInfo = toast.info("Guardando datos", { autoClose: false });
              const texto = Swal.getPopup().querySelector('#motivoTexto').value
              if (!texto) {
                Swal.showValidationMessage(`insertar texto`)
              }
              return {texto: texto }
            }

          }).then(async (result) => {
            if(result.value !== undefined){
    
              const datos = {
                unRealized: true,
                unrealizedDate: formatoFechas(new Date()),
                observaciones: result.value.texto
              }
              await noRealizada(finca.id, datos).then( () => {
                toast.update(toastInfo, {
                  render: "datos guardados correctamente",
                  type: toast.TYPE.SUCCESS,
                  autoClose: true
                });
                resolve(true);
              })
    
            }
          })
        } catch (error) {
          reject(error);
        }
      })
    }
    const handleAdd1 = () => {
      let toastInfo = undefined
      Swal.fire({
        title: 'Motivo de la no visita',
        // html: `<textarea id="motivoTexto" style="width: 373px; height: 210px; font-size:11px; padding:10px;" className="swal2-input" ></textarea>`,
        html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" className="swal2-input" >
                <option>No nos dejan entrar </option>
                <option>Garaje sin llave</option>
                <option>Finca no localizada </option>
                <option>Otros</option>
                </select>`,
        confirmButtonText: 'Guardar',
        confirmButtonColor: '#05811b',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#d33',
        focusConfirm: false,
        /* didOpen: () => {
          const textarea = document.getElementById('motivoTexto');
          textarea.value = initialText;
          textarea.setSelectionRange(initialText.length, initialText.length);
          textarea.addEventListener('keydown', handleKeydown);
          textarea.focus();
  
          // Asignar el elemento textarea al useRef
          textareaRef.current = textarea;
        },
        willClose: () => {
          const textarea = textareaRef.current;
          if (textarea) {
            textarea.removeEventListener('keydown', handleKeydown);
            textareaRef.current = null; // Limpiar la referencia al elemento
          }
        }, */
        preConfirm: () => {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          const texto = Swal.getPopup().querySelector('#motivoTexto').value
          if (!texto) {
            Swal.showValidationMessage(`insertar texto`)
          }
          return {texto: texto }
          /* const textarea = textareaRef.current;
          if (textarea) {
            const inputValue = textarea.value;
            // const texto = Swal.getPopup().querySelector('#motivoTexto').value
            if (!inputValue) {
              Swal.showValidationMessage(`insertar texto`)
            }
          return {texto: inputValue }
          } */
        }
      }).then(async (result) => {
        if(result.value !== undefined){

          const datos = {
            unRealized: true,
            unrealizedDate: formatoFechas(new Date()),
            observaciones: result.value.texto
          }
          await noRealizada(finca.id, datos).then( () => {
            toast.update(toastInfo, {
              render: "datos guardados correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
          })

          // Swal.fire({
          //   icon: 'success',
          //   title: 'Datos guardados'
          // })

          // if(props.tipo === 'centroTrabajo'){
          //   console.log(props.tipo)
          //   let centros = finca.centrosTrabajo
          //   const indiceObjetoModificar = finca.centrosTrabajo.findIndex(objeto => objeto.nif === props.nif); //saca el indice del centro de trabajo en el array
          //   console.log(indiceObjetoModificar)
          //   if (indiceObjetoModificar !== -1) {

          //     const objetoModificar = centros.find(objeto => objeto.nif === props.nif);
          //     console.log(centros)
          //     if (objetoModificar) {
          //       //se le añaden estos nuevos campos al centro de trabajo concreto
          //       objetoModificar.observacionNoVisita = result.value.texto;
          //       objetoModificar.fechaNoVisita = new Date();
          //       objetoModificar.noVisitada = true;
          //     }
                
              

          //     console.log(centros)
          //     // firestore.collection("fincas").doc(finca.nif).update({ 
          //     //   centrosTrabajo: centros
          //     // })
          //   }
          // }
          // if(props.tipo === 'finca' ){
            

          //   // console.log(finca)
          //   // firestore.collection("fincas").doc(finca.nif).update({ 
          //   //   observacionNoVisita: result.value.texto,
          //   //   fechaNoVisita: new Date(),
          //   //   noVisitada: true
          //   // })
          // }
          setRecargar(!recargar);
          setNoRealizado(true);
        }
      })
    }
    
    
    return (
      <>
        {visitado !== true && (
          <>
            {user.rol === 'TECH' && !noRealizado && (
              <button type="button" className='btn' onClick={comprobar}>
                <FaEye />
              </button>
            )}
            {noRealizado && (
              <ThemeProvider theme={theme}>
                <Tooltip title={ObsTexto} /* classes={{ tooltip: classes.customWidth }} */>
                  <img src={noVisitada} alt="No visitado" className="tableIcon" />
                </Tooltip>
              </ThemeProvider>
            )}
            <style jsx="true">{`
              .MuiTooltip-popper div {
                padding: 14px;
                font-size: 14px;
                visibility: visible;
                white-space: pre-line;
              }
            `}</style>
          </>
        )}
      </>
    );
}
 
export default withRouter(NoVisitado);