import React from "react";
import jwt_decode from "jwt-decode";

let token = localStorage.getItem("token");
let state = localStorage.getItem("state");
let expires = localStorage.getItem("expires");
let decoded = "";
let usuario = {};
if (state && token !== null && expires !== null) {
  decoded = jwt_decode(token);

  console.log(decoded);
  usuario = {
    exp: decoded?.exp,
    iat: decoded?.iat,
    id: decoded?.data?.id,
    user_id: decoded?.data?.user_id,
    nif: decoded?.data?.nif,
    nombre: decoded?.data?.nombre,
    apellidos: decoded?.data?.apellidos,
    email: decoded?.email,
    rol: decoded?.rol,
    //rol:'admin'
  };
} else {
  usuario = {};
}

export const usuarioContext = React.createContext(usuario);
