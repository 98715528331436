import React, {useState, useEffect} from 'react';
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { getAAFF, cambiarFinca, deleteFinca, deleteCentroTrabajo, renovarFinca} from '../../../services';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { CircularProgress, Tabs, Tab} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { formatoFechasEs } from "../../../functions/funciones";
import Delete from "../../../icons/delete.svg";
import Tick from "../../../icons/tick.svg";
import Change from "../../../icons/change.svg";
import Add from "../../../icons/addBlack.svg";
import RenovarComunidades from "../../../icons/renovarComunidades.svg";
import Excel from "../../../icons/excel.svg";
import ReactPaginate from "react-paginate";
import { DialogoCambioAAFF } from "../../../providers/Dialogos";
import {
    Grid,
    Select,
    FormControl,
    TextField,
    Collapse,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FF5252",
      "&:hover": {
        backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FF5252",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#7bc977",
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      "&:hover": {
        backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#7bc977",
    },
}));
  
const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FFD700",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FFD700",
      backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
}));

const ServicioCae = (props) => {

    const perPage = 3;
    const [offset, setOffset] = useState(0);
    const [selectedPage, setCurrentPage] = useState(0);
    const [pageCount, setpageCount] = useState(0);
    const [isLoading, setIsloading] = useState(true);
    const [recargar, setRecargar] = useState(0);
    const [aaff, setAAFF] = useState({});
    const [fincas, setFincas] = useState([]);
    const [fincasSeleccionadas, setFincasSeleccionadas] = useState([]);
    const [fincaCambiarAAFF, setFincaCambiarAAFF] = useState({});
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const [show, setShow] = useState(false);

    const hijoAPadre = (datosHijo) => {
        setRecargar(datosHijo);
    };

    function changePage(e) {
        console.log(e.selected);
        const d = e.selected * perPage;
        console.log(d);
        setCurrentPage(e.selected);
        setOffset(d);
        receivedData(d, perPage);
    }

    function receivedData(o, p) {
        let total = 0;
        getAAFF(props.params.nif).then((res) => {
          let fin = [];
          res.properties.forEach((f) => {
            if(f.status){
                fin.push(f);
            }
          });
          setAAFF(res);
          setFincas(fin);
          setIsloading(false);
          setpageCount(Math.ceil(fin.length / perPage));
        });
    }

    useEffect(() => {
        receivedData(offset, perPage);
        //setPorcentaje(65)
        setRecargar(false);
    }, [recargar]);
    console.log(fincas)
    return ( 
        <>
            <div className='tableWrapper'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Comunidad</th>
                            <th>NIF</th>
                            <th>Fecha alta</th>
                            <th style={{ textAlign: "center" }}>Dirección</th>
                            <th style={{ textAlign: "center" }}>Código postal</th>
                            <th style={{ textAlign: "center" }}>Municipio</th>
                            <th style={{ textAlign: "center" }}>Provincia</th>
                            <th style={{ textAlign: "center" }}>Empleados</th>
                        </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <tr>
                            <td colSpan="15" style={{ textAlign: "center" }}>
                                <CircularProgress style={{ color: "#fc7271" }} />{" "}
                            </td>
                        </tr>
                    ): fincas.length === 0 ? (
                        <tr>
                            <td colSpan="17" style={{ textAlign: "center" }}>
                            No hay fincas
                            </td>
                        </tr>
                    ): fincas?.slice(offset, offset + perPage).map((e, i) => {
                        return (
                            <React.Fragment key={i}>
                            <tr>
                                <td>{i + 1}</td>
                                <td>
                                <Link
                                    to={
                                    "/aaff/" +
                                    props.params.nif +
                                    "/comunidades/" +
                                    e.nif
                                    }
                                >
                                    {e.razonSocial}
                                </Link>
                                </td>
                                <td>{e.nif}</td>
                                <td>{formatoFechasEs(e.createdAt)}</td>
                                
                                <td style={{ textAlign: "center" }}>
                                    {e.direccion}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {e.codigoPostal}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {e.municipio}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {e.provincia}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {e.trabajadores ? "Si" : "No"}
                                </td>
                            </tr>
                            {e.workcenters === undefined ||
                            e.workcenters.length === 0 ? null : (
                                <>
                                {e.workcenters.map((c, j) => {
                                    return (
                                    <tr
                                        key={j}
                                        style={{
                                        backgroundColor:
                                            j % 2 === 0 ? "#fffbf4" : "#fff9ea",
                                        }}
                                    >
                                        <td></td>
                                        <td colSpan={3} width={"100px"}>
                                        {c.razonSocial}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td
                                        style={{
                                            textAlign: "center",
                                            width: "50px",
                                        }}
                                        >
                                        
                                        </td>
                                    </tr>
                                    );
                                })}
                                </>
                            )}
                            </React.Fragment>
                        );
                        })}
                    </tbody>
                </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
            <div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={changePage}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
            {open && (
                <DialogoCambioAAFF
                    open={open}
                    finca={fincaCambiarAAFF}
                    aaff={props.params.nif}
                    tipo={"cambiar"}
                    hijoAPadre={hijoAPadre}
                />
            )}
        </>
     );
}
 
export default withRouter(ServicioCae);