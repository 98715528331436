import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import Adjuntar from "../../../icons/upload.svg";
import Documento from "../../../icons/document.svg";
import Aviso from "../../../icons/email.svg";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { getFinca } from "../../../services";
import {
  abrirArchivo,
  subirLOPD,
  generarPDF,
} from "../../../functions/funciones";
import HojaVisitaRender from "../tecnico/HojaVisitaRenderKK";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { ComunidadDocs } from "../../../components";

const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [hojasVisita, setHojasVisita] = useState([]);
  const contentRef = useRef(null);
  // const [generar, setGenerar] = useState(false);

  useEffect(() => {
    getFinca(props.params.nif).then((res) => {
      setFinca(res);
      console.log(res)
      setProveedores(res.suppliers);
    });
  }, []);

  const generarArchivo = () => {
    // console.log("AA")
    <HojaVisitaRender generarPDF={true} />;
  };

  return (
    <>
      <BreadCrumb rutas={[{path:'/comunidades/', label:"Comunidades"}, {path:`/comunidades/${props.params.nif}`, label:`${finca?.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS COMUNIDAD</div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca?.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca?.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca?.nif}
            </p>
            <p>
              <b>Municipio:</b> {finca?.municipio}
            </p>
            <p>
              <b>Persona de contacto:</b>{" "}
            </p>
            <p>
              <b>CP:</b> {finca?.codigoPostal}
            </p>
            <p>
              <b>Email:</b> {finca?.email}
            </p>
            <p>
              <b>Provincia:</b> {finca?.provincia}
            </p>
            <p>
              <b>Telefono:</b> {finca?.telefonoContacto}{" "}
            </p>
            <p>
              <b>Presidente:</b> {finca?.nombrePresidente}{" "}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          
          {/* <div className="gridSubhead">SERVICIOS COMUNIDAD</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                <th></th>
                <th>Adjuntar</th>
                <th style={{ margin: 5 }}>Ver</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {finca?.documentos?.lopd?.pendiente ? (
                    <img src={Minus} alt="Pendiente" className="tableIcon" />
                  ) : finca?.documentos?.lopd?.validado ? (
                    <img src={Tick} alt="Validado" className="tableIcon" />
                  ) : (
                    <img src={Cross} alt="No validado" className="tableIcon" />
                  )}
                </td>
                <td style={{ width: "100%" }}>Certificado LOPD</td>
                <td style={{ textAlign: "center" }}>
                  <label htmlFor="lopd">
                    <img src={Adjuntar} alt="Adjuntar" className="tableIcon" />
                  </label>
                  <input
                    type="file"
                    id="lopd"
                    onChange={subirLOPD}
                    className="inputfile"
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <img
                    src={Documento}
                    alt="Ver"
                    className="tableIcon"
                    onClick={() => abrirArchivo(finca?.documentos?.lopd?.ruta)}
                  />
                </td>
              </tr>
              {finca?.centrosTrabajo === undefined ||
              finca?.centrosTrabajo?.length === 0 ? (
                <>
                  <tr>
                    <td>
                      {finca?.documentos?.hojaVisita?.pendiente ? (
                        <img
                          src={Minus}
                          alt="Pendiente"
                          className="tableIcon"
                        />
                      ) : finca?.documentos?.hojaVisita?.validado ? (
                        <img src={Tick} alt="Validado" className="tableIcon" />
                      ) : (
                        <img
                          src={Cross}
                          alt="No validado"
                          className="tableIcon"
                        />
                      )}
                    </td>
                    <td style={{ width: "100%" }}>Hoja de visita</td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={Documento}
                        alt="Ver"
                        className="tableIcon"
                        onClick={() => generarArchivo()}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {finca?.documentos?.evaluacionRiesgos?.pendiente ? (
                        <img
                          src={Minus}
                          alt="Pendiente"
                          className="tableIcon"
                        />
                      ) : finca?.documentos?.evaluacionRiesgos?.validado ? (
                        <img src={Tick} alt="Validado" className="tableIcon" />
                      ) : (
                        <img
                          src={Cross}
                          alt="No validado"
                          className="tableIcon"
                        />
                      )}
                    </td>
                    <td style={{ width: "100%" }}>
                      Identificación de riesgos y medidas preventivas
                    </td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={Documento}
                        alt="Ver"
                        className="tableIcon"
                        onClick={() =>
                          abrirArchivo(
                            finca?.documentos?.evaluacionRiesgos?.ruta
                          )
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {finca.documentos?.planEmergencia?.pendiente ? (
                        <img
                          src={Minus}
                          alt="Pendiente"
                          className="tableIcon"
                        />
                      ) : finca.documentos?.planEmergencia?.validado ? (
                        <img src={Tick} alt="Validado" className="tableIcon" />
                      ) : (
                        <img
                          src={Cross}
                          alt="No validado"
                          className="tableIcon"
                        />
                      )}
                    </td>
                    <td style={{ width: "100%" }}>Plan de emergencia</td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ textAlign: "center" }}>
                      <Link
                        to={`/comunidades/${props.params.nif}/PlanEmergencia`}
                        target={"_blank"}
                      >
                        <img src={Documento} alt="Ver" className="tableIcon" />
                      </Link>
                    </td>
                  </tr>
                </>
              ) : null}
            </tbody>
          </table>
          {finca.centrosTrabajo === undefined ||
          finca.centrosTrabajo.length === 0 ? null : (
            <>
              <div className="gridSubhead">CENTROS DE TRABAJO</div>
              <table style={{ margin: 15 }}>
                <thead>
                  <tr
                    style={{
                      fontSize: 10,
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ margin: 5 }}>Ver</th>
                  </tr>
                </thead>
                <tbody>
                  {finca.centrosTrabajo.map((c, j) => {
                    return (
                      <>
                        <tr>
                          <td
                            colSpan="8"
                            style={{
                              backgroundColor: "#fffbf4",
                              padding: "10px",
                            }}
                          >
                            {c.nombre}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {c.documentos?.hojaVisita?.pendiente ? (
                              <img
                                src={Minus}
                                alt="Pendiente"
                                className="tableIcon"
                              />
                            ) : c.documentos?.hojaVisita?.validado ? (
                              <img
                                src={Tick}
                                alt="Validado"
                                className="tableIcon"
                              />
                            ) : (
                              <img
                                src={Cross}
                                alt="No validado"
                                className="tableIcon"
                              />
                            )}
                          </td>
                          <td style={{ width: "100%" }}>Hoja de visita</td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "center" }}>
                            <img
                              src={Documento}
                              alt="Ver"
                              className="tableIcon"
                              onClick={() =>
                                abrirArchivo(c.documentos?.hojaVisita?.ruta)
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {c.documentos?.evaluacionRiesgos?.pendiente ? (
                              <img
                                src={Minus}
                                alt="Pendiente"
                                className="tableIcon"
                              />
                            ) : c.documentos?.evaluacionRiesgos?.validado ? (
                              <img
                                src={Tick}
                                alt="Validado"
                                className="tableIcon"
                              />
                            ) : (
                              <img
                                src={Cross}
                                alt="No validado"
                                className="tableIcon"
                              />
                            )}
                          </td>
                          <td style={{ width: "100%" }}>
                            Identificación de riesgos y medidas preventivas
                          </td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "center" }}>
                            <img
                              src={Documento}
                              alt="Ver"
                              className="tableIcon"
                              onClick={() =>
                                abrirArchivo(
                                  c.documentos?.evaluacionRiesgos?.ruta
                                )
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {c.documentos?.planEmergencia?.pendiente ? (
                              <img
                                src={Minus}
                                alt="Pendiente"
                                className="tableIcon"
                              />
                            ) : c.documentos?.planEmergencia?.validado ? (
                              <img
                                src={Tick}
                                alt="Validado"
                                className="tableIcon"
                              />
                            ) : (
                              <img
                                src={Cross}
                                alt="No validado"
                                className="tableIcon"
                              />
                            )}
                          </td>
                          <td style={{ width: "100%" }}>Plan de emergencia</td>
                          <td style={{ textAlign: "center" }}></td>
                          <td style={{ textAlign: "center" }}>
                            <Link
                              to={`/comunidades/${props.params.nif}/${c.nif}/PlanEmergencia`}
                              target={"_blank"}
                            >
                              <img
                                src={Documento}
                                alt="Ver"
                                className="tableIcon"
                              />
                            </Link>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>
          )} */}
        </div>
        <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
              </tr>
            </thead>
            <tbody>
              {proveedores.map((e, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{
                        width: "100%",
                        paddingBottom: 10,
                        paddingTop: 10,
                      }}
                    >
                      <Link to={"/proveedores/" + e.nif}>
                        {e.razonSocial}
                        <p style={{ opacity: 0.55, fontSize: 14 }}>
                          {e.serviceSupps.map((s, j) => {
                            return e.serviceSupps.length === j + 1
                              ? s.nombre
                              : s.nombre + ", ";
                          })}
                        </p>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="gridCard">
          <div className="gridHead">SERVICIOS COMUNIDAD</div>
          <ComunidadDocs nifFinca={props.params.nif} />
        </div>
        
      </div>
    </>
  );
};

export default withRouter(Comunidad);
