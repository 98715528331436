import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../functions/ordenarListas";
import { getCommercials, getConsultores, getTecnicos } from "../../services";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import { usuarioContext } from "../../providers/userContext";

const FiltrosAlertasProv = ({ onFilter }) => {

  const user = useContext(usuarioContext)
  const [busqueda, setBusqueda] = useState({});
  const [comercial, setComercial] = useState([]);
  const [consultor, setConsultor] = useState([]);
  const [tecnico, setTecnico] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, busqueda.provincia)
    );
  }, [busqueda]);

  const getDatas = async () => {
    const consul = user.rol === "TECH" || user.rol === "SUPP"  ? '' : await getConsultores();
    setConsultor(consul);
  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    setBusqueda({
      ...busqueda,
      [e.target.name]: e.target.value,
    });
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({ ...m, name: cambiarFormatoNombre(m.name) });
      }
    });
    setListadoMunicipios(municipios);
  };

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    
    // console.log(busqueda);
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscador">
        <div>
            <select name="consultor" onChange={handleChange}>
                <option value=""></option>
                {consultor?.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </option>
                  );
                })}
            </select>
        </div>
        <div>
          <button onClick={() => getBusqueda()}>
            <BsSearch />
          </button>
        </div>
      </div>
    </>
  );
};

export default FiltrosAlertasProv;
