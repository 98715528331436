import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import Minus from "../../icons/minus.svg";
import Adjuntar from "../../icons/upload.svg";
import DescargarNegro from "../../icons/descargar-negro.svg"
import Documento from "../../icons/document.svg";
import { Iconify } from "../../utilities";
import { withRouter } from "../../providers/withRouter";
import { getProveedor, getServicios } from "../../services";
import { eliminaDuplicados } from "../../functions/funciones";
import {
    abrirArchivo,
    subirDeclaracionResponsable,
    validarDeclRes,
  } from "../../providers/documentos";
import { getDeclResList } from "../../services/declRes.service";
import { usuarioContext } from "../../providers/userContext";

const DeclRes = ({nifProv}) => {

    const user = useContext(usuarioContext)
    const [aaff, setAAFF] = useState([]);
    const [fincas, setFincas] = useState([]);
    const nifProveedor = nifProv;
    const [servicios, setServicios] = useState([]);
    const [declResp, setDeclResp] = useState([]);
    const [recargar, setRecargar] = useState(false);
    
    const fetchData = async () => {
        const resp = await getDeclResList()
        console.log(resp)
        getProveedor(nifProv).then((res) => {
            let aaff = [];
            res.properties.forEach((a) => {
                //añadir al objeto aaff las declaraciones responsables correspondientes
                a.aaff.declRes = resp.data.data.find( r => r.supplier.nif === nifProv && r.aaff_id === a.aaff.id ) 
              aaff.push(a.aaff);
            });
            setAAFF(eliminaDuplicados(aaff));
          });
    }

    useEffect(() => {
        fetchData()
        getServicios().then((res) => {
          setServicios(res);
        });
        
      }, [!recargar]);


      const handleSubirDocsCallback = () => {
        setRecargar(!recargar);
      };

      const comprobarDeclRes = async (nifProv, id, ruta, idDeclRes) => {
        // Llama a la función importada y pasa la devolución de llamada
          subirDeclaracionResponsable(nifProv, id, ruta, idDeclRes).then((resultado) => {
            console.log(resultado)
            console.log("resultado")
            handleSubirDocsCallback(resultado)
          })
      };
      const comprobarValDeclRes = async (ruta, idDeclRes, nifAAFF) => {
        // Llama a la función importada y pasa la devolución de llamada
          validarDeclRes(ruta, idDeclRes, nifAAFF).then((resultado) => {
            console.log(resultado)
            console.log("resultado")
            handleSubirDocsCallback(resultado)
          })
      };
      
    return ( <>
      <div className="gridCard">
        <div className="gridHead">DECLARACIONES RESPONSABLES</div>
          {user.rol === 'SUPP' ? 
            <div className="gridSubhead" style={{ display: 'flex', justifyContent: 'end'}}>
              <a
                href="/files/Modelo-Declaracion-Responsable.pdf"
                download="Modelo-Declaracion-Responsable.pdf"
              >
                <span style={{ marginRight: 10 }} >Descargar modelo</span> <img src={DescargarNegro} width={12} alt="Descargar declaración responsable" />
              </a>
            </div>
          : null
          }
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 11,
                  textAlign: 'center',
                  margin: 0,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 400,
                  lineHeight: 1.5,
                  letterSpacing: '0.00938em'
                }}
              >
                <th></th>
                <th></th>
                <th style={{ margin: 5 }}>Ver</th>
                <th>Adjuntar</th>
              </tr>
            </thead>
            <tbody>
              {aaff.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>
                      {e?.declRes?.pendiente ? (
                        <a
                          onClick={() => {
                            comprobarValDeclRes(e.declRes?.ruta, e.declRes?.id, e.nif)
                          }}
                        >
                          <img
                            src={Minus}
                            alt="Pendiente"
                            className="tableIcon"
                          />
                        </a>
                      ) : e.declRes?.validado ? (
                        <img
                          src={Tick}
                          alt="Validado"
                          className="tableIcon"
                        />
                      ) : (
                        <img
                          src={Cross}
                          alt="No validado"
                          className="tableIcon"
                        />
                      )}
                    </td>
                    <td style={{ width: "100%" }}>{e.razonSocial}</td>
                    <td
                      style={{
                        textAlign: "center",
                        opacity: e.declRes?.ruta ===undefined ? 0.4 : 1,
                      }}
                    >
                      <Iconify icon="solar:eye-line-duotone" 
                        alt="Ver"
                        className="tableIcon"
                        onClick={() => abrirArchivo(e?.declRes?.ruta)} />
                      {/* <img
                        src={Documento}
                        
                      /> */}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <label
                        htmlFor="declaracionResponsable"
                        onClick={() =>
                          comprobarDeclRes(nifProv, e.id, e?.declRes?.ruta, e?.declRes?.id )
                        }
                      >
                        <img
                          src={Adjuntar}
                          alt="Adjuntar"
                          className="tableIcon"
                        />
                      </label>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
      </div>
    </> );
}
 
export default withRouter(DeclRes);