import React, {useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
/* import { BellFill, Building, PersonFill } from 'react-bootstrap-icons'; */
import Logo from '../../images/LogoLAE.png'
import LogoMini from '../../images/LogoLAEIcono.png'
import { ImUsers } from "react-icons/im";
import { BsBuildings, BsListCheck, BsFileEarmarkText } from "react-icons/bs";
import { usuarioContext } from "../../providers/userContext";


const Sidebar = (props) => {
  const user = useContext(usuarioContext);
  const { sidebar, checked, routes, routesClientes, routesPersonal, routesListados } = props;
  const [numbtn, setNumbtn] = useState(parseInt(localStorage.getItem("numActivo")));
  const clasesLogo = checked ? "logo ocultar" : "logo mostrar";
  const clasesLogoMini = checked ? "logomini ocultar" : "logomini mostrar";
  const Menu = () => {
    return routes;
  };
  const MenuClientes = () => {
    return routesClientes;
  };
  const MenuPersonal = () => {
    return routesPersonal;
  };
  const MenuListados = () => {
    return routesListados;
  };
  const handleChange = (num) => {
    setNumbtn(num);
    localStorage.setItem("numActivo",num);
  };
  return (
    <>
      <div
        id="mySidebar"
        className="sidebar container"
        style={{ width: sidebar ? "300px" : "115px", paddingLeft: 0 }}
      >
        <div className="header">
          {sidebar ? (
            <img
              src={Logo}
              className={clasesLogo}
              style={{ filter: "brightness(0) invert(1)" }}
              alt="LAE FINCAS"
            />
          ) : (
            <img
              src={LogoMini}
              className={clasesLogoMini}
              style={{ filter: "brightness(0) invert(1)" }}
              alt="LAE FINCAS"
            />
          )}
        </div>
        <div className="menu-content">
          <div className="lista-principal">
            <div className="mt-5"></div>
            <ul>
                {(user.rol === "ADMIN" || user.rol === 'CONSUL' || user.rol === 'COMM' || user.rol === 'AAFF' || user.rol === 'TECH' || user.rol === 'SUPP') &&
                    <li 
                        onClick={() => handleChange(0)} 
                        className={numbtn===0 ? 'active' : ''}
                    >
                        <BsBuildings alt="clientes" /> 
                    </li>
                }
                {(user.rol === "ADMIN" || user.rol === 'CONSUL') &&
                    <li 
                        onClick={() => handleChange(1)} 
                        className={numbtn===1 ? 'active' : ''}
                    >
                        <ImUsers alt="personal" /> 
                    </li>
                }
                {(user.rol === "ADMIN" || user.rol === 'CONSUL' || user.rol === 'TECH') &&
                    <li 
                        onClick={() => handleChange(2)} 
                        className={numbtn===2 ? 'active' : ''}
                    >
                        <BsListCheck alt="Seguimiento" /> 
                    </li>
                }
                {(user.rol === "ADMIN" || user.rol === 'CONSUL' || user.rol === 'COMM') &&
                    <li 
                        onClick={() => handleChange(3)} 
                        className={numbtn===3 ? 'active' : ''}
                    >
                        <BsFileEarmarkText alt="Listados" /> 
                    </li>
                }
            </ul>
          </div>
          <div className="lista mt-5">
            {numbtn === 0 && (
              <ul>
                <MenuClientes />
              </ul>
            )}
            {numbtn === 1 && (
              <ul>
                <MenuPersonal />
              </ul>
            )}
            {numbtn === 2 && (
              <ul>
                <Menu />
              </ul>
            )}
            {numbtn === 3 && (
              <ul>
                <MenuListados />
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
