import React, {useState, useEffect} from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createSupplier, getServicios } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { cambiarFormatoNombre } from "../../../functions/funciones";

const NuevoProveedor = () => {
    const [proveedor, setProveedor] = useState({});
    const [listadoMunicipios, setListadoMunicipios] = useState([]);
    const [servicios, setServicios] = useState([]);

    useEffect(() => {
      fetchData()
    },[])

    const fetchData = async () => {
      const resServicios = await getServicios()
      setServicios(resServicios.data)
    }
    function handleSubmit(data) {
        const toastInfo = toast.info("Guardando proveedor");
        createSupplier(data)
          .then((res) => {
            if(res.status === 201){
              toast.update(toastInfo, {
                render: "proveedor creado con éxito",
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              window.location.replace("/proveedores");
            }
            if(res.status === 403){
              toast.update(toastInfo, {
                render: "error al crear el proveedor",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("Error al crear el proveedor");
          });
      }
      function handleChange(evt) {
        if (evt.target.name === 'servicio[]') {
          const selectedOptions = Array.from(evt.target.selectedOptions, option => option.value);
    
          setProveedor({
            ...proveedor,
            servicio: selectedOptions,
          });
        } else {
          const value = evt.target.value;
          setProveedor({
            ...proveedor,
            [evt.target.name]: value,
          });
        }
        console.log(proveedor)
      }

    function seleccionarMunicipios(e){
        let provincia = ""
        let municipios = []
        provinces.map( p => {
          if(p.code === e.target.value){
            provincia = p.name
          }
        })
        // this.setState({ provincia: provincia })
        console.log(e.target.value)    
        console.log(provincia)    
        setProveedor({
          ...proveedor,
          provincia
        })
        console.log(municipalities)    
        municipalities.map( m => {
          if(m.provCode === e.target.value){
            console.log(m.code, e.target.value)
            municipios.push({...m, name: cambiarFormatoNombre(m.name)})
          }
        })
        setListadoMunicipios(municipios)
        console.log(municipios)    
    }
    return ( 
        <>
            <BreadCrumb rutas={[{path:'/proveedores', label:"Proveedores"}, {path:'/proveedores', label:"Nuevo"}]} style={{ margin: '0 10px'}} />
        <div className="pageContent">
          <div className="gridHead">
            Crear nuevo Proveedor
          </div>
          <form className="nuevoAAFFForm">
            <Grid
              container
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="NIF"
                  name="nif"
                  pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                  title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
                  value={proveedor.nif}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Razón social"
                  name="razonSocial"
                  value={proveedor.razonSocial}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="email"
                  placeholder="Email de contacto"
                  name="email"
                  value={proveedor.email}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Telefono de contacto"
                  name="telefonoContacto"
                  value={proveedor.telefono}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Nombre de contacto"
                  name="nombreContacto"
                  value={proveedor.nombre}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <select
                  name="provincia"
                  onChange={(e) =>
                    seleccionarMunicipios(e)
                  }
                >
                  <option value={proveedor.provincia}>Provincia</option>
                  {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.code}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                {/* <input
                  type="text"
                  placeholder="Provincia"
                  value={proveedor.provincia}
                  onChange={(e) => this.setState({ provincia: e.target.value })}
                ></input> */}
              </Grid>
              <Grid item xs={12} sm={6}>
              <select
                  name="municipio"
                  onChange={handleChange}
                >
                  <option value={proveedor.municipio}>Municipio</option>
                  {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="direccion"
                  name="direccion"
                  value={proveedor.direccion}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Código postal"
                  name="codigoPostal"
                  value={proveedor.codigoPostal}
                  onChange={handleChange}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
              <select
                  name="servicio[]"
                  multiple
                  onChange={handleChange}
                >
                  <option value={proveedor.servicio}>Servicio</option>
                  {servicios.sort()?.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </select>
              </Grid>
            </Grid>
            <input type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(proveedor)}></input>
          </form>
        </div>
        </>
     );
}


export default NuevoProveedor;
