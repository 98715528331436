import React, { useState, useEffect } from "react";
import { getAAFFs, getTecnicos, getFincasAAFF } from "../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";

const ExportarListaTecnicos = (props) => {
  const [tecnicos, setTecnicos] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    let fincas = [];
    let resultado = [];
    let aaff = [];
    let tech = [];
    let promises = [];
    promises.push(
      getFincasAAFF().then((res) => {
        fincas = res;
      }),
      getTecnicos().then((resT) => {
        tech = resT;
      })
    );

    Promise.all(promises).then(() => {
      tech.forEach((t) => {
        let data = {
          tech_id: t.id,
        };

        getAAFFs(data).then((res) => {
          //console.log(res)
          let fincasPendientes = 0;
          let fincasVisitadas = 0;
          let fincasRenovadas = 0;
          let fincasRenovadasVisitadas = 0;
          let nfincas = 0;
          if (res.rows.length === 0) {
            t.porcentaje = parseFloat(
              ((fincasVisitadas / (nfincas || 1)) * 100).toFixed(2)
            );
            t.fincasPendientes = fincasPendientes;
            t.estimacion =
              Math.round((fincasPendientes / 160 + Number.EPSILON) * 100) / 100;
            t.porcentajeRenovadas = parseFloat(
              (
                (fincasRenovadasVisitadas / (fincasRenovadasVisitadas || 1)) *
                100
              ).toFixed(2)
            );
            t.fincasRenovadas = fincasRenovadas;
            t.estimacionRenovadas =
              Math.round(
                (fincasRenovadasVisitadas / 160 + Number.EPSILON) * 100
              ) / 100;
            delete t.user;
            delete t.zona;
            delete t.id;
            delete t.apellidos;
          } else {
            res.rows.forEach((a) => {
              fincas.map((f) => {
                if (a.nif == f.aaff?.nif) {
                  nfincas++;
                  if (f.visitada && !f.renovada && !f.renovadaVisitada) {
                    fincasVisitadas++;
                  }
                  if (f.renovada) {
                    fincasRenovadas++;
                  }
                  if (f.renovadaVisitada) {
                    fincasRenovadasVisitadas++;
                  }
                  if (!f.visitada) {
                    fincasPendientes++;
                  }
                }
              });
              t.nombre = t.nombre + " " + t.apellidos;
              t.porcentaje = parseFloat(
                ((fincasVisitadas / (nfincas || 1)) * 100).toFixed(2)
              );
              t.fincasPendientes = fincasPendientes;
              t.estimacion =
                Math.round((fincasPendientes / 160 + Number.EPSILON) * 100) /
                100;
              t.porcentajeRenovadas = parseFloat(
                (
                  (fincasRenovadasVisitadas / (fincasRenovadasVisitadas || 1)) *
                  100
                ).toFixed(2)
              );
              t.fincasRenovadas = fincasRenovadas;
              t.estimacionRenovadas =
                Math.round(
                  (fincasRenovadasVisitadas / 160 + Number.EPSILON) * 100
                ) / 100;
              delete t.user;
              delete t.zona;
              delete t.id;
              delete t.apellidos;
            });
          }
        });
        resultado.push(t);
        setTecnicos(resultado);
      });
    });
  }, []);

  return (
    <>
      {console.log(tecnicos)}
      {tecnicos.length === props.tecnicos.length ? (
        <ExportToExcel apiData={tecnicos} fileName={"Listado de técnicos"} />
      ) : (
        <span className="bigBtn" style={{ cursor: "context-menu" }}>
          <CircularProgress
            style={{
              color: "#fff",
              width: "16px",
              height: "16px",
              marginRight: "10px",
            }}
          />{" "}
          Generando listado
        </span>
      )}
    </>
  );
};

export default withRouter(ExportarListaTecnicos);
