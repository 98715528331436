import { styled, Button } from "@mui/material";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
}));

function LaeButton({ children, sx, ...props }) {
  return (
    <ColorButton {...props} sx={sx}>
      {children}
    </ColorButton>
  );
}

export default LaeButton;
