import React from "react";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoLAE from "../../../images/LAE.png";
import Cabecera from "../../../images/cabecera.png";
import { incidencias } from "../../../providers/incidencias";
import { useEffect } from "react";
import { getFinca } from "../../../services";

const HojaVisitaRender = (props) => {
  useEffect(() => {
    getFinca(props.params.nif).then((res) => {
      console.log(res);
    });
  }, []);

  return (
    <>
      <table className="tablePage">
        <thead>
          <tr>
            <td style={{ border: "none" }}>
              <div className="header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <div style={{ padding: "0 30px" }}>
            <div
              style={{
                color: "#ffffff",
                backgroundColor: "#152849",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 5,
                fontSize: 14,
              }}
            >
              HOJA DE SEGUIMIENTO
            </div>
            <table>
              <tr>
                <th>Comunidad de propietarios:</th>
                <td>{/* {this.state.hojaVisita?.comunidad.razonSocial} */}</td>
                <th>Actividad:</th>
                <td>COMUNIDAD DE PROPIETARIOS</td>
              </tr>
              <tr>
                <th>Centro de trabajo:</th>
                <td>{/* {this.state.hojaVisita?.comunidad.direccion} */}</td>
                <th>Localidad:</th>
                <td>{/* {this.state.hojaVisita?.comunidad.localidad} */}</td>
              </tr>
              <tr>
                <th>Técnico:</th>
                <td>{/* {this.state.hojaVisita?.tecnico.nombre} */}</td>
                <th>Código Postal:</th>
                <td>{/* {this.state.hojaVisita?.comunidad.codigoPostal} */}</td>
              </tr>
              <tr>
                <th>Fecha de visita:</th>
                <td>
                  {/* {this.state.hojaVisita?.fechaVisitaRenovacion === undefined || (this.state.hojaVisita?.fechaVisitaRenovacion < this.state.hojaVisita?.fechaVisita || Date.parse(this.state.hojaVisita?.fechaVisitaRenovacion) < Date.parse(this.state.hojaVisita?.fechaVisita)) ? this.state.hojaVisita?.fechaVisita : this.state.hojaVisita?.fechaVisitaRenovacion} */}
                </td>
                <th>Administrador de Fincas:</th>
                <td>{/* {this.state.hojaVisita?.aaff.razonSocial} */}</td>
              </tr>
            </table>
            <div
              style={{
                color: "#ffffff",
                backgroundColor: "#152849",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 5,
                fontSize: 14,
              }}
            >
              ACTUACIONES
            </div>
            <table>
              <tr>
                <td>
                  <div style={{ minHeight: 100 }}>
                    Visita al centro de trabajo. Se realiza toma de datos para
                    la identificación de riesgos.
                  </div>
                </td>
              </tr>
            </table>
            <div
              style={{
                color: "#ffffff",
                backgroundColor: "#152849",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 5,
                fontSize: 14,
              }}
            >
              IDENTIFICACIÓN DE RIESGOS
            </div>
            <table id="tableIncidencias">
              <tr>
                <th>
                  Una vez realizada la visita en la comunidad, se observa:
                </th>
              </tr>
              <tr>
                <td>
                  <ul style={{ minHeight: 200 }}>
                    {/* {this.state.incidencias.map((e, i) => <li>{e}</li>)} */}
                  </ul>
                </td>
              </tr>
            </table>
            <div
              style={{
                color: "#ffffff",
                backgroundColor: "#152849",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 5,
                fontSize: 14,
              }}
            >
              OBSERVACIONES
            </div>
            <table>
              <tr>
                <td>
                  <div style={{ minHeight: 100 }}>
                    <p style={{ whiteSpace: "pre-line" }}></p>
                  </div>
                </td>
              </tr>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                marginTop: 30,
                marginBottom: 30,
              }}
            >
              <div style={{ marginRight: 75 }}>
                <p>
                  <b>TÉCNICO DE SEGURIDAD</b>
                </p>
                <p style={{ marginTop: 50 }}>Firma: </p>
                <p style={{ marginTop: 10 }}>
                  <small>Técnico en PRL</small>
                </p>
              </div>
              <div style={{ marginLeft: 75 }}>
                {/* <p><b>RESPONSABLE</b></p>
                                <p style={{ marginTop: 50 }}>Firma: _________________________________</p> */}
              </div>
            </div>
          </div>
        </tbody>
      </table>
      <div
        style={{
          width: "100%",
          height: 120,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 40px",
          backgroundImage: `url(${Cabecera})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{ display: "flex", color: "#ffffff", alignItems: "center" }}
        >
          <img
            style={{ height: 60, filter: "brightness(0) invert(1)" }}
            src={LogoLAE}
            alt="Logo LAE"
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: 20,
              paddingLeft: 20,
              borderLeft: "2px solid #ffffff",
              height: "fit-content",
              fontSize: 20,
            }}
          >
            <p style={{ margin: 0, lineHeight: 1.2, fontWeight: "bold" }}>
              HOJA DE VISITA
            </p>
            <p style={{ margin: 0, lineHeight: 1.2 }}>
              Identificación de riesgos
            </p>
          </div>
        </div>
        {/* <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoMC} alt="Logo M&C" /> */}
      </div>
      <style jsx>{`
        div {
          font-size: 10px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          table-layout: fixed;
        }
        th,
        td {
          border: 1px solid #000000;
          padding: 5px;
          line-height: 1.1;
          text-align: left;
        }
        th {
          background-color: #eaecee;
        }
        p {
          margin: 0;
        }
        ul {
          padding-left: 20px;
        }
        li {
          margin-top: 5px;
        }
        .header-space {
          height: 120px;
        }
        @page {
          size: A4 portrait;
          margin: 0 0 30px 0;
        }
      `}</style>
    </>
  );
};

export default withRouter(HojaVisitaRender);
