import React, { useState, useEffect } from "react";
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet, Font, Svg, Link } from "@react-pdf/renderer"
import LogoLAE from "../../../images/LogoLAE.png"
import Cabecera from "../../../images/cabecera.png"
import CircularProgress from '@mui/material/CircularProgress';
import { getSale, getVisitSheet } from '../../../services';
import { withRouter } from "../../../providers/withRouter";
import { incidencias } from "../../../providers/incidencias";
import RobotoRegular from "../../../fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../fonts/Roboto-BoldItalic.ttf"
import { formatoFechasEs } from "../../../functions/funciones";

Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoItalic, fontWeight: 400 },
      { src: RobotoRegular, fontWeight: 400 },
      { src: RobotoBold, fontWeight: 700 },
      { src: RobotoBoldItalic, fontWeight: 800 },
    ]
});


const styles = StyleSheet.create({
    body: {
        paddingBottom: 65,
        paddingHorizontal: '0cm',
    },
    h1: {
        marginBottom: '30px',
        fontSize: '14px',
        fontFamily: 'Roboto',
        fontWeight: 700
    },
     h2: {
        fontSize: '11px',
        fontFamily: 'Roboto',
        fontWeight: 700,
        marginTop: '10px',
        marginBottom: '10px',
    },
    h3: {
        fontSize: '12px',
        width: '100%',
        padding: '5px 10px',
        // backgroundColor: '#f8f8f8',
        textTransform: 'uppercase',
        // border: '1px solid #e6e6e6',
        // marginTop: '20px',
        marginBottom: '15px',
        fontFamily: 'Roboto',
        fontWeight: 700
    },
    h4: {
        // color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '10px',
        padding: '5px 10px',
        fontFamily: 'Roboto',
        fontWeight: 700
    },
    h5: {
        fontSize: '9px',
        marginTop: '15px',
        marginBottom: '5px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        padding: '5px 10px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '10px',
    },
    ul: {
        // lineHeight: '1.8',
        // fontSize:'7px',
        paddingLeft: 10,
        marginVertical: 8,
        flexDirection: "column", 
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        // fontSize: '10px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "20%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#eaecee',
        color: '#000000',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableCol: { 
        width: "30%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell: { 
        //margin: "auto", 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        fontSize: 7,
        fontWeight:'bold',
        textAlign:'left'
    },
    tableColHeader1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#eaecee',
        color: '#000000',
        fontWeight:'bold',
        textAlign:'left'
    }, 
    tableCol1: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell1: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 60,
        fontSize: 8,
        textAlign:'left'
    },
    tableCell2: { 
        marginLeft:5,
        marginTop: 3, 
        marginBottom: 3, 
        minHeight: 120,
        fontSize: 8,
        textAlign:'left'
    },
    lista: {
        content: '✔',
        fontSize: 11,
        fontFamily: 'Roboto',
        fontWeight: 700,
        color:'#000000'
    },
    textoNormal: {
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    textoItalic: {
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    textoNegritaItalic: {
        fontFamily: 'Roboto',
        fontWeight: 800
    },
    textoNegrita: {
        fontFamily: 'Roboto',
        fontWeight: 700
    },
    icon: {
        width: 10,
        height: 10,
    },
    cuadrado: {
        width:10,
        height:10,
        border: '1px solid #000'
    },
    linea: {
        width:'90%', 
        height:'15px', 
        borderBottom: '1px solid #000000'

    },
    pageHeader: {
        marginTop: 20, 
        paddingBottom: 20, 
        marginHorizontal: '1cm'
    }
  });

const HojaVisitaRender = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [hojasVisita, setHojasVisita] = useState([])
    const [finca, setFinca] = useState({})
    const [imagenes1, setImagenes] = useState('')
    const [inc, setIncidencias] = useState([])
    const [aaff, setAAFF] = useState({})
    const [incidenciasVisibles, setIncidenciasVisibles] = useState([])

    useEffect(() => {
        fetchData()
    },[])


    const fetchData = async () => {
        console.log(props)
        const sale = await getSale(props.params.id)
        
        const visitSheet_id = sale.detailsCae[0]?.visitSheet_id
        const response = await getVisitSheet(visitSheet_id)
        const { data } = response
        let imagenes = []
        data.images.forEach(img => {
            
            imagenes.push(`https://apifincasdev.azurewebsites.net${img.ruta.replace('/storage', '')}`)
        })
        setImagenes(imagenes)
        setHojasVisita(data)
        setFinca(data.propertie)
        setAAFF(data.aaff)
        
        if (data.questions?.p1_1 === "si") inc.push(incidencias.p1_1)
        if (data.questions?.p1_2 === "si") inc.push(incidencias.p1_2)
        if (data.questions?.p1_3 === "si") inc.push(incidencias.p1_3)
        if (data.questions?.p1_4 === "si") inc.push(incidencias.p1_4)
        if (data.questions?.p1_5 === "si") inc.push(incidencias.p1_5)
        if (data.questions?.p1_6 === "si") inc.push(incidencias.p1_6)
        if (data.questions?.p1_7 === "si") inc.push(incidencias.p1_7)
        if (data.questions?.p1_8 === "no") inc.push(incidencias.p1_8)
        if (data.questions?.p2_0 === "si") {
            if (data.questions?.p2_1 === "no") inc.push(incidencias.p2_1)
            if (data.questions?.p2_2 === "no") inc.push(incidencias.p2_2)
            if (data.questions?.p2_3 === "no") inc.push(incidencias.p2_3)
            if (data.questions?.p2_4 === "no") inc.push(incidencias.p2_4)
            if (data.questions?.p2_5 === "no") inc.push(incidencias.p2_5)
            if (data.questions?.p2_5 === "no") {
                if (data.questions?.p2_6 === "no") inc.push(incidencias.p2_6)
                if (data.questions?.p2_7 === "no") inc.push(incidencias.p2_7)
                if (data.questions?.p2_8 === "no" && data.questions?.p2_9 === "no") inc.push(incidencias.p2_8)
            }
            if (data.questions?.p2_9 === "no" && data.questions?.p2_8 === "no") inc.push(incidencias.p2_9)
            if (data.questions?.p2_10 === "no") inc.push(incidencias.p2_10)
        }
        if (data.questions?.p3_0 === "si") {
            if (data.questions?.p3_1 === "no") inc.push(incidencias.p3_1)
            if (data.questions?.p3_2 === "no") inc.push(incidencias.p3_2)
            if (data.questions?.p3_3 === "no") inc.push(incidencias.p3_3)
            if (data.questions?.p3_4 === "no") inc.push(incidencias.p3_4)
        }
        if (data.questions?.p4_0 === "s") {
            if (data.questions?.p4_1 === "no") inc.push(incidencias.p4_1)
            if (data.questions?.p4_2 === "no") inc.push(incidencias.p4_2)
            if (data.questions?.p4_3 === "no") inc.push(incidencias.p4_3)
            if (data.questions?.p4_4 === "no") inc.push(incidencias.p4_4)
            if (data.questions?.p4_5 === "no") inc.push(incidencias.p4_5)
            if (data.questions?.p4_6 === "no") inc.push(incidencias.p4_6)
            if (data.questions?.p4_7 === "no") inc.push(incidencias.p4_7)
            if (data.questions?.p4_8 === "no") inc.push(incidencias.p4_8)
            if (data.questions?.p4_9 === "no") inc.push(incidencias.p4_9)
            if (data.questions?.p4_10 === "no") inc.push(incidencias.p4_10)
            if (data.questions?.p4_11 === "no") inc.push(incidencias.p4_11)
            if (data.questions?.p4_12 === "no") inc.push(incidencias.p4_12)
            if (data.questions?.p4_13 === "no") inc.push(incidencias.p4_13)
            if (data.questions?.p4_14 === "no") inc.push(incidencias.p4_14)
            if (data.questions?.p4_15 === "no") inc.push(incidencias.p4_15)
        }
        if (data.questions?.p5_0 === "si") {
            if (data.questions?.p5_2 === "no") inc.push(incidencias.p5_2)
            if (data.questions?.p5_3 === "no") inc.push(incidencias.p5_3)
        }
        if (data.questions?.p6_0 === "si") {
            if (data.questions?.p6_2 === "no") inc.push(incidencias.p6_2)
            if (data.questions?.p6_3 === "no") inc.push(incidencias.p6_3)
        }
        if (data.questions?.p7_0 === "si") {
            if (data.questions?.p7_1 === "no") inc.push(incidencias.p7_1)
            if (data.questions?.p7_2 === "no") inc.push(incidencias.p7_2)
            if (data.questions?.p7_3 === "no") inc.push(incidencias.p7_3)
            if (data.questions?.p7_4 === "no") inc.push(incidencias.p7_4)
            if (data.questions?.p7_5 === "no") inc.push(incidencias.p7_5)
            if (data.questions?.p7_6 === "no") inc.push(incidencias.p7_6)
        }
        if (data.questions?.p7_0 === "no") inc.push(incidencias.p7_0)
        if (data.questions?.p8_0 === "si") {
            if (data.questions?.p8_1 === "no") inc.push(incidencias.p8_1)
            if (data.questions?.p8_2 === "no") inc.push(incidencias.p8_2)
        }
        if (data.questions?.p8_0 === "no") inc.push(incidencias.p8_0)
        if (data.questions?.p9_0 === "si") {
            if (data.questions?.p9_1 === "no") inc.push(incidencias.p9_1)
            if (data.questions?.p9_2 === "no") inc.push(incidencias.p9_2)
        }
        if (data.questions?.p9_0 === "no") inc.push(incidencias.p9_0)
        setIncidenciasVisibles(inc)
        setIsLoading(false)
    }


    return (
        <>
            {/* {console.log(imagenes1)}
            <div style={{ display:'flex', justifyContent:'center', float:'left'  }}>
                {imagenes1.map( i => {
                    return (
                        <img style={{ width:100, flexDirection: "row", float:'left' }} src={i} alt="a"  /> 
                    )
                })}
            </div> */}
            {console.log(imagenes1)}
            {isLoading ? <CircularProgress style={{color:'#fc7271', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFViewer style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page style={styles.body}>
                            {/* <View style={{marginTop: 0, paddingBottom: 10, marginHorizontal: '0cm'}} fixed>
                                <View style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                                    <Image style={styles.pdfHeaderImg} src={Cabecera} alt="Cabecera" />
                                    <Image style={{ height: 50, position:"absolute", left: 25, top: 20, filter: "brightness(0) invert(1)"}} src={LogoLAE} alt="Logo LAE" />
                                    <View style={{ height: 50, left:100, top:20, marginLeft: 10, paddingLeft: 10, borderLeft: '2px solid #ffffff', color: '#ffffff', fontSize: 15, position: 'absolute' }}>
                                        <Text style={[styles.textoNegrita, { marginTop: 10, lineHeight: 1.2 }]}>HOJA DE VISITA</Text>
                                        <Text style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</Text>
                                    </View>
                                </View> 
                            </View> */}
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                    <View style={{ height: 50, left:100, top:15, marginLeft: 10, paddingLeft: 10, borderLeft: '2px solid #92004D', color: '#92004D', fontSize: 15, position: 'absolute' }}>
                                        <Text style={[styles.textoNegrita, { marginTop: 10, lineHeight: 1.2 }]}>HOJA DE VISITA</Text>
                                        <Text style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#92004D', textAlign: 'center', marginTop: 0, marginBottom: 5, fontSize: 10, padding: 2 }}>HOJA DE SEGUIMIENTO</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Comunidad de propietarios:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita.propertie?.razonSocial}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Actividad:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>COMUNIDAD DE PROPIETARIOS</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Centro de trabajo:</Text> </Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{props.params.idCentroTrabajo ? hojasVisita.workcenter.razonSocial : hojasVisita.propertie?.direccion}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Localidad:</Text> </Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.propertie?.municipio}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Técnico:</Text></Text>  
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.technician.nombre}</Text> 
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Código Postal:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.propertie?.codigoPostal}</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Fecha de visita:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{formatoFechasEs(hojasVisita?.createdAt)}</Text> 
                                                {/* <Text style={styles.tableCell}>{hojasVisita?.fechaVisitaRenovacion === undefined || new Date(hojasVisita?.fechaVisitaRenovacion).getFullYear() === 1970 || (hojasVisita?.fechaVisitaRenovacion < hojasVisita?.fechaVisita) && (Date.parse(hojasVisita?.fechaVisitaRenovacion) < Date.parse(hojasVisita?.fechaVisita)) ? hojasVisita?.fechaVisita : hojasVisita?.fechaVisitaRenovacion}</Text>  */}
                                            </View> 
                                            <View style={styles.tableColHeader}> 
                                                <Text style={styles.tableCell}><Text style={styles.textoNegrita}>Administrador de Fincas:</Text></Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>{hojasVisita?.aaff.razonSocial}</Text> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#92004D', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>ACTUACIONES</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell1}><Text style={styles.textoNegrita}>Visita al centro de trabajo. Se realiza toma de datos para la identificación de riesgos.</Text></Text> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#92004D', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IDENTIFICACIÓN DE RIESGO</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableColHeader1}> 
                                                <Text style={styles.tableCell}>Una vez realizada la visita en la comunidad, se observa:</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <View style={styles.tableCell2}>
                                                    {inc.map((e, i) => 
                                                        <View style={styles.ol}>
                                                            <Text key={i} style={{ marginHorizontal: 4, marginRight: 5 }}>•</Text>
                                                            <Text key={i} style={{ width: '100%', marginRight: 5}}>{e}</Text>
                                                        </View>
                                                    )}
                                                </View> 
                                            </View> 
                                        </View>
                                    </View>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#92004D', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>OBSERVACIONES</Text>
                                    <View style={styles.table}> 
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol1}> 
                                                <Text style={styles.tableCell2}><Text style={{ whiteSpace: "pre-line" }}>{hojasVisita?.comentario}</Text></Text> 
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {imagenes1?.length > 0 ? 
                                <View style={{ marginHorizontal: '1cm' }} break>
                                    <Text style={{ color: '#ffffff', backgroundColor: '#92004D', textAlign: 'center', marginTop: 10, marginBottom: 5, fontSize: 10, padding: 2 }}>IMAGENES</Text>  
                                    <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {imagenes1.map((img, i) => (
                                            <View key={i} style={{ width: '25%', padding: 5 }}>
                                                <Image style={{ width: '100%' }} src={img} alt="a" />
                                            </View>
                                        ))}
                                    </View> 
                                </View>
                            : null}
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={{ display: 'flex', justifyContent: 'left', marginTop: 10, marginBottom: 0 }}>
                                    <View style={{ marginRight: 75, fontSize:8 }}>
                                        <Text style={styles.textoNegrita}>TÉCNICO DE SEGURIDAD</Text>
                                        <Text style={{ marginTop: 20 }}>Firma: {hojasVisita?.technician.nombre}</Text>
                                        <Text style={{ marginTop: 10, fontSize: 0.8 }}>Técnico en PRL</Text>
                                    </View>
                                    <div style={{ marginLeft: 75 }}>
                                        {/* <p><b>RESPONSABLE</b></p>
                                        <p style={{ marginTop: 50 }}>Firma: _________________________________</p> */}
                                    </div>
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            }
        </>
        
    );
}
export default withRouter(HojaVisitaRender);