import React, {useEffect, useState} from "react";
import "./Login.css"
//import { toast } from "react-toastify";
import Logo from "../images/LAE.png"
import BackgroundImage from "../images/loginFondo.jpg"
import { Link } from "react-router-dom";
import { signIn } from "../services";
import { toast } from "react-toastify";

const Login = () => {
  const [mensaje, setMensaje] = useState('')
  const [Incorrecto, setIncorrecto] = useState(false)
  const [loginDatos, setLoginDatos] = useState({
    email: '',
    password: ''
  })
  function handleChange(evt) {
    const value = evt.target.value;
    setLoginDatos({
      ...loginDatos,
      [evt.target.name]: value
    });
  }


  function handleSubmit(){
    console.log(loginDatos)
    if(loginDatos.email==='' || loginDatos.password===''){
      toast.error("Los campos estan vacios")
    }else{
      signIn(loginDatos).then( response => {
        if(response.status === 200){
          window.location.href = "/"
        }
        if(response.status !== 200 || response === undefined){
          toast.error("email o contraseña incorrecto")
        }
      }).catch(e => {
        toast.error("email o contraseña incorrecto")
      })
    }
  }

  return (
    <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
      <form className="loginForm">
        <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
        <input type="email" name="email" required onChange={handleChange} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} placeholder="Correo electrónico" />
        <input type="password" name="password" required minLength="6" onChange={handleChange} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} placeholder="Contraseña" />
        <button type="button" onClick={(e) => handleSubmit(e) } onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''}>Iniciar Sesión</button>
        <Link to="/cambiarPassword" target="_blank">
          <div style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      color: "rgb(48, 50, 72)",
                      margin: "10px auto 0",
                      padding: "8px 30px 0",
                      fontWeight: "normal",
                      fontSize: "10px"
                }}>¿Has olvidado tu contraseña?
          </div>
        </Link>
      </form>
    </div>
  )
}


export default Login;
