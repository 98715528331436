import axios from "axios";

const urlPath = "comment";

export const getComments = async () => {
    try {
      const response = await axios.get(urlPath);
  
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
  };

export const createComment = async (data) => {
  console.log(data)
    try {
        const response = await axios.post(urlPath, data);
        console.log(response.data);

        return response;
    } catch (err) {
        console.log(err.response);
    }
};
export const updateComment = async (id, data) => {
    try {
        const response = await axios.put(`${urlPath}/${id}`, data);
        console.log(response.data);

        return response;
    } catch (err) {
        console.log(err.response);
    }
};

export const deleteComment = async (id) => {
    try {
      const response = await axios.delete(`${urlPath}/${id}`);
      console.log(response.data);
  
      return response;
    } catch (err) {
      console.log(err.response);
    }
};