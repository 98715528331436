import React, { useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";

import {
  getAAFF,
  getFinca,
} from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import ComunidadDocs from "../../../components/Comunidad/ComunidadDocs";
import Ventas from "../general/Ventas";

const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [recargar, setRecargar] = useState(false);

  useEffect(() => {
    console.log(props)
    getFinca(props.params.nifFinca).then((res) => {
      console.log(res)
      getAAFF(res.aaff.nif).then((resAAFF) => {
        console.log(resAAFF)
        res.aaff = resAAFF;
      });
      res.documentos = { hojasVisita: { ano: 2023 } };
      res.centrosTrabajo = [];
      res.ano = 2023;
      setFinca(res);
      setProveedores(res.suppliers.filter(objeto => objeto.status === true))
    });
  }, [recargar]);


  return (
    <>
      <BreadCrumb rutas={[{path:`/aaff/${props.params.nif}/comunidades`, label:`Comunidades`}, {path:`/aaff/${props.params.nif}/comunidades`, label:`${finca.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageGrid" style={{ gridTemplateColumns: '1fr' }}>
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS COMUNIDAD</div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca.nif}
            </p>
            <p>
              <b>Municipio:</b> {finca.municipio}
            </p>
            <p>
              <b>Persona de contacto:</b> {finca.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {finca.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {finca.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {finca.provincia}
            </p>
            <p>
              <b>Telefono de contacto:</b> {finca.telefonoContacto}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
          {/* <AcordeonDocs /> */}
          {/* {console.log(props)} */}
          <Ventas nifFinca={props.params.nifFinca} />
        </div>
        {/* <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
              </tr>
            </thead>
            <tbody>
              {Array.isArray(proveedores)
                ? proveedores.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            paddingTop: 10,
                          }}
                        >
                          <Link to={"/proveedores/" + e.nif}>
                            {e.razonSocial}
                            <p style={{ opacity: 0.55, fontSize: 14 }}>
                              {e.serviceSupps.map((s, j) => {
                                return e.serviceSupps.length === j + 1
                                  ? s.nombre
                                  : s.nombre + ", ";
                              })}
                            </p>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default withRouter(Comunidad);
