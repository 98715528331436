import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";
import {TextField, Select} from "@mui/material/";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { cambiarProveedor } from "../../../services/proveedor.service";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { municipiosIniciales, cambiarFormatoNombre } from "../../../functions/funciones";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { createComment } from '../../../services/comment.service';

/* const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  form: {
    margin: theme.spacing(1),
    width: '100ch',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: ' repeat(2, 1fr)',
    gridGap: '15px',
    fontSize: '12px',
  },
})); */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85ch",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const modal = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const paper = {
  backgroundColor: "#fff",
  border: "2px solid #000",
  boxShadow:
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
  padding: "16px 32px 24px",
};
const form = {
  margin: "8px",
  width: "100ch",
  position: "relative",
  display: "grid",
  gridTemplateColumns: " repeat(2, 1fr)",
  gridGap: "15px",
  fontSize: "12px",
};

export default function TransitionsModal(props) {
  // console.log(props)
  var d = new Date(
    props.proveedor.ultimaReclamacion === null
      ? props.proveedor.createdAt
      : props.proveedor.ultimaReclamacion
  );
  var mm = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  var dd = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  var yy = d.getFullYear();
  let nuevoFormato = yy + "-" + mm + "-" + dd;
  //   const classes = useStyles();
  /* var c = new Date(props.proveedor.createdAt)
    var cmm = c.getMonth() + 1 <10 ? '0'+(c.getMonth() + 1) : c.getMonth() + 1;
    var cdd = c.getDate() <10 ? '0'+c.getDate():c.getDate();
    var cyy = c.getFullYear();
    let nuevoCreatedAt = cyy+"-"+cmm+"-"+cdd */

  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  useEffect(() => {
    
    const proveedor = {
      id: props.proveedor.id,
      razonSocial: props.proveedor.razonSocial,
      direccion: props.proveedor.direccion,
      nif: props.proveedor.nif,
      municipio: props.proveedor.municipio,
      nombreContacto: props.proveedor.nombreContacto,
      codigoPostal: props.proveedor.codigoPostal,
      // emailLogin: props.proveedor.user.email,
      emailContacto: props.proveedor.emailContacto,
      provincia: props.proveedor.provincia,
      telefonoContacto: props.proveedor.telefonoContacto,
      ultimaReclamacion: nuevoFormato,
      // comentarios: props.proveedor.comentarios,
    };
    setEditarDatos(proveedor);
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.proveedor.provincia))
  }, [props]);

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value,
    });
    // console.log(editarDatos)
  }

  const editar = (proveedor) => {
    // console.log(proveedor)
    let data = {
      razonSocial: proveedor.razonSocial,
      direccion: proveedor.direccion,
      nif: proveedor.nif,
      municipio: proveedor.municipio,
      nombreContacto: proveedor.nombreContacto,
      codigoPostal: proveedor.codigoPostal,
      // emailLogin: props.proveedor.user.email,
      emailContacto: proveedor.emailContacto,
      provincia: proveedor.provincia,
      telefonoContacto: proveedor.telefonoContacto,
      ultimaReclamacion: proveedor.ultimaReclamacion,
      // comentarios: proveedor.comentarios
    };
    const toastInfo = toast.info("Guardando datos", { autoClose: false });
    cambiarProveedor(props.proveedor.nif, data)
      .then((res) => {
        // console.log(res)
        if (res.message === "SUPPLIER_UPDATED") {
          toast.update(toastInfo, {
            render: "Datos guardados con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          props.handleClose();
          props.hijoAPadre();
        } else {
          toast.update(toastInfo, {
            render: "no se ha podido editar los datos del proveedor",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
        // let infoComentario = {
        //   prop_id:null,
        //   supp_id:proveedor.id,
        //   aaff_id:null,
        //   texto: proveedor.comentarios,
        //   tipo: "comment"
        // }
        // createComment(infoComentario).then(res => {
        //   console.log(res)
        // })
      })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
    // let d = '';
    // let c = '';
    // if(proveedor.ultimaReclamacion !== "NaN-NaN-NaN"){ d = new Date(proveedor.ultimaReclamacion)}else{ d = ''}
    // if(proveedor.comentarios === undefined){c= "-"}else{ c= proveedor.comentarios}
    //console.log(d)
    /* firestore.collection("proveedores").doc(props.proveedor.nif).update({
      razonSocial: proveedor.razonSocial,
      direccion: proveedor.direccion,
      nif: proveedor.nif,
      municipio: proveedor.municipio,
      codigoPostal: proveedor.codigoPostal,
      provincia: proveedor.provincia,
      emailLogin: proveedor.emailLogin,
      ultimaReclamacion: d,
      comentarios: c,
      contacto: {
        nombre: proveedor.personaContacto,
        email: proveedor.email,
        telefono: proveedor.telefono,
      }
    }).then(() => {
      toast.success("Datos editados con éxito")
      props.handleClose()
    }).catch(err => {
      console.log(err)
      toast.error("Error al editar los datos")
    }) */
  };
  const seleccionarMunicipios = (e) => {
    let codProvincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.name === e.target.value){
        codProvincia = p.code
      }
    })
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value
    });   
    municipalities.map( m => {
      if(m.provCode === codProvincia){
        municipios.push({...m, name: cambiarFormatoNombre(m.name)})
      }
    })
    setListadoMunicipios(municipios)
  }

  return (
    <div>
      <Modal
        sx={modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div
            style={{
              backgroundColor: "#fff",
              border: "2px solid #000",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
              padding: "16px 32px 24px",
            }}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div
                  style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <TextField
                    id="razonSocial"
                    name="razonSocial"
                    label="Nombre proveedor"
                    value={editarDatos.razonSocial}
                    onChange={handleChange}
                  />
                  <TextField
                    id="direccion"
                    name="direccion"
                    label="Dirección"
                    value={editarDatos.direccion}
                    onChange={handleChange}
                  />
                  <Select native name="provincia" id="provincia" style={{ textTransform: 'uppercase'}} onChange={seleccionarMunicipios} defaultValue={editarDatos?.provincia}>
                    <option value="">Provincia</option>
                    {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                      return (
                        <option key={i} value={e.name}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select>
                  <Select native name="municipio" id="municipio" style={{ textTransform: 'uppercase'}} onChange={handleChange} defaultValue={editarDatos?.municipio}>
                    <option value="">Municipio</option>
                    {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                      return (
                        <option key={i} value={e.name}>
                          {e.name}
                        </option>
                      );
                    })}
                  </Select> 
                  <TextField
                    id="nombreContacto"
                    name="nombreContacto"
                    label="Persona de contacto"
                    value={editarDatos.nombreContacto}
                    onChange={handleChange}
                  />
                  <TextField
                    id="emailContacto"
                    name="emailContacto"
                    label="Email de contacto"
                    value={editarDatos.emailContacto}
                    onChange={handleChange}
                  />
                  <TextField
                    id="emailLogin"
                    name="emailLogin"
                    label="Email login"
                    value={editarDatos.emailLogin}
                    onChange={handleChange}
                  />
                  <TextField
                    id="codigoPostal"
                    type="number"
                    name="codigoPostal"
                    label="C.P."
                    value={editarDatos.codigoPostal}
                    onChange={handleChange}
                  />
                  <TextField
                    id="telefonoContacto"
                    type="number"
                    name="telefonoContacto"
                    label="Teléfono"
                    value={editarDatos.telefonoContacto}
                    onChange={handleChange}
                  />
                  <TextField
                    id="ultimaReclamacion"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="ultimaReclamacion"
                    label="Última Reclamación"
                    defaultValue={editarDatos.ultimaReclamacion}
                    onChange={handleChange}
                  />
                  <div></div>
                </div>
                {/* <TextField
                  style={{ display: "flex" }}
                  id="comentarios"
                  name="comentarios"
                  label="Comentarios"
                  value={editarDatos.comentarios}
                  onChange={handleChange}
                  multiline
                  rows={6}
                  columns={100}
                /> */}
                <div
                  style={{
                    margin: "8px",
                    width: "100ch",
                    position: "relative",
                    display: "grid",
                    gridTemplateColumns: " repeat(2, 1fr)",
                    gridGap: "15px",
                    fontSize: "12px",
                  }}
                >
                  <Button variant="contained" onClick={props.handleClose}>
                    Cancelar
                  </Button>
                  <Button
                    className="btn-Secondary"
                    variant="contained"
                    color="secondary"
                    onClick={() => editar(editarDatos)}
                  >
                    Editar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
