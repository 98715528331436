import axios from "axios";

const urlPath = "consultant";

export const createCons = async (data) => {
  const datos = {
    nombre: data.nombre,
    apellidos: data.apellidos,
    nif: data.nif,
    email: data.email,
  };
  try {
    const response = await axios.post(urlPath, datos);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const getConsultores = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getConsultor = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const asignarConsultor = async (nif, id) => {
  const datos = {
    consul_id: id,
  };
  console.log(nif, id);
  try {
    const response = await axios.put(`aaff/${nif}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteConsultor = async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    //console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
