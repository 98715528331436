import React from "react";
import { withRouter } from "../../../providers/withRouter";
import { getAAFF } from "../../../services";
import { getConsultores, asignarConsultor } from "../../../services";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import Add from "../../../icons/add.svg";
import { filterItems } from "../../../functions/funciones";

class AsignarConsultor extends React.Component {
  constructor() {
    super();
    this.state = {
      nombre: "",
      aaff: undefined,
      resultados: undefined,
      consultores: [],
    };
  }

  componentDidMount() {
    getAAFF(this.props.params.nif).then((res) => {
      this.setState({ aaff: res });
    });
    getConsultores().then((res) => {
      let consultores = [];
      res.forEach((c) => {
        consultores.push({
          id: c.id,
          nif: c.nif,
          nombre: c.nombre,
          apellidos: c.apellidos,
          busqueda: c.nombre + " " + c.apellidos,
        });
        console.log(consultores);
        this.setState({ consultores: consultores });
      });
    });
  }

  buscar = (e) => {
    e.preventDefault();
    this.setState({
      resultados: filterItems(this.state.nombre, this.state.consultores),
    });
  };

  render() {
    if (this.state.aaff === undefined) return null;
    return (
      <>
        <div
          className="pageHeader"
          style={{
            backgroundImage: `url(${HeaderBackground})`,
            textAlign: "left",
          }}
        >
          Administradores de Fincas / {this.state.aaff.razonSocial} /{" "}
          <b>Asignar técnico</b>
        </div>
        <div className="pageContent">
          <div className="titleContent">Asignar consultor</div>
          <form className="nuevoAAFFForm" onSubmit={this.buscar}>
            <Grid
              container
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid item xs={12} sm={4}>
                <input
                  type="text"
                  placeholder="Nombre"
                  required
                  value={this.state.nombre}
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                ></input>
              </Grid>
            </Grid>
            <input type="submit" value="Buscar"></input>
          </form>
          <Resultados
            resultados={this.state.resultados}
            aaff={this.state.aaff}
          />
        </div>
      </>
    );
  }
}

class Resultados extends React.Component {
  enviarEmail = (e) => {
    console.log("enviar email");
    console.log(e);
  };

  asignar = (resultado) => {
    console.log(resultado);
    console.log(this.props.aaff);
    asignarConsultor(this.props.aaff.nif, resultado.id)
      .then((res) => {
        console.log(res);
        toast.success("Consultor añadido con éxito");
        /* if(this.props.aaff.notificado !== undefined && this.props.aaff.notificado === false){
            this.enviarEmail({email: resultado.email, aaffNombre: this.props.aaff.razonSocial})
        } */
        window.location.replace("/aaff");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al añadir el técnico");
      });
  };
  render() {
    if (this.props.resultados === undefined) return null;
    return (
      <div className="tableContainer" style={{ marginTop: 30 }}>
        <table>
          <thead>
            <tr>
              <th>Consultor</th>
              <th>NIF</th>
              <th style={{ textAlign: "center" }}>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resultados.map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e.nombre + " " + e.apellidos}</td>
                  <td>{e.nif}</td>
                  <td style={{ textAlign: "center" }}>
                    <div
                      className="asignarButton"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => this.asignar(e)}
                    >
                      <img src={Add} alt="Más" />
                      Asignar
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(AsignarConsultor);
