import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./routes/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HojaVisitaRender from "./routes/pages/tecnico/HojaVisitaRender";
import EvaluacionRiesgosRender from "./routes/pages/tecnico/EvaluacionRiesgosRender";
/*import PlanPrevencionRender from "./routes/pages/tec/PlanPrevencionRender"; */
import PlanEmergenciaRender from "./routes/pages/tecnico/PlanEmergenciaRender";
/*import HojaVisitaRenderCentroTrabajo from "./routes/pages/tec/HojaVisitaRenderCentroTrabajo";
import EvaluacionRiesgosRenderCentroTrabajo from "./routes/pages/tec/EvaluacionRiesgosRenderCentroTrabajo";
import PlanPrevencionRenderCentroTrabajo from "./routes/pages/tec/PlanPrevencionRenderCentroTrabajo";
import PlanEmergenciaRenderCentroTrabajo from "./routes/pages/tec/PlanEmergenciaRenderCentroTrabajo";*/
import InformeSituacionProveedores from "./routes/pages/admin/InformeSituacionProveedores";
import InformeSituacionComunidades from "./routes/pages/admin/InformeSituacionComunidades";
import InformeSituacionRiesgos from "./routes/pages/admin/InformeSituacionRiesgos";
import PDF from "./providers/pdf";
import { AxiosInterceptor } from "./interceptors/axios.interceptor";
import CertificadoVigilancia from "./components/pdfs/certificadosVigilancia";
import CertificadosRGPD from "./components/pdfs/certificadosRGPD";
import DocumentacionLOPD from "./components/pdfs/documentacionLOPD";
/* import CambioPassword from "./routes/cambiarPassword" */
import HojaVisitaPDF from "./routes/pages/admin/HojaVisitaPDF";
import PlanEmergenciaPDF from "./routes/pages/admin/PlanEmergenciaPDF";
import EvaluacionRiesgosPDF from "./routes/pages/admin/EvaluacionRiesgosPDF";

AxiosInterceptor();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Router>
      <Routes>
        {/* <Route path="/cambiarPassword" element={<CambioPassword />} /> */}
        {/* <Route path="passwordset" element={<PasswordSet />} /> */}
        <Route path="login" element={<Login />} />
        <Route
          path="/doc-render/:nif/:id/hoja-visita"
          element={<HojaVisitaPDF />}
        />
        <Route
          path="/doc-render/:nif/:idCentroTrabajo/:id/hoja-visita"
          element={<HojaVisitaPDF />}
        />
         <Route path="/doc-render/:nif/:id/identificacion-riesgos" element={<EvaluacionRiesgosPDF />} />
         <Route path="/doc-render/:nif/:idCentroTrabajo/:id/identificacion-riesgos" element={<EvaluacionRiesgosPDF />} />
        {/*  <Route path="/doc-render/:nif/plan-prevencion" element={<PlanPrevencionRender />} /> */}
        <Route
          path="/doc-render/:nif/:id/plan-emergencia"
          element={<PlanEmergenciaPDF />}
        />
        <Route
          path="/doc-render/:nif/:idCentroTrabajo/:id/plan-emergencia"
          element={<PlanEmergenciaPDF />}
        />
        {/* <Route path="/doc-render/:nif/:nifCentroTrabajo/hoja-visita" element={<HojaVisitaRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/evaluacion-riesgos" element={<EvaluacionRiesgosRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-prevencion" element={<PlanPrevencionRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-emergencia" element={<PlanEmergenciaRenderCentroTrabajo />} /> */}
        <Route
          path="/doc-render/:nif/situacion-proveedores"
          element={<InformeSituacionProveedores />}
        />
        <Route
          path="/doc-render/:nif/situacion-comunidades"
          element={<InformeSituacionComunidades />}
        />
        <Route
          path="/doc-render/:nif/situacion-riesgos"
          element={<InformeSituacionRiesgos />}
        />
        <Route
          path="/doc-render/:nif/:id/video-vigilancia"
          element={<CertificadoVigilancia />}
        />
        <Route
          path="/doc-render/:nif/:id/auditoria"
          element={<CertificadosRGPD />}
        />
        <Route
          path="/doc-render/:nif/:id/documentacion"
          element={<DocumentacionLOPD />}
        />
        <Route path="/doc-render/:nif/pdf" element={<PDF />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
