import axios from "axios";

export const signIn = async ({ email, password }) => {
  const data = { email, password };
  const timeNow = Date.now();
  let fechaExpire = new Date(timeNow);

  try {
    const response = await axios.post("auth/login", data);
    if (response.status === 200) {
      localStorage.setItem("expires", new Date(fechaExpire).toUTCString());
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("state", true);
      localStorage.setItem("numActivo", 0);
      //window.location.replace("/Inicio");
    }
    return response;
  } catch (err) {
    console.log(err.response);
    //return window.location.replace("/login")
  }
};
export const logout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("state");
  localStorage.removeItem("expires");
  localStorage.removeItem("numActivo");
  // document.cookie.split(";").forEach(function(c) {
  //     document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  //   });
  window.location.replace("/login");
};
