import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import Editar from "../../../icons/editar.svg";
import Adjuntar from "../../../icons/upload.svg";
import Documento from "../../../icons/document.svg";
import Aviso from "../../../icons/email.svg";
import { Link } from "react-router-dom";
import ModalEditar from "./editarDatosComunidad";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { CircularProgress, Tabs, Tab } from "@mui/material";

import {
  getAAFF,
  getFinca,
  getServicios,
  getProveedores,
} from "../../../services";
import { abrirArchivo } from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import ComunidadDocs from "../../../components/Comunidad/ComunidadDocs";
import NoVisitado from "../../../components/noVisitadoComunidad";

const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [expandir, setExpandir] = useState(false);
  const [serviciosProveedores, setServiciosProveedores] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);

  useLayoutEffect(() => {
    console.log(props)
    console.log(props.params.nifFinca)
    getFinca(props.params.nifFinca).then((res) => {
      getAAFF(res.aaff.nif).then((resAAFF) => {
        res.aaff = resAAFF;
      });
      res.documentos = { hojasVisita: { ano: 2023 } };
      res.centrosTrabajo = [];
      res.ano = 2023;

      let provs = res.suppliers.filter(objeto => objeto.status === true)
      // let servicio = ''
      // provs.forEach(p => {
      //   console.log(p.service_to_propertie.service)
      //   servicio = p.serviceSupps?.find(serv => serv.id === p.service_to_propertie.service).nombre
      //   console.log(servicio)
      //   provs.servicio = servicio
      // })    
      console.log(provs)
      setFinca(res);
      setProveedores(provs)
    });
    getServicios().then((res) => {
      // console.log(res);
      //setServiciosProveedores(res)
    });
    // getProveedores().then((provs) => {
    //   console.log(provs);
    //   console.log(provs.rows.serviceSupps);
    //   setProveedores(provs.rows);
    //   setServiciosProveedores(provs.rows.serviceSupps);
    // });
  }, [recargar]);

  const loopHv = (num, f) => {
    let y = 0;
    let arr = [];
    let anos = [];
    let rutas = [];
    for (let x = f.ano; x <= num; x++) {
      //console.log(x)
      y++;
      anos.push(x);

      /* if(x === 2022 || f.documentos.hojaVisita?.ano === '' || f.documentos.hojaVisita?.ano === undefined){
                rutas.push("/"+f.nif+"/hoja-visita.pdf")
            }else{
                rutas.push("/"+f.nif+"/hoja-visita_"+x+".pdf")
            } */
    }
    for (let z = 0; z < y; z++) {
      arr.push(
        <tr key={z}>
          <td>
            {finca.documentos.hojaVisita?.pendiente ? (
              <img src={Minus} alt="Pendiente" className="tableIcon" />
            ) : finca.documentos.hojaVisita?.validado ? (
              <img src={Tick} alt="Validado" className="tableIcon" />
            ) : (
              <img src={Cross} alt="No validado" className="tableIcon" />
            )}
          </td>
          <td style={{ width: "100%" }}>Hoja de visita {anos[z]}</td>
          <td style={{ textAlign: "center" }}></td>
          <td style={{ textAlign: "center" }}>
            <img
              src={Documento}
              alt="Ver"
              className="tableIcon"
              onClick={() => abrirArchivo(rutas[z])}
            />
          </td>
        </tr>
      );
    }
    return arr;
  };

  if (!finca) return null; //Loading data...
  return (
    <>
      <ModalEditar
        finca={finca}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comunidad / <b>Vecinos {finca.razonSocial}</b>
      </div> */}
      <BreadCrumb rutas={[{path:`/aaff/${props.params.nif}/comunidades`, label:`Comunidades`}, {path:`/aaff/${props.params.nif}/comunidades`, label:`${finca.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS COMUNIDAD</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca.nif}
            </p>
            <p>
              <b>Municipio:</b> {finca.municipio}
            </p>
            <p>
              <b>Persona de contacto:</b> {finca.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {finca.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {finca.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {finca.provincia}
            </p>
            <p>
              <b>Telefono de contacto:</b> {finca.telefonoContacto}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
          {/* <AcordeonDocs /> */}
          {/* {console.log(props)} */}
          <ComunidadDocs nifFinca={props.params.nifFinca} />
        </div>
        <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(proveedores)
                ? proveedores.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            paddingTop: 10,
                          }}
                        >
                          <Link to={"/proveedores/" + e.nif}>
                            {e.razonSocial}
                            <p style={{ opacity: 0.55, fontSize: 14 }}>
                              {
                                e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find( serv => serv.id === e.service_to_propertie.service)?.nombre : null
                              }
                              {/* {e.serviceSupps.map((s, j) => {
                                return e.serviceSupps.length === j + 1
                                  ? s.nombre
                                  : s.nombre + ", ";
                              })} */}
                            </p>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : null}
              {/* {proveedores?.map((e, i) => {
                            return (
                            <tr key={i}>
                                <td style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}>
                                    <Link to={"/proveedores/" + e.nif}>{e.razonSocial}<p style={{ opacity: 0.55, fontSize: 14 }}>{serviciosProveedores[e.servicio]}</p></Link>
                                </td>
                            </tr>
                            )
                        })} */}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidad);

// class Comunidad extends React.Component {
//   constructor() {
//     super()
//     this.state = {
//       finca: undefined,
//       proveedores: [],
//       existeHojaVisita: false,
//       anoFechaVisita: 0,
//       modalEditarOpened: false,
//       expandir: true
//     }
//   }
//   componentDidMount() {
//     this.loadPageContent()
//   }

//   loadPageContent = () => {
//     firestore.collection("fincas").doc(this.props.params.nifFinca).get().then(snapshot => {
//         let finca = snapshot.data();
//         let proveedores = [];
//         let promises = [];
//         promises.push(firestore.collection("aaff").doc(finca.nifAAFF).get().then(a=> {finca.aaff = {telefono:a.data().telefono, email: a.data().email, nombre: a.data().contacto}}))
//         finca.proveedores.forEach(proveedor => {
//           promises.push(firestore.collection("proveedores").doc(proveedor.nif).get().then(p => proveedores.push({ ...p.data(), servicio: proveedor.servicio })))
//         });
//         Promise.all(promises).then(() => {
//           console.log(finca.aaff)
//           this.setState({ finca: finca, proveedores: proveedores })
//         })
//       }).catch(error => {
//         window.location.replace("/")
//       })
//       firestore.collection("hojasVisita").doc(this.props.params.nifFinca).get().then( hv => hv.exists ? this.setState({existeHojaVisita:true, anoFechaVisita: new Date(hv.data().fechaVisita.seconds*1000).getFullYear()}) : null)
//   }

//   subirLOPD = (event) => {
//     storage.ref(`${finca.nif}/${event.target.files[0]["name"]}`).put(event.target.files[0]).then(snapshot => {
//       this.setState(prevState => ({
//         finca: {
//           ...prevState.finca,
//           estado: prevState.finca.documentos?.lopd?.validado ? prevState.finca.estado - 5 : prevState.finca.estado,
//           documentos: {
//             ...prevState.finca.documentos,
//             lopd: {
//               ...prevState.finca.documentos?.lopd,
//               ruta: snapshot.ref.fullPath,
//               pendiente: true,
//               validado: false
//             }
//           }
//         }
//       }))
//       firestore.doc(`fincas/${finca.nif}`).update(finca)
//       toast.success("El archivo se ha subido correctamente")
//     }).catch(error => {
//       console.log(error)
//       toast.error("Ha ocurrido un error al subir el archivo")
//     })
//   }

//   abrirArchivo = (ruta) => {
//       console.log(ruta)
//     if (ruta === "") {
//       toast.error("No existe el archivo")
//     } else {
//       storage.ref(ruta).getDownloadURL().then(url => {
//         window.open(url, "_blank")
//       }).catch(error => {
//         console.log(error)
//         toast.error("Ha ocurrido un error al descargar el archivo")
//       })
//     }

//   }

//   loopHvCt = (num, c, fNif) => {
//       //console.log(num)
//       let y = 0
//       let arr = []
//       let anos = []
//       let rutas = []
//       for(let x = 2022; x <= num; x++){
//         //console.log(x)
//         y++
//         anos.push(x)
//         if(x === 2022){
//             rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita.pdf")
//         }else{
//             rutas.push("/"+fNif+"/"+c.nif+"/hoja-visita_"+x+".pdf")
//         }
//     }

//     for(let z = 0; z < y; z++){
//         arr.push(<tr>
//             <td>{c.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//             <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
//             <td style={{ textAlign: 'center' }}></td>
//             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
//         </tr>)
//     }
//     return arr
//   }

//   loopHv = (num, f) => {
//     //console.log(num)
//     let y = 0
//     let arr = []
//     let anos = []
//     let rutas = []
//     for(let x = anoFechaVisita; x <= num; x++){
//       //console.log(x)
//       y++
//       anos.push(x)
//       if(x === 2022 || f.documentos.hojaVisita?.ano === '' || f.documentos.hojaVisita?.ano === undefined){
//           rutas.push("/"+f.nif+"/hoja-visita.pdf")
//       }else{
//           rutas.push("/"+f.nif+"/hoja-visita_"+x+".pdf")
//       }
//     }

//     for(let z = 0; z < y; z++){
//         arr.push(<tr>
//             <td>{finca.documentos?.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//             <td style={{ width: '100%' }}>Hoja de visita {anos[z]}</td>
//             <td style={{ textAlign: 'center' }}></td>
//             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(rutas[z])} /></td>
//         </tr>)
//     }
//     return arr
//   }

//   render() {
//     if (!finca) return null //Loading data...
//     return <>
//          <ModalEditar finca={finca} opened={modalEditarOpened} handleClose={() => {
//           this.setState({ modalEditarOpened: false })
//           this.loadPageContent()
//         }} />
//         <div className="pageHeader" style={{ backgroundImage: `url(${HeaderBackground})`, textAlign: 'left' }}>Comunidad / <b>Vecinos {finca.razonSocial}</b></div>
//         <div className="pageGrid">
//             <div className="gridCard">
//                 <div className="gridHead">INFORMACIÓN GENERAL</div>
//                 <div className="gridSubhead editarSubhead">
//                     <div>DATOS COMUNIDAD</div>
//                     <div className="editarBtn" onClick={() => this.setState({ modalEditarOpened: true })}><span style={{ marginRight: 10 }} >Editar</span> <img src={Editar} alt="Editar" /></div>
//                 </div>
//                 <div className="datosGrid">
//                     <p><b>Nombre comunidad:</b> {finca.razonSocial}</p>
//                     <p><b>Dirección:</b> {finca.direccion}</p>
//                     <p><b>NIF:</b> {finca.nif}</p>
//                     <p><b>Municipio:</b> {finca.municipio}</p>
//                     <p><b>Persona de contacto:</b> {finca.aaff.nombre}</p>
//                     <p><b>CP:</b> {finca.codigoPostal}</p>
//                     <p><b>Email:</b> {finca.aaff.email}</p>
//                     <p><b>Provincia:</b> {finca.provincia}</p>
//                     <p><b>Telefono:</b> {finca.aaff.telefono}</p>
//                     {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
//                 </div>
//                 <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
//                     <table style={{ margin: 15 }}>
//                     <thead>
//                     <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
//                         <th></th>
//                         <th></th>
//                         <th>{/* Adjuntar */}</th>
//                         <th style={{ margin: 5 }}>Ver</th>
//                     </tr>
//                     </thead>
//                     <tbody>
//                     <tr>
//                         <td>{finca.documentos?.lopd?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.lopd?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                         <td style={{ width: '100%' }}>Certificado LOPD</td>
//                         <td style={{ textAlign: 'center' }}>{/* <label htmlFor="lopd"><img src={Adjuntar} alt="Adjuntar" className="tableIcon" /></label><input type="file" id="lopd" onChange={this.subirLOPD} className="inputfile" /> */}</td>
//                         <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(finca.documentos?.lopd?.ruta)} /></td>
//                     </tr>
//                     {(finca.centrosTrabajo === undefined || finca.centrosTrabajo.length === 0) ?
//                     <>
//                     {/* <tr>
//                         <td>{finca.documentos?.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                         <td style={{ width: '100%' }}>Hoja de visita</td>
//                         <td style={{ textAlign: 'center' }}></td>
//                         <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(finca.documentos?.hojaVisita?.ruta)} /></td>
//                     </tr> */}
//                     {this.loopHv(finca.documentos?.hojaVisita?.ano === undefined ? 2022 : finca.documentos?.hojaVisita?.ano, finca)}
//                     {existeHojaVisita === false ?
//                         <tr>
//                         <td>{finca.documentos?.prevencionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                         <td style={{ width: '100%' }}>Plan de prevención</td>
//                         <td style={{ textAlign: 'center' }}></td>
//                         <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(finca.documentos?.prevencionRiesgos?.ruta)} /></td>
//                         </tr>
//                     : null}
//                         {finca.documentos?.evaluacionRiesgos?.pendiente || finca.documentos?.evaluacionRiesgos?.validado ?
//                         <tr>
//                             <td>{finca.documentos?.evaluacionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : "" /* <img src={Cross} alt="No validado" className="tableIcon" /> */}</td>
//                             <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
//                             <td style={{ textAlign: 'center' }}></td>
//                             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(finca.documentos?.evaluacionRiesgos?.ruta)} /></td>
//                         </tr>
//                         : console.log(finca.documentos?.evaluacionRiesgos?.pendiente, finca.documentos?.evaluacionRiesgos?.validado)}
//                     <tr>
//                         <td>{finca.documentos?.planEmergencia?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : finca.documentos?.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                         <td style={{ width: '100%' }}>Plan de emergencia</td>
//                         <td style={{ textAlign: 'center' }}></td>
//                         <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(finca.documentos?.planEmergencia?.ruta)} /></td>
//                     </tr>
//                     </>
//                     :
//                     null
//                     }

//                     </tbody>
//                     </table>
//                     {(finca.centrosTrabajo === undefined || finca.centrosTrabajo.length === 0) ? null :
//                         <>
//                             <div className="gridSubhead">CENTROS DE TRABAJO</div>
//                             <table style={{ margin: 15 }}>
//                                 <thead>
//                                     <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
//                                         <th></th>
//                                         <th></th>
//                                         <th></th>
//                                         <th style={{ margin: 5 }}>Ver</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {finca.centrosTrabajo.map((c,j) => {
//                                         return (
//                                         <>
//                                             <tr>
//                                             <td colSpan="8" style={{backgroundColor: '#fffbf4', padding: '10px'}}>{c.nombre}</td>
//                                             </tr>
//                                             {this.loopHvCt(c.documentos.hojaVisita?.ano === undefined ? 2022 : c.documentos.hojaVisita?.ano, c, finca.nif)}
//                                            {/*  <tr>
//                                             <td>{c.documentos.hojaVisita?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.hojaVisita?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                                             <td style={{ width: '100%' }}>Hoja de visita {this.loopHV(c.documentos.hojaVisita?.ano === undefined ? 2022 : c.documentos.hojaVisita?.ano)}</td>
//                                             <td style={{ textAlign: 'center' }}></td>
//                                             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.hojaVisita?.ruta)} /></td>
//                                             </tr> */}

//                                             {/* <tr>
//                                             <td>{c.documentos.prevencionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.prevencionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                                             <td style={{ width: '100%' }}>Plan de prevención</td>
//                                             <td style={{ textAlign: 'center' }}></td>
//                                             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.prevencionRiesgos?.ruta)} /></td>
//                                             </tr> */}
//                                             <tr>
//                                             <td>{c.documentos.evaluacionRiesgos?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.evaluacionRiesgos?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                                             <td style={{ width: '100%' }}>Identificación de riesgos y medidas preventivas</td>
//                                             <td style={{ textAlign: 'center' }}></td>
//                                             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.evaluacionRiesgos?.ruta)} /></td>
//                                             </tr>
//                                             <tr>
//                                             <td>{c.documentos.planEmergencia?.pendiente ? <img src={Minus} alt="Pendiente" className="tableIcon" /> : c.documentos.planEmergencia?.validado ? <img src={Tick} alt="Validado" className="tableIcon" /> : <img src={Cross} alt="No validado" className="tableIcon" />}</td>
//                                             <td style={{ width: '100%' }}>Plan de emergencia</td>
//                                             <td style={{ textAlign: 'center' }}></td>
//                                             <td style={{ textAlign: 'center' }}><img src={Documento} alt="Ver" className="tableIcon" onClick={() => this.abrirArchivo(c.documentos.planEmergencia?.ruta)} /></td>
//                                             </tr>
//                                         </>
//                                         )
//                                     })}
//                                 </tbody>
//                             </table>
//                         </>
//                     }

//             </div>
//             <div className="gridCard">
//                 <div className="gridHead">PROVEEDORES</div>
//                 <table style={{ margin: 15 }}>
//                     <thead>
//                     <tr style={{ fontSize: 10, fontWeight: 'normal', textAlign: 'center' }}>
//                         <th></th>
//                         {/* <th>Aviso</th> */}
//                     </tr>
//                     </thead>
//                     <tbody>
//                     {proveedores.map((e, i) => {
//                         return (
//                         <tr key={i}>
//                             <td style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}><Link to={"/proveedores/" + e.nif}>{e.razonSocial}<p style={{ opacity: 0.55, fontSize: 14 }}>{serviciosProveedores[e.servicio]}</p></Link></td>
//                             {/* <td style={{ textAlign: 'center' }}><img src={Aviso} alt="Aviso" className="tableIcon" /></td> */}
//                         </tr>
//                         )
//                     })}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     </>
//   }
// }

// export default withRouter(Comunidad);
