import React, {useState, useEffect, useContext} from 'react'
import { withRouter } from "../../providers/withRouter";
import { Link } from "react-router-dom";
import { getAAFF } from '../../services';
import { createComment, deleteComment, getComments } from '../../services/comment.service';
import { IoSave } from "react-icons/io5";
import Delete from "../../icons/delete.svg";
import Editar from "../../icons/editar.svg";
import { usuarioContext } from '../../providers/userContext';
import { editarComentario, eliminarComentario, formatoFechasEs } from '../../functions/funciones';

const ObservacionesTecnicos = (props) => {
    
    const user = useContext(usuarioContext)
    const [aaff, setAAFF] = useState({});
    const [observations, setObservations] = useState([]);
    const [observation, setObservation] = useState('');
    const [recargar, setRecargar] = useState(0);

    useEffect(() => {
        fetchData()
        setRecargar(false);
    }, [recargar]);

    const fetchData = async () => {
        const responseAaffs = await getAAFF(props.params.nif);
        setAAFF(responseAaffs);
        let obs = []
        const responseComments = await getComments()
        
        responseComments?.data.forEach( com => {
          if(com.aaff_id === responseAaffs.id){
            if(com.user.consultant.length > 0){
              com.usuario = com.user.consultant[0]
            }else if(com.user.technician.length > 0){
              com.usuario = com.user.technician[0]
            }else{
              com.usuario = com.user.admin[0]
            }
            if(com.tipo==='observation'){
              obs.push(com)
            }
          }
        })
        obs.sort(function(a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setObservations(obs)
    
      }
    
    function guardarObservaciones() {
        try {
        let infoComentario = {
            prop_id:null,
            supp_id:null,
            aaff_id:aaff.id,
            texto: observation,
            tipo: "observation",
            status:true,
        }
        createComment(infoComentario).then(res => {
            handleSubirDocsCallback(res)
            setObservation('')
        })
        } catch (error) {
        console.log(error)
        }
        
    }

    function cambiarObservaciones(evt) {
        const value = evt.target.value;
        setObservation(value);
    }

    
    const handleSubirDocsCallback = () => {
        setRecargar(!recargar);
    };

    const editarComment = async (id, comm) => {
        editarComentario(id, user.id, comm).then( (resultado) => {
        handleSubirDocsCallback(resultado)
        })
    }
    const eliminarComment = async (id) => {
        eliminarComentario(id).then( (resultado) => {
        handleSubirDocsCallback(resultado)
        })
    }
    return ( 
        <>
            <div>
              <p style={{paddingBottom: 10}}>
                <b>Observaciones del técnico:</b>
              </p>
              {observations.lenght === 0
                ? "-"
                : observations.map(comm => {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{width:'40px', margin: '3px 0'}}>
                        {comm.user_id === user.user_id ? 
                          <>
                            <img
                              src={Delete}
                              alt="ELIMINAR"
                              className="tableIcon"
                              onClick={() => eliminarComment(comm.id)}
                              style={{width: 14, margin:0}}
                            ></img> 
                            <img 
                              src={Editar}
                              alt="Editar"
                              className="tableIcon"
                              onClick={() => editarComment(comm.id, comm)}
                              style={{width: 14, margin:'0 0 0 3px'}}
                            ></img> 
                            {}
                          </>
                        : null}
                      </span>
                      <span>
                        {formatoFechasEs(comm.updatedAt)} - {comm.usuario?.nombre+" "+comm.usuario?.apellidos} - {comm.texto}
                      </span>
                    </div>
                  )
                })}{" "}
            </div>
            <div>
              <form
                noValidate
                autoComplete="off"
                style={{ display: "flex", alignItems: "end" }}
              >
                <textarea
                  style={{
                    marginRight: "10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}
                  id="observaciones"
                  name="observaciones"
                  label="observaciones"
                  value={aaff.observaciones}
                  onChange={cambiarObservaciones}
                  /* multiline */ rows={6}
                  columns={100}
                />
                <div>
                  <button type="button" className="bigBtn" onClick={() => guardarObservaciones(observations)}>
                    <IoSave style={{ fontSize:23 }} />
                  </button>
                </div>
              </form>
            </div>
        </>
     );
}
 
export default withRouter(ObservacionesTecnicos);