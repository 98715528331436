import React, { useState, useEffect, useContext } from "react";
import { getCommercials, deleteCommercial } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { usuarioContext } from "../../../providers/userContext";

const Comerciales = () => {
  const user = useContext(usuarioContext)
  const [comerciales, setComerciales] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [recargar, setRecargar] = useState(true);

  useEffect(() => {
    getCommercials().then((res) => {
      setComerciales(res.data);
      setIsloading(false);
    });
  }, [recargar]);

  const eliminarComercial = (com) => {
    let nombre = com.nombre + " " + com.apellidos;
    Swal.fire({
      title: "¿Quieres borrar este comercial?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borrar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteCommercial(com.nif)
            .then((res) => {
              if (res.message === "COMMERCIAL_DELETED") {
                toast.success("Comercial eliminado");
                setRecargar(!recargar);
              } else {
                toast.error("No se ha podido elimiar el comercial");
              }
            })
            .catch((e) => {
              toast.error("No se ha podido elimiar el comercial");
              console.log(e);
            });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error");
        console.log(e);
      });
  };

  
  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:"Comerciales"}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
            <div className="gridHead">
                COMERCIALES
            </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div>{/* <ExportarListaTecnicos tecnicos={tecnicos} /> */}</div>
          {user.rol === "ADMIN" && 
            <Link to="/comerciales/nuevo">
              <div className="contentButton">
                <img src={Add} alt="Nuevo comercial" />              
              </div>
            </Link>
          }
        </div>
        <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>Comercial</th>
                <th style={{ textAlign: "center" }}>NIF</th>
                <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                {user.rol === "ADMIN" && 
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                }
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#fc7271" }} />{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {comerciales.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.nombre + " " + e.apellidos}
                    </td>
                    <td style={{ textAlign: "center" }}>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>
                      <Link to={"/comerciales/" + e.nif + "/aaff"}>
                        <img
                          src={Lupa}
                          alt="Administradores de Fincas"
                          className="tableIcon"
                        ></img>
                      </Link>
                    </td>
                    {user.rol === "ADMIN" && 
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={Delete}
                          alt="ELIMINAR"
                          className="tableIcon"
                          onClick={() => eliminarComercial(e)}
                        ></img>
                      </td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Comerciales;
