import axios from "axios";

const urlPath = "commercial";

export const createCommercial = async (data) => {
  const datos = {
    email: data.email,
    nif: data.nif,
    nombre: data.nombre,
    apellidos: data.apellidos,
  };
  try {
    const response = await axios.post(urlPath, datos);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const getCommercials = async () => {
  try {
    const response = await axios.get(urlPath);
    //console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getCommercial = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);
    //console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const asignarComercial = async (nif, id) => {
  const datos = {
    commer_id: id,
  };
  console.log(nif, id);
  try {
    const response = await axios.put(`aaff/${nif}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const deleteCommercial = async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    //console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
