import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { Iconify } from "../../../utilities";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import moment from "moment";
import {editarAAFF, getAAFFs, getTecnico} from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Tick from "../../../icons/tick.svg";
import Warning from "../../../icons/warning.svg";
import { formatoFechas, formatoFechasEs } from "../../../functions/funciones";
import Lupa from "../../../icons/lupa.svg";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";



const AAFFTecnico = (props) => {
  const user = useContext(usuarioContext);
  const [recargar, setRecargar] = useState(0);
//   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [desde, setDesde] = useState(undefined);
  const [hasta, setHasta] = useState(undefined);
  const [tecnico, setTecnico] = useState({});
  const [msjFincas, setMsjFincas] = useState("");
  const [cargandoVisitas, setCargandoVisitas] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
    setRecargar(false);
  }, [recargar]);
  
  const fetchData = async ({ offset, limit, filtros }) => {
    

    try {
      setLoading(true);
      const responseTecnico = await getTecnico(props.params.nif)
      console.log(responseTecnico)
      const payload = {
        filtros:{...filtros, tech_id: responseTecnico.id},
        params: { page: offset, limit },
      };
      const response = await getAAFFs(payload);
      setTecnico(responseTecnico)
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cambiarFechaEncargo = (nif) => {
    console.log("cambiar fecha" + nif);
    Swal.fire({
      title: "Cambiar fecha encargo",
      html: `<input type="date" id="fecha" class="swal2-input">`,
      confirmButtonText: "Cambiar",
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector("#fecha").value;
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`);
        }
        return { fecha: fecha };
      },
    }).then((result) => {
      let data = {
        nif: nif,
        fechaEncargo: formatoFechas(result.value.fecha),
      };
      console.log(data);
      editarAAFF(data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toast.success("Datos editados con éxito");
            console.log(res);
            setRecargar(!recargar);
          } else {
            let mensajes = [];

            res.data.error.map((err) => {
              mensajes = err;
              console.log(err.message);
              toast.error("error al editar");
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error al editar los datos");
        });
    });
  };
  const AAFFRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
    },
    {
      id: "fechaEncargo",
      numeric: false,
      disablePadding: false,
      label: "FECHA ENCARGO",
      overrideFunc: (row) => (
        <Typography onClick={() => cambiarFechaEncargo(row.nifAAFF)} style={{ textAlign: 'left'}}>
            {row.fechaEncargo === null ? "-" : formatoFechasEs(row.fechaEncargo)}
        </Typography>
      ),
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "localizacion",
      numeric: false,
      disablePadding: false,
      label: "LOCALIZACIÓN",
    },
    {
      id: "numeroComunidades",
      numeric: false,
      disablePadding: false,
      label: "Nº COMUNIDADES",
      overrideFunc: (row) => (
        <Typography style={{ textAlign: 'left'}}>
            {row.length}
        </Typography>
      ),
    },
    {
      id: "comunidadesVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES VISITADAS",
      overrideFunc: (row) => (
        <ProgressBarWithColors
          value={
            (row?.filter((el) => el.visitada).length / ((row?.filter((el) => el.pendiente).length) + (row?.filter((el) => el.visitada).length) || 1)) * 100
          }
          label={
              <Typography>
                  {row?.filter((el) => el.visitada).length} / {(row?.filter((el) => el.pendiente).length) + (row?.filter((el) => el.visitada).length)}
              </Typography>
          }
        />
      ),
    },
    {
      id: "comunidadesRenovasVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES RENOVADAS VISITADAS",
      overrideFunc: (row) => (
        
        <ProgressBarWithColors
          value={
            (row?.filter((el) => el.renovadaVisitada).length / ((row?.filter((el) => el.renovada).length)+(row?.filter((el) => el.renovadaVisitada).length) || 1)) * 100
          }
          label={
              <Typography>
                  {row?.filter((el) => el.renovadaVisitada).length} / {row?.filter((el) => el.renovada).length}
              </Typography>
          }
        />
      ),
    },
    {
      id: "hojaVisita",
      numeric: false,
      disablePadding: false,
      label: "HOJAS DE VISITA",
    },
  ];  
  
  const formatData = (data) => {
    data.map((row) => (
      console.log(row)
    ))
    return data.map((row) => ({
        
      aff_name: <Link to={"/aaff/" + row.nif + "/comunidades"}>{row.razonSocial}</Link> || row.propertie?.aaff?.nombreContacto,
      fechaEncargo: {fechaEncargo: row.fechaEncargo, nifAAFF: row.nif},
      nif: row.nif || row.propertie?.nif,
      id: row.id,
      localizacion: row.municipio + "-"+ row.provincia,
      numeroComunidades:row.properties?.filter(objeto => objeto.status === true),
      comunidadesVisitadas: row.properties?.filter(objeto => objeto.status === true) ?? [],
      comunidadesRenovasVisitadas: row.properties?.filter(objeto => objeto.status === true) ?? [],
      hojaVisita: <Link to={"/tecnicos/" + props.params.nif + "/aaff/" + row.nif + "/hojas-visita"}>
                    <img src={Lupa} alt="Hojas de visita" className="tableIcon" />
                  </Link>
    }));
  };
  function filtrarFechas() {

    let d = desde
    let h = hasta
    // const fechaInicio = new Date('2022-01-01');
    // const fechaFin = new Date('2022-12-31');
    // const fechaInicioR = new Date('2023-01-01');
    // const fechaFinR = new Date('2023-12-31');
    // const rangoMilisegundos = fechaFin - fechaInicio;
    // const rangoMilisegundosR = fechaFinR - fechaInicioR;
    // let fechaVisita = undefined
    // let fechaVisitaRenovacion = undefined
    // for(let i=0;i<=10;i++){
    //   const fechaAleatoriaMilisegundos = fechaInicio.getTime() + Math.random() * rangoMilisegundosR;
    //   const fechaAleatoriaMilisegundosR = fechaInicioR.getTime() + Math.random() * rangoMilisegundos;
    //   fechaVisita = new Date(fechaAleatoriaMilisegundos).toLocaleDateString();
    //   fechaVisitaRenovacion = new Date(fechaAleatoriaMilisegundosR).toLocaleDateString();
    // }
      console.log(d, h)
    const fechas = [
      {
        fechaVisita: '2022-01-15',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-02-20',
        fechaVisitaRenovacion: '2023-02-20',
      },
      {
        fechaVisita: '2023-09-10',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-04-05',
        fechaVisitaRenovacion: '2023-04-05',
      },
      {
        fechaVisita: '2022-05-30',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-06-25',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-07-12',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2023-08-08',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-09-22',
        fechaVisitaRenovacion: '2023-09-08',
      },
      {
        fechaVisita: '2022-10-17',
        fechaVisitaRenovacion: '1970-01-01',
      },
    ];

    let numFincasVisitadas = 0
    let numFincasVisitadasRenovadas = 0

    setCargandoVisitas(true)
    let msjFincas = ''
    if(h<d || d === "Invalid Date" || h === "Invalid Date"){
      msjFincas="Las fechas no son correctas"
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    }else{
      let data = {
        tech_id: tecnico.id,
      };
      getAAFFs(data).then((res) => {
        console.log(res)
      });
      fechas.forEach(f=>{
        if(d <= f.fechaVisita && h >= f.fechaVisita || d <= f.fechaVisitaRenovacion && h >= f.fechaVisitaRenovacion){
          if(f.fechaVisitaRenovacion > f.fechaVisita){
            numFincasVisitadasRenovadas++
          }
          else{
            numFincasVisitadas++
          }
        }
      })
      if(numFincasVisitadas === 0 && numFincasVisitadasRenovadas === 0){
        msjFincas = "No se han realizado visitas en este periodo de tiempo"
      }else{
        msjFincas = "Se han realizado "+ numFincasVisitadas +" visitas nuevas y "+numFincasVisitadasRenovadas+" visitas de renovación en este periodo de tiempo"
      }
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    }
  }
  
  return (
    <>
      <BreadCrumb rutas={[{path:'/tecnicos', label:`Tecnicos`}, {path:'/tecnicos', label:`${tecnico.nombre + " " + tecnico.apellidos}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent tableContainer">
        
        <div className="tableWrapper">
          <div className="buscador">
            <input
              type="date"
              placeholder="Desde"
              value={desde}
              onChange={(event) => setDesde(event.currentTarget.value)}
            />
            <input
              type="date"
              placeholder="Hasta"
              value={hasta}
              onChange={(event) => setHasta(event.currentTarget.value)}
            />
            <button onClick={() => filtrarFechas()}><BsSearch /></button>
            <p>
              {cargandoVisitas ? (
                <CircularProgress
                  style={{
                    color: "#fc7271",
                    height: "20px",
                    width: "20px",
                    padding: "0",
                  }}
                />
              ) : (
                msjFincas
              )}
            </p>
          </div>
          <Box className="pageContent">
              <Box sx={{ mb: 10 }}>
              <DataTable
                  data={data.rows}
                  isLoading={loading}
                  headers={AAFFRows}
                  fetcher={fetchData}
                  currentPage={data.current_page}
                  meta={data.meta}
                  totalItems={data.total}
                  totalPages={data.totalPages}
                  nextPage={data.next_page}
                  filtros={busqueda}
                  mensaje={"No hay administradores de fincas"}
              />
              </Box>
          </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(AAFFTecnico);
