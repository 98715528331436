import React, { useState, useEffect, useContext } from "react";

import { getTecnicos, deleteTecnico } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ExportarListaTecnicos from "./ExportarListaTecnicos";
import Excel from "../../../icons/excel.svg";
import { IoMdPersonAdd } from "react-icons/io";
import { usuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

const Tecnicos = () => {
  const user = useContext(usuarioContext)
  const [tecnicos, setTecnicos] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [recargar, setRecargar] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    getTecnicos().then((res) => {
      console.log(res)
      setTecnicos(res);
      setIsloading(false);
    });
  }, [recargar]);

  const eliminarTecnico = (com) => {
    let nombre = com.nombre + " " + com.apellidos;
    Swal.fire({
      title: "¿Quieres borrar este tecnico?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borrar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          console.log("borrar comercial " + nombre);
          deleteTecnico(com.nif)
            .then((res) => {
              console.log(res);
              if (res.message === "TECHNICIAN_DELETED") {
                toast.success("técnico eliminado");
                setRecargar(!recargar);
              } else {
                toast.error("No se ha podido elimiar el técnico");
              }
            })
            .catch((e) => {
              toast.error("No se ha podido elimiar el técnico");
              console.log(e);
            });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error");
        console.log(e);
      });
  };

  return (
    <>
      <BreadCrumb rutas={[{path:`/tecnicos`, label:`Técnicos`}]} />
      <div className="listPage">
        <div className="gridCard">
            <div className="gridHead">
                TÉCNICOS
            </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div>
            {show ? (
              <ExportarListaTecnicos tecnicos={tecnicos} />
            ) : (
              <button className="bigBtn" onClick={() => setShow(true)}>
                <img src={Excel} alt="Generar listado" width="30px" /> Generar
                listado
              </button>
            )}
          </div>
          {user.rol === "ADMIN" && 
            <Link to="/tecnicos/nuevo">
              <div className="contentButton">
                <img src={Add} alt="Nuevo Técnico" />              
              </div>
            </Link>
          }
        </div>
        <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>Técnico</th>
                <th style={{ textAlign: "center" }}>NIF</th>
                <th style={{ textAlign: "center" }}>Teléfono</th>
                <th style={{ textAlign: "center" }}>Email</th>
                <th style={{ textAlign: "center" }}>Zona</th>
                <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                <th style={{ textAlign: "center" }}>Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#fc7271" }} />{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {tecnicos.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.nombre + " " + e.apellidos}
                    </td>
                    <td style={{ textAlign: "center" }}>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>{e.telefono}</td>
                    <td style={{ textAlign: "center" }}>{e.user.email}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.zona !== null ? e.zona : "-"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Link to={"/tecnicos/" + e.nif + "/aaff"}>
                        <img
                          src={Lupa}
                          alt="Administradores de Fincas"
                          className="tableIcon"
                        ></img>
                      </Link>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <img
                        src={Delete}
                        alt="ELIMINAR"
                        className="tableIcon"
                        onClick={() => eliminarTecnico(e)}
                      ></img>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Tecnicos;
