export const DOC_TYPES = {
  CERT_LOPD: 1,
  SEG_RESP_CIVIL: 2,
  JUST_PAG_SPA: 3,
  PLAN_PREV: 4,
  IDENT_RIESGOS: 5,
  CERT_SS: 6,
  VIG_SALUD: 7,
  TIPO_EDT_ELIM: 8,
  DOC_FINCA: 9,
  DOC_CENTR_TRAB: 10,
  DOC_COMUNITY: 11,
};


export const DOC_TYPES_NAMES = {
  [DOC_TYPES.CERT_LOPD]: "Certificado LOPD",
  [DOC_TYPES.SEG_RESP_CIVIL]: "Seguridad Social",
  [DOC_TYPES.JUST_PAG_SPA]: "Justificación de Pago SPA",
  [DOC_TYPES.PLAN_PREV]: "Plan de Prevision",
  [DOC_TYPES.IDENT_RIESGOS]: "Identificación de Riesgos",
  [DOC_TYPES.CERT_SS]: "Certificado corriente de pago Seguridad Social",
  [DOC_TYPES.VIG_SALUD]: "Vigencia de Salud",
  [DOC_TYPES.TIPO_EDT_ELIM]: "Tipo de Edición/Eliminación",
  [DOC_TYPES.DOC_FINCA]: "Documento Finca",
  [DOC_TYPES.DOC_CENTR_TRAB]: "Documento Centro de Trabajo",
  [DOC_TYPES.DOC_COMUNITY]: "Documento Comunidad",
}
