import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { desvincularProveedor, formatoFechasEs } from "../../../functions/funciones";
import Add from "../../../icons/add.svg";
import {
  LinearProgress,
  Box,
  Typography,
  Button,
  linearProgressClasses,
  styled,
} from "@mui/material";
import { getAAFF, getAaffListSuplier, getProveedores } from "../../../services";
import { daysDiff } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { BsSearch } from "react-icons/bs";
import { usuarioContext } from "../../../providers/userContext";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import Desvincular from "../../../icons/desvincular.svg";

const proveedoresRows = [
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    overrideFunc: (row) => (
      <ProgressBarWithColors
        value={row}
        label={
          <Typography>
            {row}
          </Typography>
        }
      />
    ),
  },
  {
    id: "desvincular",
    numeric: false,
    disablePadding: false,
    label: "DESVINCULAR",
  },
]
const ProveedoresLista = (props) => {
  const user = useContext(usuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const [aaff, setAAFF] = useState([]);
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  useEffect(() => {
    console.log(props.params.nif)
    fetchData();
    //setPorcentaje(65)
    //setRecargar(false)
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(offset, limit, filtros);
    setLimite(limit)
    const aaff = await getAAFF(props.params.nif)
    setAAFF(aaff)
    console.log(aaff.id)
    const payload = {
      filtros,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      // const response = await getAaffListSuplier(payload);
      const response = await getProveedores(payload);
      console.log(response)
      // const response = await getProveedores(payload);
      // const response1 = await getAAFF(user.nif);
      // console.log(response1)
      let datos = []
      const { meta, data } = response;
      console.log(response)
      // const filteredData = data.filter((item) => item.properties.some((prop) => prop.aaff.id === 5))
      // const filteredMeta = {
      //   current_page:1,
      //   first_page:1,
      //   has_more_pages: true,
      //   has_pages: true,
      //   is_empty: false,
      //   last_page:4,
      //   next_page:2,
      //   per_page:5,
      //   previous_page:null,
      //   total_pages:20
      // }
      // console.log(filteredData, filteredMeta)

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      proveedor_name:
        (
          <Link to={"/proveedores/" +row.nif}>
            {row.razonSocial}
          </Link>
        ),
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs,
      desvincular: <img
      src={Desvincular}
      alt="Desvincular"
      className="tableIcon"
      onClick={() => desvincularProveedor(row.idFinca, row.id)}
    ></img>
    }));
  };
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 

  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label: 'Administrador'},{path:`/aaff/${aaff.nif}/comunidades`, label: `${aaff.razonSocial}`}, {path:`/aaff/${aaff.nif}/proveedores`, label: 'proveedores'}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORESa</div>
          <div className="tableContainer">
            <div style={{ display: "flex" }}>
              <FiltrosProveedores onFilter={filtrarDatos} /> 
              {/* <div className="exportar">
                <ExportarProveedoresLista busqueda={Resbusqueda} /> 
              </div> */}
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
