import React, { useState, useEffect } from "react";
import {
  Grid,
  Select,
  FormControl,
  TextField,
  Collapse,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
//import Change from "../icons/change.svg"
import { getAAFFs, changeAAFF } from "../services";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export function DialogoCambioAAFF(props) {
  const [open, setOpen] = useState(false);
  const [aaffs, setAAFFS] = useState([]);
  const [aaffSeleccionado, setAAFFSeleccionado] = useState({});
  const [aaffBuscado, setAAFFBuscado] = useState(null);
  const [nuevaLista, setNuevaLista] = useState([]);

  const nifAAFF = props.aaff;
  let finca = props.finca;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAAFFs({filtros:{}, params:{}}).then((res) => {
      console.log(res);
      setAAFFS(res.data);
    });
    if (finca.nif !== undefined) handleClickOpen();
  }, [finca]);

  const seleccionarAAFF = (evt) => {
    console.log(evt.target.value)
    setAAFFSeleccionado(evt.target.value);
  };
  const mostrarAAFF = (evt) => {
    console.log(evt.target.value)
    setAAFFBuscado(evt.target.value);
    let nuevaLista = [];
    aaffs.map((j, i) => {
      if (j.nif.toString().includes(evt.target.value)) {
        //nuevaLista.push(j)
        nuevaLista.push({ nif: j.nif, razonSocial: j.razonSocial });
      }
    });
    if (nuevaLista.length === 1) {
      nuevaLista.forEach((l) => {
        setAAFFSeleccionado(l.nif);
      });
    }
    setNuevaLista([]);
    setNuevaLista(nuevaLista);
  };
  const cambiarAAFF = () => {
    let nuevoaaff = null;

    if (aaffSeleccionado === null) {
      nuevoaaff = nifAAFF;
    } else {
      nuevoaaff = aaffSeleccionado;
    }
    changeAAFF(finca.nif, nuevoaaff)
      .then((res) => {
        console.log(res.status)
        if (res.status >= 200 && res.status < 300) {
          toast.success("AAFF cambiado con éxito");
        }
        handleClose();
        props.hijoAPadre(1);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        props.hijoAPadre(0);
        toast.error("error al cambiar de aaff");
      });
  };
  return (
    <div>
      {/* <img src={Change} alt="CAMBIAR AAFF" className="tableIcon" onClick={handleClickOpen}></img> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Quieres cambiar el aaff de <b>{finca.razonSocial}</b>?
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <form noValidate autoComplete="off">
          <div style={{ margin: "8px 30px" }}>
            <TextField
              id="buscarAAFF"
              name="buscarAAFF"
              label="Buscar AAFF por nif"
              onChange={mostrarAAFF}
              autoFocus
              style={{ margin: "0 0 40px 0" }}
              fullWidth
              variant="standard"
            />
            <Select  isSearchable
              defaultValue=""
              style={{ width: "100%" }}
              fullWidth
              variant="standard"
              native
              name="aaff"
              id="aaff"
              onChange={seleccionarAAFF}
            >
              {aaffBuscado === null
                ? aaffs.map((j, i) => {
                    return (
                      <option key={i} value={j.nif} data-name={j.razonSocial}>
                        {j.razonSocial}
                      </option>
                    );
                  })
                : nuevaLista.map((j, i) => {
                    return (
                      <option key={i} value={j.nif} data-name={j.razonSocial}>
                        {j.razonSocial}
                      </option>
                    );
                  })}
            </Select>
          </div>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="inherit"
              style={{ color: "#FF5252" }}
            >
              Cancelar
            </Button>
            <Button
              onClick={cambiarAAFF}
              color="inherit"
              style={{ color: "#7bc977" }}
            >
              <b>Cambiar</b>
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export function DialogoSubirDocumentacion(props) {
  console.log(props);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  return (
    <div>
      {/* <img src={Change} alt="CAMBIAR AAFF" className="tableIcon" onClick={handleClickOpen}></img> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Qué quieres hacer con el documento?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Subir un nuevo documento implica sobreescribir el anterior
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#fc7271" }}
          >
            Borrar documento
          </Button>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#fc7271" }}
          >
            Subir nuevo
          </Button>
          <Button
            onClick={() => {}}
            color="inherit"
            style={{ color: "#fc7271" }}
            autoFocus
          >
            <b>VER</b>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
