import axios from "axios";

const urlPath = "propertie";

export const nuevoCentroTrabajo = async (nif, data) => {
  console.log(nif, data);

  try {
    const response = await axios.post(`${urlPath}/${nif}/workcenter`, data);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteCentroTrabajo = async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`${urlPath}/workcenter/${id}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const listCentroTrabajo = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/workcenter/list`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
