import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ComunidadesIcon from "../icons/comunidades.svg";
import ProveedorIcon from "../icons/proveedor.svg";

import Inicio from "./pages/aaff/Inicio";
import Comunidades from "./pages/aaff/Comunidades";
import Comunidad from "./pages/aaff/Comunidad";
import Proveedores from "./pages/aaff/Proveedores";
import Proveedor from "./pages/aaff/Proveedor";
import AAFF from "./pages/aaff/AAFF";
import { TbBuildingCommunity } from "react-icons/tb";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="comunidades" element={<Comunidades />} />
      <Route path="comunidades/:nif" element={<Comunidad />} />
      <Route path="proveedores" element={<Proveedores />} />
      <Route path="proveedores/:nif" element={<Proveedor />} />
      <Route path="aaff" element={<AAFF />} />
    </Routes>
  );
}

export function MenuLinksClientes(props) {
  return (
    <>
        <li>
          <NavLink activeclassname="active" to="/aaff" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "",  color: props.showSidebar ? "#33383b" : "#fff"}}>
            <div className="menuBtn">
              <img src={Edificios} alt="Panel principal" />Panel principal
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/comunidades" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <TbBuildingCommunity /> Comunidades
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/proveedores" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={ProveedorIcon} alt="Proveedores" />Proveedores
            </div>
          </NavLink>
        </li>
    </>
  )
}
