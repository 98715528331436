import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ComunidadesIcon from "../icons/comunidades.svg";
import ProveedorIcon from "../icons/proveedor.svg";

import Inicio from "./pages/proveedor/Inicio";
import AAFF from "./pages/proveedor/AAFF";
// import AAFFComunidades from "./pages/admin/AAFFComunidades";
import Comunidades from "./pages/proveedor/Comunidades";
import Comunidad from "./pages/proveedor/Comunidad";
import Proveedor from "./pages/proveedor/Proveedor";
import DetallesAAFF from "./pages/proveedor/DetallesAAFF";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="aaff/:nif/comunidades" element={<DetallesAAFF />} />
      {/* <Route path="/aaff/:nif/comunidades" element={<AAFFComunidades />} /> */}
      <Route path="/comunidades" element={<Comunidades />} />
      <Route path="/comunidades/:nif" element={<Comunidad />} />
      <Route path="/proveedor" element={<Proveedor />} />
    </Routes>
  );
}

export function MenuLinksClientes(props) {
  return (
    <>
      <li>
        <NavLink
          activeclassname="active"
          to="/proveedor"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
            color: props.showSidebar ? "#33383b" : "#fff",
          }}
        >
          <div className="menuBtn">
            <img src={ProveedorIcon} alt="Panel Principal" />
            Panel Principal
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/aaff"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={Edificios} alt="Proveedores" />
            Administradores de Fincas
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/comunidades"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={ComunidadesIcon} alt="Comunidades" />
            Comunidades
          </div>
        </NavLink>
      </li>
    </>
  );
}
