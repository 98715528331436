import { Box, MenuItem, Select, TextField, Typography} from "@mui/material";
import React, { useState, useContext } from "react";
import { ventas } from "../../../functions/ventasPrueba";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import { useVentas } from "../../../hooks";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataTable, LaeButton, NoVisitado } from "../../../components";
import { Iconify, convertirAPdf } from "../../../utilities";
import moment from "moment";
import Add from "../../../icons/add.svg";
import { generarPDF, getSales, getFinca } from "../../../services";
import { toast } from "react-toastify";
import FiltrosVentas from "../../../components/filtros/filtrosVentas";
import { formatoFechasEs } from "../../../functions/funciones";
import { usuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { FaPencilAlt, FaPlus  } from "react-icons/fa";

export default function ComunidadDocs(props) {
  const user = useContext(usuarioContext);
  const comunidadesRows = [
    {
      id: "color",
      numeric: false,
      disablePadding: true,
      label: "",
      overrideFunc: (row) => (
        <Typography
          className="servicios"
          style={{ padding: 0, background: row }}
        ></Typography>
      ),
    },
    {
      id: "servicio",
      numeric: false,
      disablePadding: false,
      label: "SERVICIO",
    },
    {
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "fechaVenta",
      numeric: false,
      disablePadding: false,
      label: "FECHA VENTA",
    },
    {
      id: "documentos",
      numeric: false,
      disablePadding: false,
      label: "DOCUMENTOS",
      colSpan: 4,
      overrideFunc: (row) => (
        <Typography>
          {(row.serviceprops.id === 1 || row.serviceprops.id === 3) && (
            <>
              <td style={{ width: "25%", padding: '0px 10px' }}>
                {renderDocs('Hoja visita', "hoja-visita", convertirAPdf, row, "cae")}
              </td>
              <td style={{ width: "25%", padding: '0px 10px' }}>
                {renderDocs('Plan prevención', "identificacion-riesgos", convertirAPdf, row, "cae")}
              </td>
              <td style={{ width: "25%", padding: '0px 10px' }}>
                {renderDocs('Plan emergencia', "plan-emergencia", convertirAPdf, row, "cae")}
              </td>
            </>
          )}
          {(row.serviceprops.id === 2 || row.serviceprops.id === 4) && (
            <>
            {/* <td style={{ width: "25%", paddingRight: '23px' }}>
              {renderDocs('Lopd', convertirAPdf, row, "lopd")}
            </td> */}
            <td style={{ width: "25%", padding: '0px 10px', /* paddingRight: '15px' */ }}>
              {renderDocs('Auditoría', 'auditoria', convertirAPdf, row, "lopd")}
            </td>
            <td style={{ width: "25%", padding: '0px 10px' }}>
              {renderDocs('Video vigilancia', "video-vigilancia", convertirAPdf, row, "lopd")}
            </td>
            <td style={{ width: "25%", padding: '0px 10px' }}>
              {renderDocs('Documentación', 'documentacion', convertirAPdf, row, "lopd")}
            </td>
            </>
          )}
        </Typography>
      ),
    },
    {
      id: "noVisita",
      numeric: false,
      disablePadding: false,
      label: "No visitada",
      overrideFunc: (row) => (
        <Typography>
            <NoVisitado finca={row} nif={row.properties.nif} tipo={"finca"} />
        </Typography>
      ),
    },
    {
      id: "fechaRealizada",
      numeric: false,
      disablePadding: false,
      label: "FECHA REALIZADA",
      overrideFunc: (row) => (
        <Typography>
          {!row.detailsCae[0]?.unRealized && row.detailsCae[0]?.saleDate !== null && row.detailsCae[0]?.saleDate !== undefined ? 
            formatoFechasEs(row.detailsCae[0]?.saleDate)
          : null}
        </Typography>
      ),
    },

    
  ];

  const realizarVisita = {
    id: "hacerVisita",
    numeric: false,
    disablePadding: false,
    label: "Realizar Visita",
    overrideFunc: (row) => (
      <Typography>
        {row.detailsCae[0]?.visitada ? 
          row.workcenters === null ?
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/edit/${row.id}`} target={"_blank"} >
              <FaPencilAlt/>
            </Link>
            :
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/${row.workcenters.id}/edit/${row.id}`} target={"_blank"} >
              <FaPencilAlt/>
            </Link>
        : 
          row.workcenters === null ?
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/create/${row.id}`} target={"_blank"} >
              <FaPlus/>
            </Link>
          :
            <Link className="btn" to={`/hojaVisita/${row.properties.nif}/${row.workcenters.id}/create/${row.id}`} target={"_blank"} >
              <FaPlus/>
            </Link>
        }
        
      </Typography>
    ),
  }

  if(user.rol !== 'COMM'){
    comunidadesRows.push(realizarVisita)
  }
  
  const [listaVentas, setListaVentas] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
  }, [recargar]);
  console.log(props);
  const renderDocs = (nombre, enlace, convertirAPdf, row, tipo) => {
    let documento;
    if(tipo === "lopd"){
      documento = row.detailsLopd?.find(obj => obj.sale_id === row.id);
    }
    if(tipo === 'cae'){
      documento = row.detailsCae?.find(obj => obj.sale_id === row.id);
    }
    if (!documento) {
      return (
        <div style={{ diplay: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
        <small
          className="docsStatus" 
          style={{ background: '#FF5252' }}
        >
          {nombre}
        </small>
        </div>
      );
    }
  
    const { pendiente, visitada } = documento;
  
    return (
      <div style={{ diplay: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
        <small
          // onClick={() =>
          //   pendiente || visitada
          //     ? convertirAPdf({
          //         nombre: nombre.toLowerCase().replace(/ /g, '-'),
          //         nif: row.properties.nif,
          //         workcenter_id: row.workcenters?.id !== undefined ? row.workcenters.id : null,
          //         sale_id: row.id
          //       })
          //     : null
          // }
          className="docsStatus" 
          style={
            pendiente ?
              { background: '#fcba03' }
            : visitada ?
              { background: '#7bc977' }
            : 
              { background: '#FF5252' }
          }
        >
          {row.workcenters === null ? 
            <Link to={`/doc-render/${row.properties?.nif}/${row.id}/${enlace}`} target={"_blank"} >{nombre}</Link>
          :
            <Link to={`/doc-render/${row.properties?.nif}/${row.workcenters.id}/${row.id}/${enlace}`} target={"_blank"} >{nombre}</Link>
          }
          {/* {nombre} */}
        </small>
      </div>
    );
  };

  const convertirAPdf = async ({ nombre, nif, workcenter_id, sale_id }) => {
    try {
      console.log(nombre, nif, workcenter_id, sale_id);
      const toastInfo = toast.info("Abriendo archivo");
    //   const response = await generarPDF(`http://localhost:3001/doc-render/${nif}/${nombre}`);
        const datos = {
          url: workcenter_id !== null ? `http://localhost:3001/doc-render/${nif}/${workcenter_id}/${sale_id}/${nombre}` : `http://localhost:3001/doc-render/${nif}/${sale_id}/${nombre}`,    
          nombre: nombre,
          nif: nif
        }
        await generarPDF(datos).then( res => {
        console.log(res)
        const url = URL.createObjectURL(res.data);
        console.log(url)
        // Abre el PDF en una nueva ventana o pestaña
        const newWindow = window.open('', '_blank');
        const pdfWindow = newWindow;
        pdfWindow.document.write('Cargando PDF...');

        // Promesa para cargar la URL
        const loadURL = new Promise((resolve, reject) => {
          const iframe = document.createElement('iframe');
          iframe.src = url;
          iframe.style.display = 'none';

          iframe.onload = () => {
            resolve();
          };
          
          iframe.onerror = (error) => {
            reject(error);
          };

          document.body.appendChild(iframe);
        });

        loadURL
          .then(() => {
            // Espera a que el evento "load" se dispare en el iframe antes de abrir la ventana emergente
            pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco
            pdfWindow.document.write('Cargando PDF...');
            setTimeout(() => {
              pdfWindow.location.href = 'about:blank'; // Limpiar la página en blanco nuevamente
              pdfWindow.location.href = url; // Abrir la URL del PDF
            }, 1000); // Espera 1 segundo (ajusta según sea necesario)
          })
          .catch((error) => {
            console.error('Error al cargar la URL:', error);
            pdfWindow.document.write('Error al cargar la URL');
          });
      })
    //   const blob = new Blob([response.data], { type: 'application/pdf' });
      
    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const fetchData = async ({ offset, limit, filtros }) => {
    console.log(offset, limit, filtros);
    setLimite(limit);
    let usuario = {}
    if(user.rol === "COMM"){
      usuario = {commer_id: user.id}
    } 
    const payload = {
      // aaff_id: 7,
      filtros:usuario,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      if (props.nifFinca === undefined) {
        const response = await getSales(payload)
        const { meta, data } = response;
        console.log(data)
        setListaVentas(data);
        setData({
          meta,
          rows: formatData(data),
        });
      }else{
        // const data = ventas.filter(v => v.comunidad.nif === props.nifFinca)
        const finca = await getFinca(props.nifFinca)
        // console.log(finca.id)
        const payload = {
          filtros:{prop_id:String(finca.id)},
          params: { page: offset, limit },
        };

        const response = await getSales(payload)
        const { meta, data } = response;
        setListaVentas(data)
        setData({
          meta,
          rows: formatData(data),
        });
      }
      //   const response = await getAAFFs(payload);
      //   const { meta, data } = response;
      //   setData({
      //     meta,
      //     rows: formatData(listaVentas),
      //   });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      color: row.color,
      servicio: row.serviceprops.nombre,
      comunidad:  (
        // <Link to={"/aaff/" + row.aaff.id + "/comunidades/"+ row.properties.nif} >{row.properties.razonSocial}</Link>
        <Link to={"/aaff/" + row.aaff.nif + "/comunidades/"+ row.properties.nif} >{row.workcenters === null ? row.properties.razonSocial : row.workcenters.razonSocial}</Link>
      ),
      codigoPostal: row.properties.codigoPostal,
      fechaVenta: formatoFechasEs(row.createdAt),
      documentos: row,
      noVisita: row,
      fechaRealizada: row,
      hacerVisita: user.rol !== 'COMM' ? row: null
    }));
  };
  // function handleChange(evt) {
  //   const value = evt.target.value;
  //   setBusqueda({
  //     ...busqueda,
  //     [evt.target.name]: value,
  //   });
  // }

  // const getBusqueda = () => {
  //   console.log(busqueda);
  //   for (let key in busqueda) {
  //     if (
  //       busqueda[key] === "" ||
  //       busqueda[key] === null ||
  //       busqueda[key] === undefined
  //     ) {
  //       delete busqueda[key];
  //     }
  //   }
  //   fetchData({ offset: 1, limit: limite, filtros: busqueda });
  // };

  const filtrarDatos = (filtros) => {
    console.log(filtros)
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros });
  } 

  return (
    <>
      {props.nifFinca === undefined && (
        <BreadCrumb rutas={[{path:`/ventas`, label:`Ventas`}]} style={{ margin: '0 10px'}} />
      )}
      <div className="pageContent tableContainer">

        {props.nifFinca === undefined && (
          <>
          {user.rol !== "ADMIN" && user.rol !== "CONSUL" ? null :
            <div className="contentBar" style={{padding: '0 10px'}}>
              <div className="contentBtns"></div>
              <div className="contentButton">
                <Link to="/ventas/create" target={"_blank"}>
                  <img src={Add} alt="Más" />
                </Link>
              </div>
            </div>
          }

            <FiltrosVentas onFilter={filtrarDatos} />
          </>
        )}

        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay ventas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      <style jsx="true">{`
        .tableContainer table tbody th.docs {
          text-align: center;
        }
        .tableContainer table tbody td {
          padding: 0;
        }
      `}</style>
    </>
  );
}
