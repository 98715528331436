import React, { useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createCons } from "../../../services/";

const NuevoConsultor = () => {
  const [consultor, setConsultor] = useState({});

  function handleSubmit(data) {
    const toastInfo = toast.info("Guardando consultor");
    createCons(data)
      .then((res) => {
        toast.update(toastInfo, {
          render: "consultor creado con éxito",
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        });
        window.location.replace("/consultores");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al crear el consultor");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setConsultor({
      ...consultor,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Consultores / <b>Nuevo</b>
      </div>
      <div className="pageContent">
        <div className="titleContent">Crear nuevo Consultor</div>
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={consultor.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={consultor.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={consultor.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={consultor.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
          </Grid>
          <input
            type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(consultor)}
          ></input>
        </form>
      </div>
    </>
  );
};

export default NuevoConsultor;
