import React from "react"
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import VentasIcon from "../icons/ventas.svg";
import ProveedorIcon from "../icons/proveedor.svg";
import PlantillasIcon from "../icons/plantillas.svg";
import TecnicoIcon from "../icons/tecnico.svg";
import ConsultorIcon from "../icons/consultor.svg";
import ComercialIcon from "../icons/comercial.svg";
import AlertaIcon from "../icons/alerta.svg";
import CheckControl from "../icons/controlComunidad.svg";

import Inicio from "./pages/admin/Inicio";
import AAFF from "./pages/admin/AAFF";
import NuevoAAFF from "./pages/admin/NuevoAAFF";
import Comunidades from "./pages/admin/Comunidades";
import Comunidad from "./pages/admin/Comunidad";
import Proveedores from "./pages/admin/Proveedores";
import ProveedoresFincas from "./pages/admin/ProveedoresFincas";
/*import ProveedorFinca from "./pages/admin/ProveedorFinca";*/
import NuevoConsultor from "./pages/admin/NuevoConsultor";
import Consultores from "./pages/admin/Consultores";
import AAFFConsultor from "./pages/admin/AAFFConsultor";
import ProveedoresConsultor from "./pages/admin/ProveedoresConsultor";
import NuevoTecnico from "./pages/admin/NuevoTecnico";
import Tecnicos from "./pages/admin/Tecnicos";
import AAFFTecnico from "./pages/admin/AAFFTecnico";
import HojasVisita from "./pages/admin/HojasVisita";
import AsignarConsultor from "./pages/admin/AsignarConsultor";
import Proveedor from "./pages/admin/Proveedor";
import AsignarTecnico from "./pages/admin/AsignarTecnico";
import ProveedoresLista from "./pages/admin/ProveedoresLista";
import ProveedorDatos from "./pages/admin/ProveedorDatos";
import Comerciales from "./pages/admin/Comerciales";
import NuevoComercial from "./pages/admin/NuevoComercial";
import AsignarComercial from "./pages/admin/AsignarComercial";
import AAFFComercial from "./pages/admin/AAFFComercial";
import ProveedoresComercial from "./pages/admin/ProveedoresComercial";
import AlertasProveedores from "./pages/admin/alertasProveedores"; 
import NuevoCentroTrabajo from "./pages/admin/NuevoCentroTrabajo";
import AAFFInformes from "./pages/admin/AAFFInformes";
import Plantillas from "./pages/admin/Plantillas";
import Ventas from "./pages/admin/Ventas";
import CrearVenta from "./pages/general/CrearVenta";
import NuevoProveedor from "./pages/admin/NuevoProveedor";
import GestionComunidades from "./pages/admin/GestionComunidades";
import NuevoDelegacion from "./pages/admin/NuevoDelegacion";
import DelegacionDatos from "./pages/admin/DelegacionDatos";
import RenovarComunidades from "./pages/admin/RenovarComunidades";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff-informes" element={<AAFFInformes />} />
      <Route path="/aaff/nuevo" element={<NuevoAAFF />} />
      <Route path="/aaff/:nif/comunidades" element={<Comunidades />} />
      <Route path="/aaff/:nif/comunidades/:nifFinca" element={<Comunidad />} />
      <Route path="/aaff/:nif/proveedores" element={<Proveedores />} />
      <Route path="/aaff/:nifAAFF/proveedores/:nifProveedor" element={<Proveedor />} />
      <Route path="/aaff/:nifAAFF/comunidades/:nifFinca/proveedores" element={<ProveedoresFincas />} />
      {/*<Route path="/aaff/:nifAAFF/comunidades/:nifFinca/proveedores/:nifProveedor" element={<ProveedorFinca />} />*/}
      <Route path="/aaff/:nif/consultor" element={<AsignarConsultor />} />
      <Route path="/aaff/:nif/tecnico" element={<AsignarTecnico />} />
      <Route path="/aaff/:nif/comercial" element={<AsignarComercial />} />
      <Route path="/consultores" element={<Consultores />} />
      <Route path="/consultores/nuevo" element={<NuevoConsultor />} />
      <Route path="/consultores/:nif/aaff" element={<AAFFConsultor />} />
      <Route path="/consultores/:nifConsultor/aaff/:nifAAFF/proveedores" element={<ProveedoresConsultor />} />
      <Route path="/tecnicos" element={<Tecnicos />} />
      <Route path="/tecnicos/nuevo" element={<NuevoTecnico />} />
      <Route path="/tecnicos/:nif/aaff" element={<AAFFTecnico />} />
      <Route path="/tecnicos/:nifTecnico/aaff/:nifAAFF/hojas-visita" element={<HojasVisita />} />
      <Route path="/proveedores/nuevo" element={<NuevoProveedor />} />
      <Route path="/proveedores" element={<ProveedoresLista />} />
      <Route path="/proveedores/:nifProveedor" element={<ProveedorDatos />} />
      <Route path="/comerciales" element={<Comerciales />} />
      <Route path="/comerciales/nuevo" element={<NuevoComercial />} />
      <Route path="/comerciales/:nifComercial/aaff" element={<AAFFComercial />} />
      <Route path="/comerciales/:nifComercial/aaff/:nifAAFF/proveedores" element={<ProveedoresComercial />} />
      <Route path="/alertas" element={<AlertasProveedores />} />
      <Route path="/comunidades/:nifFinca/nuevoCentro" element={<NuevoCentroTrabajo />} />
      <Route path="/comunidades" element={<GestionComunidades />} />
      <Route path="/plantillas" element={<Plantillas />} />
      <Route path="/ventas" element={<Ventas />} />
      <Route path="/ventas/create" element={<CrearVenta />} />
      <Route path="/ventas/edit/:ventaId" element={<CrearVenta />} />
      <Route path="/delegacion/nueva/:id" element={<NuevoDelegacion />} />
      <Route path="/delegacion/:nifDelegacion/:id" element={<DelegacionDatos />} />
      <Route path="/aaff/:nif/renovar" element={<RenovarComunidades />} />
    </Routes>
  )
}

export function MenuLinksClientes(props) {
  return (
    <>
        <li>
          <NavLink activeclassname="active" to="/aaff" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "",  color: props.showSidebar ? "#33383b" : "#fff"}}>
            <div className="menuBtn">
              <img src={Edificios} alt="Administradores de Fincas" />Administradores de Fincas
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/proveedores" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={ProveedorIcon} alt="Proveedores" />Proveedores
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/ventas" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={VentasIcon} alt="Ventas" />Ventas
            </div>
          </NavLink>
        </li>
    </>
  )
}
export function MenuLinksPersonal(props) {
  return (
    <>
        <li>
          <NavLink activeclassname="active" to="/consultores" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={ConsultorIcon} alt="Consultores" />Consultores
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/comerciales" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={ComercialIcon} alt="Comerciales" />Comerciales
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink activeclassname="active" to="/tecnicos" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn">
              <img src={TecnicoIcon} alt="Técnicos" />Técnicos
            </div>
          </NavLink>
        </li>
    </>
  )
}
export function MenuLinksListado(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/aaff-informes" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn">
            <img src={CheckControl} alt="Generar informes" />Generar informes
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink activeclassname="active" to="/plantillas" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn">
            <img src={PlantillasIcon} alt="Generar informes" />Gestión plantillas
          </div>
        </NavLink>
      </li>
    </>
  )
}
export function MenuLinks(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/alertas" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn">
            <img src={AlertaIcon} alt="Alertas" />Alertas
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink activeclassname="active" to="/comunidades" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn">
            <img src={CheckControl} alt="Control Comunidades" />Control Comunidades
          </div>
        </NavLink>
      </li>
    </>
  )
}