import React, {useState, useEffect, useContext} from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getServiciosFincas } from '../../services';
import { usuarioContext } from '../../providers/userContext';

const FiltrosVentas = ({onFilter}) => {

    const { rol } = useContext(usuarioContext)
    const [busqueda, setBusqueda] = useState({})
    const [servicios, setServicios] = useState([])

    useEffect(() => {
      fetchData() 
    },[])

    const fetchData = async () => {
      const response = await getServiciosFincas()
      setServicios(response)
    }
    function handleChange(evt) {
        const value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
    
      const getBusqueda = () => {
        console.log(busqueda);
        for (let key in busqueda) {
          if (
            busqueda[key] === "" ||
            busqueda[key] === null ||
            busqueda[key] === undefined
            ) {
              delete busqueda[key];
            }
        }
        console.log(busqueda);
        onFilter(busqueda)
      };

    return ( 
        <>
            <div className="buscador">
              {/* <TextField id="razonSocial" name="razonSocial" label="Razón Social" value={busqueda.razonSocial} onChange={handleChange} onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")} /> */}
              <div>
                {/* <input
                    type="text"
                    name="servicio"
                    value={busqueda.servicio}
                    placeholder="Servicio"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input> */}
                <select 
                    name="servp_id" 
                    value={busqueda.servicio}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                    <option value="">Servicio</option>
                    {servicios?.map(s => {
                      return (
                        rol === "TECH" && (s.id === 1 ||s.id === 3) ?
                          <option value={Number(s.id)}>{s.nombre}</option>
                        : rol !== "TECH" ? 
                          <option value={Number(s.id)}>{s.nombre}</option>
                        : 
                        null
                      )
                    })}
                </select>
                <input
                    type="text"
                    name="razonSocial"
                    value={busqueda.razonSocial}
                    placeholder="Comunidad"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="text"
                    name="codigoPostal"
                    value={busqueda.codigoPostal}
                    placeholder="Código postal"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="date"
                    name="ventaDesde"
                    value={busqueda.ventaDesde}
                    placeholder="Fecha Venta Desde"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="date"
                    name="ventaHasta"
                    value={busqueda.ventaHasta}
                    placeholder="Fecha Venta Hasta"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <select 
                    name="visitada" 
                    value={busqueda.visitada}
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                    <option value="">Estado</option>
                    <option value={Boolean(false)}>Pendiente</option>
                    <option value={Boolean(true)}>Visitada</option>
                </select>
                {/* <input
                    type="text"
                    name="estado"
                    value={busqueda.estado}
                    placeholder="Estado"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input> */}
              </div>
              <div>
                <button onClick={() => getBusqueda()}>
                    <BsSearch />
                </button>
              </div>
            </div>
        </>
     );
}
 
export default withRouter(FiltrosVentas);