import axios from "axios";

const urlPath = "generatePDF";

export const generarPDF = async (datos) => {
    console.log(datos.url, datos.nombre, datos.nif)
  try {
    const response = await axios.post(`${urlPath}/pdf`, datos, {responseType: 'blob'});
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
