import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import { pendingNotifications } from './../../providers/api'
import Sidebar from "./sidebar";
import logOut from "./../../icons/logout.svg";
import Settings from "./../../icons/settings.svg";
// import { BellFill } from 'react-bootstrap-icons';
import "./navbar.css";
import { useEffect } from "react";
import { logout } from "./../../services";
import { BsFillBellFill, BsFillGearFill } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { Badge } from "@mui/material";

function Navbar(props) {
  const { user, routes, routesClientes, routesPersonal, routesListados } = props;
  const [sidebar, setSidebar] = useState(true);
  const [checked, setChecked] = useState(true);

  const [numNotification, setNumNotification] = useState(0);
  const location = useLocation();

  const local =
    location.pathname.charAt(1).toUpperCase() + location.pathname.slice(2);

  useEffect(() => {
    // pendingNotifications().then(result => setNumNotification(result))
  }, []);

  const handleChange = (event) => {
    setChecked(!event.target.value);
    showSidebar();
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
    props.showSidebar();
  };
  const cerrarSesion = () => {
    logout();
  };
  return (
    <>
      <Sidebar
        sidebar={sidebar}
        checked={checked}
        rol={user.rol}
        routes={routes}
        routesClientes={routesClientes}
        routesPersonal={routesPersonal}
        routesListados={routesListados}
      />

      <nav className="navbar navbar-light bg-light justify-content-between">
        <div className="container-fluid">
          {/* <button className="navbar-brand btn" onClick={() => showSidebar()}><SquareHalf /></button> */}
          <div className="check-menu form-switch">
            <label className="switch">
              <input
                type="checkbox"
                value={checked}
                checked={checked ? checked : null}
                onChange={handleChange}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div className="buttons">
            <div className="d-inline-block me-2" style={{fontSize: '10px' }}>{user.nombre}</div>
            <div className="notificaciones">
              <Link to="/notificaciones" className="btn link-btn-default">
                <BsFillBellFill />
              </Link>
              {numNotification !== 0 && (
                <Badge color="secondary" className="navbarBadge" badgeContent={numNotification} max={999}></Badge>
                // <div className="notificaciones-popup">{numNotification}</div>
              )}
            </div>
            <Link to={`/password`}>
              <button className="btn btn-default mx-2">
                <BsFillGearFill />
              </button>
            </Link>
            <button className="btn btn-default" onClick={() => cerrarSesion()}>
              <MdLogout />
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
