import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import HeaderBackground from "../../../images/banner_proveedores.jpg";
import Editar from "../../../icons/editar.svg";
import ModalEditar from "./editarDatosAAFF";
import { getAaffListSuplier, SuppListPropertiAaff, getAAFF } from "../../../services";
import { usuarioContext } from "../../../providers/userContext";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
const AAFF = (props) => {
  const user = useContext(usuarioContext);
  const [proveedor, setProveedor] = useState();
  const [declResp, setDeclResp] = useState({});
  const [aaff, setAaff] = useState({});
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [modalNotificacionOpened, setModalNotificacionOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);

  useEffect(() => {
    fetchData()
    getAAFF(user.nif).then((res) => {
      setAaff(res);
    });
  }, [recargar]);

  const fetchData = async () => {
    const t = await SuppListPropertiAaff()
    console.log(t)
    const r = await getAaffListSuplier()
    console.log(r)
  }

  return (
    <>
      <ModalEditar
        aaff={aaff}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />

      <BreadCrumb rutas={[{path:'/aaff', label:"Administrador"}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
          <div className="gridCard">
            <div className="gridHead">INFORMACIÓN GENERAL</div>
            <div className="gridSubhead editarSubhead">
              <div>DATOS DE ADMINISTRADOR DE FINCAS</div>
              <div
                className="editarBtn"
                onClick={() => setModalEditarOpened(true)}
              >
                <span style={{ marginRight: 10 }}>Editar</span>{" "}
                <img src={Editar} alt="Editar" />
              </div>
            </div>
          </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="datosGrid">
          <p>
            <b>Nombre:</b> {aaff.razonSocial}
          </p>
          <p>
            <b>NIF:</b> {aaff.nif}
          </p>
          <p>
            <b>Municipio:</b> {aaff.municipio}
          </p>
          <p>
            <b>Provincia:</b> {aaff.provincia}
          </p>
          <p>
            <b>Email:</b> {aaff.email}
          </p>
          <p>
            <b>Persona de contacto:</b> {aaff.nombreContacto}
          </p>
          <p>
            <b>Teléfono:</b> {aaff.telefono}
          </p>
        </div>
      </div>
    </>
  );
};

export default withRouter(AAFF);
