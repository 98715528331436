import axios from "axios";

const urlPath = "sale";

export const getSales = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/getSale/filter`, data.filtros, {
      params: data.params,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSale = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getSale/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSale = async (data) => {

  try {
    console.log(data);

    const response = await axios.post(urlPath, data);
    console.log(response.status);

    return response.status;
  } catch (err) {
    console.log(err.response);
  }
};

export const noRealizada = async (id, data) => {
  console.log(data)
  try {
    const response = await axios.put(`${urlPath}/notVisit/${id}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};