import axios from "axios";

const urlPath = "supplier";

export const getServicios = async () => {
  try {
    const response = await axios.get(`${urlPath}/services`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const getProveedores = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const getProveedor = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAaffListSuplier = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/AaffListSuplier`, data.filtros, {
      params: data.params,
    });
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const TechListSuplier = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/TechListSuplier`, data.filtros, {
      params: data.params,
    });
    console.log(response.data)
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const createSupplier = async (data) => {
  console.log(data)
  let datos = {
    razonSocial: data.razonSocial,
    nif: data.nif,
    email: data.email,
    telefonoContacto: data.telefonoContacto,
    nombreContacto: data.nombreContacto,
    municipio: data.municipio,
    provincia: data.provincia,
    direccion: data.direccion,
    codigoPostal: data.codigoPostal,
    parent_id:data.parent_id,
    servicio:data.servicio
  };
  console.log(datos)
  try {
    const response = await axios.post(urlPath, datos);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const cambiarProveedor = async (nif, data) => {
  console.log(data);
  /* let data = {
        "status": false
      } */
  try {
    const response = await axios.put(`${urlPath}/${nif}`, data);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getAlertas = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/list/alert`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const SuppListPropertiAaff = async () => {
  try {
    const response = await axios.post(`${urlPath}/SuppListPropertiAaff`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteProveedor = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

// DOCS

export const postSuppDoc = async ({ nif, data, file }) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));
    const response = await axios.post(`docsSupp/${nif}/doc`);
    console.log(response);
  } catch (error) {
    console.log(error);
    return error;
  }
};
