import axios from "axios";

const urlPath = "delegation";

export const getDelegations = async (nif) => {
    console.log(nif)
    try {
      const response = await axios.get(`${urlPath}/${nif}`);
      return response.data;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };