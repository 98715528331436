import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import {
  DataTable,
  ProgressBarWithColors,
  SmoothModal,
} from "../../../components";
import { Iconify } from "../../../utilities";
import { useAAFFs } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import NoVisitadoAAFF from "../../../components/noVisitadoAAFF";
import noVisitada from "../../../icons/noVisitada.svg"
import notaNV from "../../../icons/notaNV.svg"

const motivosNoVisita = [
  "El AAFF no quiere visita en la renovación",
  "El AAFF no quiere que empecemos todavía las visitas",
];

const ColorButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
  alignItems: "center",
}));

const AAFF = () => {
  const user = useContext(usuarioContext);

  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [motivoNoVisita, setMotivoNoVisita] = useState(motivosNoVisita[0]);
  const { loading, data, fetchData, limite } = useAAFFs();

  const handleOpenNewTab = ({ nif }) => {
    if (!nif) return;
    window.open(`aaff/${nif}/comunidades`, "_blank");
  };

  const handlePagination = ({ offset, limit }) => {
    const { id } = user
    // setPagination({ page: offset, limit });
    fetchData({ offset, limit, filtros:busqueda });
  };

  const handleOpenModal = () => setOpenModal(true);

  const closeModal = () => setOpenModal(false);

  const comunidadesRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
    },
    {
      id: "fechaEncargo",
      numeric: false,
      disablePadding: false,
      label: "FECHA ENCARGO",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "Provincia",
    },
    {
      id: "communityNumbers",
      numeric: false,
      disablePadding: false,
      label: "Nº DE COMUNIDADES",
      overrideFunc: (row) => (
        <>
          <Typography>
            {row?.filter((el) => el.visitada).length} / {row?.length}
          </Typography>
        </>
      ),
    },
    {
      id: "communityPercent",
      numeric: false,
      disablePadding: false,
      label: "COMPLETADO (VISITADO)",
      overrideFunc: (row) => (
        <ProgressBarWithColors
          value={
            (row?.filter((el) => el.visitada).length / (row?.length || 1)) * 100
          }
          label={
            parseFloat(
              (
                (row?.filter((el) => el.visitada).length / (row?.length || 1)) *
                100
              ).toFixed(2)
            ) + "%"
          }
        />
      ),
    },
    {
      id: "noVisitada",
      numeric: true,
      disablePadding: false,
      label: "NO VISITA",
      overrideFunc: (row) => (
        console.log(row)
        // <Box
        //   onClick={(e) => {
        //     e.stopPropagation();
        //     handleOpenModal();
        //   }}
        // >
        //   <img src={notaNV} 
        //     style={{ width: 20 }}
        //     sx={{ height: 24, width: 24, mr: 2 }} 
        //   />
        //   {/* <Iconify
        //     icon="solar:document-add-bold-duotone"
        //     sx={{ height: 24, width: 24, mr: 2 }}
        //   /> */}
        // </Box>
        // <Typography>
        //   {
        //   !row.noRealizada && row.fechaRealizada !== null ? (
        //     row.fechaRealizada
        //   ) : (
        //     <NoVisitadoAAFF aaff={row} nif={row.nif} />
        //   )}
        // </Typography>
      ),
    },
  ];

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    console.log(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  const handleSubmitMotivo = () => {
    console.log(motivoNoVisita)
  }

  return (
    <>
      <SmoothModal open={openModal} onClose={closeModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"}>
            Motivo de No Visita
          </Typography>

          <Box sx={{ width: "90%" }}>
            <Select native name="motivoNoVisita" id="motivoNoVisita" style={{ textTransform: 'uppercase'}} onChange={setMotivoNoVisita} defaultValue={motivoNoVisita}>
              <option value=""></option>
              {motivosNoVisita.map(motivo => {
                return (
                  <option key={motivo} value={motivo}>
                    {motivo}
                  </option>
                );
              })}
            </Select>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <ColorButton onClick={handleSubmitMotivo}>
              <Typography>Guardar</Typography>
            </ColorButton>
            <Button onClick={closeModal}>Cancelar</Button>
          </Box>
        </Box>
      </SmoothModal>
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  ADMINISTRADORES DE FINCAS
              </div>
          </div>
      </div>
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} />  
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data?.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data?.current_page}
                meta={data?.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(AAFF);
