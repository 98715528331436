
import { toast } from "react-toastify";
import Swal from "sweetalert2"
import { formatoFechas } from "../functions/funciones";
import { createDocsSupp, deleteDocsSupp, updateDocsSupp, validateDocsSupp } from "../services/docs.service";
import axios from "axios";
import { createDeclRes, updateDeclRes, validateDeclRes } from "../services/declRes.service";

export const subirDocumentacionProveedor = async (ruta, id, idDoc, rol, nif) => {
  //creamos todo dentro de un promise para que cuando acabe de subirse se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      var el = document.getElementById("fileElem");
      let toastInfo = undefined
      const swalConfig = {
        title: "Subir documentacion<br/>",
        showCancelButton: true,
        confirmButtonText: "Guardar  y elegir archivo",
        confirmButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-3',
        },
        focusConfirm: false,
      };
      if( idDoc !== 1 && idDoc !== 4 && idDoc !== 5 ){
        swalConfig.html = '<div><p>Indica la fecha de caducidad del documento</p><input type="date" id="fecha" style="margin: 20px 0" class="swal2-input"></div>';
      }
      
      Swal.fire({
        ...swalConfig,
        focusConfirm: false,
        preConfirm: () => {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          let fecha = null;
          if( idDoc !== 1 && idDoc !== 4 && idDoc !== 5 ){
            fecha = formatoFechas(Swal.getPopup().querySelector("#fecha").value);
            if (!fecha) {
              Swal.showValidationMessage(`fecha caducidad`);
            }
          }
          const data = {
            tipoDoc: idDoc,
            fechaCaducidad: fecha,
            pendiente: rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? false : true, 
            avisado: false, 
            segundoAviso: false, 
            validado: rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? true : false,
          };
          return { data: data };
        },
      }).then((result) => {
        if (result.isConfirmed) { //si se le da al boton de guardar
          el.click()
          el.onchange = () => {
            // if(ruta === undefined || ruta === null){ //si no hay ruta se sube la documentación y si ya hay un documento, se actualiza. 
            //   createDocsSupp(el.files[0], nif, result.value.data).then( res => {
            //     toast.update(toastInfo, {
            //       render: "El archivo se ha subido correctamente",
            //       type: toast.TYPE.SUCCESS,
            //       autoClose: true
            //     });
            //     resolve(true);
            //   }).catch(err => {
            //     console.log(err)
            //     toast.update(toastInfo, {
            //       render: "Error al subir el archivo",
            //       type: toast.TYPE.ERROR,
            //       autoClose: true
            //     });
            //   })
            // }else{
            // }
            updateDocsSupp(el.files[0], nif, id, result.value.data).then( res => {
              toast.update(toastInfo, {
                render: "El archivo se ha actualizado correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            }).catch(err => {
              console.log(err)
              toast.update(toastInfo, {
                render: "Error al actualizar el archivo",
                type: toast.TYPE.ERROR,
                autoClose: true
              });
            })
          }
        }
      });
    }catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  })
  
    
}

export const subirDocumentacionPerfilProveedor = async (ruta, id, idDoc, rol, nif) => {
  //creamos todo dentro de un promise para que cuando acabe de subirse se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      let toastInfo = toast.info("Guardando datos", { autoClose: false });
      var el = document.getElementById("fileElem");
      const data = {
        tipoDoc: idDoc,
        pendiente: rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? false : true, 
        avisado: false, 
        segundoAviso: false, 
        validado: rol === "ADMIN" || rol === "CONSUL" || rol === "TECH" ? true : false,
      };
      el.click()
      el.onchange = () => {
        updateDocsSupp(el.files[0], nif, id, data).then( res => {
          toast.update(toastInfo, {
            render: "El archivo se ha actualizado correctamente",
            type: toast.TYPE.SUCCESS,
            autoClose: true
          });
          resolve(true);
        }).catch(err => {
          console.log(err)
          toast.update(toastInfo, {
            render: "Error al actualizar el archivo",
            type: toast.TYPE.ERROR,
            autoClose: true
          });
        })
      }
    }catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  })
  
    
}

export const subirDocumentacionCentroTrabajo = (nombreDoc, finca, nifCentroTrabajo, callback) => {

}

export const subirDocumentacionComunidad = (nombreDoc, finca, callback) => {

}


export const abrirArchivo = async (ruta) => {
  if (!ruta) {
    toast.error("No existe el archivo")
  } else {
    try {
      const url = `${process.env.REACT_APP_PUBLIC_URL}${ruta.replace('/storage', '')}`;
      window.open(url, "_blank")
    } catch (error) {
      console.log(error)
      toast.error("Ha ocurrido un error al descargar el archivo")
    }
  }
}

export const verDocumentacion = (ruta, id, idDoc, rol, nif) => {
  //creamos todo dentro de un promise para que cuando acaben las funciones se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      Swal.fire({
        title: "¿Qué quieres hacer con el documento?",
        text:" Subir un nuevo documento implica sobreescribir el anterior",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Ver",
        confirmButtonColor: "rgb(130, 130, 130)",
        denyButtonText: "Sobreescribir",
        denyButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Borrar documento",
        cancelButtonColor: "#892828",
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-3',
          denyButton: 'order-2',
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          abrirArchivo(ruta)
        } else if (result.isDenied) {
          const subirDocPromise = subirDocumentacionProveedor(ruta, id, idDoc, rol, nif)
          subirDocPromise.then(resolve).catch(reject); //esperar a ejecutar la funcion para enviar la promesa
        }else if(result.dismiss === Swal.DismissReason.cancel){
          // const deleteDocPromise = deleteDocsSupp(nif, id)
          const deleteDocPromise = validateDocsSupp(nif, id, {validado:false})
          deleteDocPromise.then(resolve).then( toast.success("Documento eliminado correctamente")).catch(reject)
        }
      });
    }catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  })
};
export const validarDocumentacion = (ruta, id, nif) => {
  return new Promise(async (resolve, reject) => {
    try {
      let toastInfo = undefined
      console.log(ruta, id, nif)
      abrirArchivo(ruta)
      const swalConfig = {
        title: "¿Qué quieres hacer con el documento?",
        text:" Si no se valida se borrará",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Validar",
        confirmButtonColor: "#7bc977",
        denyButtonText: "No validar",
        denyButtonColor: "#FF5252",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-3',
          denyButton: 'order-2',
        },
        focusConfirm: false,
      }
      if( id !== 1 && id !== 4 && id !== 5 ){
        swalConfig.html = '<div><p>Indica la fecha de caducidad del documento</p><input type="date" id="fecha" style="margin: 20px 0" class="swal2-input"></div>';
      }
      Swal.fire({
        ...swalConfig,
        preConfirm: () => {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          let fecha = null;
          if( id !== 1 && id !== 4 && id !== 5 ){
            fecha = formatoFechas(Swal.getPopup().querySelector("#fecha").value);
          }
          if (!fecha) {
            Swal.showValidationMessage(`fecha caducidad`);
          }
          const data = {
            fechaCaducidad: fecha,
            validado: true,
          };
          return { data: data };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("validar")
          validateDocsSupp(nif, id, result.value.data).then( res => {
            toast.update(toastInfo, {
              render: "El archivo se ha validado correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            resolve(true);
          }).catch(err => {
            console.log(err)
            toast.update(toastInfo, {
              render: "Error al validar el archivo",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          })
          
        }else if(result.isDenied){
          console.log("no validar")
          validateDocsSupp(nif, id, result.value.data).then( res => {
            // toast.update(toastInfo, {
            //   render: "El archivo se ha validado correctamente",
            //   type: toast.TYPE.SUCCESS,
            //   autoClose: true
            // });
            resolve(true);
          }).catch(err => {
            console.log(err)
            // toast.update(toastInfo, {
            //   render: "Error al validar el archivo",
            //   type: toast.TYPE.ERROR,
            //   autoClose: true
            // });
          })
        }
      });
    }catch(error){
      reject(error);
    }
  })
};



export const borrarArchivo = (ruta) => {
  // Por hacer
}

export const subirDeclaracionResponsable = async (nifProv, idAAFF, ruta, idDeclRes) => {
  console.log(idAAFF, nifProv, ruta, idDeclRes)
  //creamos todo dentro de un promise para que cuando acabe de subirse se actualice la info en la vista sin necesidad de recargar pagina
  return new Promise(async (resolve, reject) => {
    try {
      // await new Promise((resolve) => setTimeout(resolve, 200));
      var el = document.getElementById("fileElem");
      let toastInfo = undefined
      Swal.fire({
        title: "Subir declaración responsable",
        showCancelButton: true,
        confirmButtonText: "Guardar  y elegir archivo",
        confirmButtonColor: "rgb(130, 130, 130)",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-3',
        },
        focusConfirm: false,
        preConfirm: () => {
          toastInfo = toast.info("Guardando datos", { autoClose: false });
          const data = {
            aaff_id: idAAFF
          };
          return { data: data };
        },
      }).then((result) => {
        if (result.isConfirmed) { //si se le da al boton de guardar
          el.click()
          el.onchange = () => {
            if(idDeclRes===undefined){ //si no hay ruta se sube la documentación y si ya hay un documento, se actualiza. 
              createDeclRes(el.files[0], nifProv, result.value.data).then( res => {
                toast.update(toastInfo, {
                  render: "El archivo se ha subido correctamente",
                  type: toast.TYPE.SUCCESS,
                  autoClose: true
                });
                resolve(true);
              }).catch(err => {
                console.log(err)
                toast.update(toastInfo, {
                  render: "Error al subir el archivo",
                  type: toast.TYPE.ERROR,
                  autoClose: true
                });
              })
            }else{
              updateDeclRes(el.files[0], nifProv, idDeclRes).then( res => {
                toast.update(toastInfo, {
                  render: "El archivo se ha actualizado correctamente",
                  type: toast.TYPE.SUCCESS,
                  autoClose: true
                });
                resolve(true);
              }).catch(err => {
                console.log(err)
                toast.update(toastInfo, {
                  render: "Error al actualizar el archivo",
                  type: toast.TYPE.ERROR,
                  autoClose: true
                });
              })
            }
          }
        }
      });
    }catch (error) {
      // En caso de error, rechaza la promesa
      reject(error);
    }
  })
}

export const validarDeclRes = (ruta, id, nifAAFF) => {
  // const toastInfo = toast.info()
  console.log(ruta, id, nifAAFF)
  return new Promise(async (resolve, reject) => {
    try {
      const toastInfo = toast.info()
      abrirArchivo(ruta)
      Swal.fire({
        title: "¿Qué quieres hacer con el documento?",
        text:" Si no se valida se borrará",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Validar",
        confirmButtonColor: "#7bc977",
        denyButtonText: "No validar",
        denyButtonColor: "#FF5252",
        cancelButtonText: "Cancelar",
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-3',
          denyButton: 'order-2',
        },
        focusConfirm: false,
        
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("validar")
          validateDeclRes(nifAAFF, id, {validate:true}).then( res => {
            toast.update(toastInfo, {
              render: "El archivo se ha validado correctamente",
              type: toast.TYPE.SUCCESS,
              autoClose: true
            });
            resolve(true);
          }).catch(err => {
            console.log(err)
            toast.update(toastInfo, {
              render: "Error al validar el archivo",
              type: toast.TYPE.ERROR,
              autoClose: true
            });
          })
          
        }else if(result.isDenied){
          console.log("no validar")
          validateDeclRes(nifAAFF, id, {validate:false}).then( res => {
            resolve(true);
          }).catch(err => {
            console.log(err)
          })
        }
      });
    }catch(error){
      reject(error);
    }
  })
}