import React, { useState } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import provinces from '@doncicuto/es-provinces';
import municipalities from '@doncicuto/es-municipalities';
import { ordenarProvinciasYMunicipios } from '../../functions/ordenarListas';

const FiltrosProveedores = ({onFilter}) => {
    const [busqueda, setBusqueda] = useState({})

    function handleChange(evt) {
        const value = evt.target.value;
        setBusqueda({
          ...busqueda,
          [evt.target.name]: value,
        });
      }
    
      const getBusqueda = () => {
        
        for (let key in busqueda) {
          if (
            busqueda[key] === "" ||
            busqueda[key] === null ||
            busqueda[key] === undefined
          ) {
            delete busqueda[key];
          }
        }
        console.log(busqueda);
        onFilter(busqueda)
      };

    return ( 
        <>
            <div className="buscador">
              <div>

                <input
                    type="text"
                    name="razonSocial"
                    value={busqueda.razonSocial}
                    placeholder="Razón Social"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="text"
                    name="nif"
                    value={busqueda.nif}
                    placeholder="Nif"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <input
                    type="text"
                    name="emailContacto"
                    value={busqueda.email}
                    placeholder="Email"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                ></input>
                <select
                    type="text"
                    name="provincia"
                    value={busqueda.provincia}
                    placeholder="Provincia"
                    onChange={handleChange}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                    <option value="">Provincia</option>
                    provinces
                    {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                            <option key={i} value={e.name}>
                            {e.name}
                            </option>
                        );
                    })}
                </select>
              </div>
              <div>
                <button
                  onClick={() => getBusqueda()}
                  style={{ marginLeft: "10px" }}
                >
                  <BsSearch />
                </button>
              </div>
            </div>
        </>
     );
}
 
export default withRouter(FiltrosProveedores);