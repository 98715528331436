import React, { useContext, useState } from "react";
import { Box, Button, Select, TextField, Typography } from "@mui/material";
import { usuarioContext } from "../../../providers/userContext";
import { useEffect } from "react";
import { createDeclRes, getProveedor } from "../../../services";
import moment from "moment";
import { Iconify } from "../../../utilities";
import { EditarProveedor, LaeButton, SmoothModal, declRes } from "../../../components";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { daysDiff } from "../../../functions/funciones";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import { DOC_TYPES, DOC_TYPES_NAMES } from "../../../models";
import { withRouter } from "../../../providers/withRouter";
import { subirDocumentacionPerfilProveedor } from "../../../providers/documentos";
import { getDocsType } from "../../../services/docsType.service";
import DeclRes from "../../../components/proveedores/declRes";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Editar from "../../../icons/editar.svg";

const Proveedor = () => {
  const user = useContext(usuarioContext);

  const [info, setInfo] = useState(0);
  const [formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [docsType, setDocsType] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getProveedor(user?.nif);
      
      const res = await getDocsType()
      console.log(response)
      console.log(res)
      setDocsType(res)
      
      setInfo(response);
      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, response?.provincia)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = ({ target }) =>
    setFormData((prev) => ({
      ...prev,
      [target?.name]: target?.value ?? "",
    }));

  const handleOpenDoc = (route) =>
    window.open("http://localhost:4001" + route, "_blank");

  const handlePut = async () => {
    try {
      console.log(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const subirArchivo = async (ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    console.log(ruta, id, idDoc, rol, nif)
    subirDocumentacionPerfilProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
      console.log(resultado)
      console.log("resultado")
      handleSubirDocsCallback(resultado)
    })
  };

  const handleUploadDecl = async () => {
    try {
      const response = await createDeclRes(null, "asjdnajsdnasd", {
        aaff_id: null,
      });

      console.log(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, [recargar]);
  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Proveedor / {user?.nombre ?? ""}
        </Typography>
      </div> */}
      <BreadCrumb rutas={[{path:'/proveedor', label:'Panel principal'}]} />
      <EditarProveedor
        data={info}
        showModal={showModal}
        onSubmit={handlePut}
        handleChange={handleChange}
        onClose={() => setShowModal(false)}
      />

      <div className="listPage">
        <div className="gridCard">
          <div
            className="gridHead"
            style={{
              backgroundColor: "#92004D",
              display: "flex",
              flexDirection: " row",
            }}
          >
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>
            {/* <Box className="lae-flex lae-flex-row">
              <Typography fontWeight="bold">Editar</Typography>
              <Iconify
                icon={"akar-icons:edit"}
                height={24}
                width={24}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => setShowModal(true)}
              />
            </Box> */}
          </div>
          <div className="gridSubhead editarSubhead">
            <div></div>
            <div
              className="editarBtn"
              onClick={() => setShowModal(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
        </div>
        <Box sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography>DATOS DE PROVEEDOR DE MANTENIMIENTO:</Typography>
            <Box className="lae-flex lae-flex-row">
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Nombre Proveedor: {info?.razonSocial}</Typography>
                <Typography>NIF: {info?.nif}</Typography>
                <Typography>
                  Persona de contacto: {info?.nombreContacto}
                </Typography>
                <Typography>
                  Email de contacto: {info?.emailContacto}
                </Typography>
                <Typography>
                  Telefono de contacto: ${info?.telefonoContacto}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Dirección: {info?.direccion}</Typography>
                <Typography>Municipio: {info?.municipio}</Typography>
                <Typography>CP: {info?.codigoPostal}</Typography>
                <Typography>Provincia: {info?.provincia}</Typography>
                <Typography>
                  Fecha de alta: {moment(info?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="lae-flex lae-flex-row" sx={{ width: "100%" }}>
          <Box sx={{ flex: 1 }}>
            <div className="gridCard">
              <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
                <Typography fontWeight="bold">DOCUMENTACIÓN CAE</Typography>
                {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
              </div>
            </div>

            <Box
              className="lae-flex lae-flex-column"
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                mb: 1,
                borderRadius: "0 0 10px 10px",
                boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
                justifyContent: "space-between",
              }}
            >
              <Box className="lae-flex lae-flex-row">
                <Box sx={{ flex: 8 }}>
                  <picture></picture>
                  <Typography></Typography>
                </Box>
                <Box
                  className="lae-flex lae-flex-row"
                  sx={{
                    flex: 2,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ w: "24px" }}>
                    <Typography>Caducidad</Typography>
                  </Box>
                  <Box sx={{ w: "24px" }}>
                    <Typography>Ver</Typography>
                  </Box>
                  <Typography>Adjuntar</Typography>
                </Box>
              </Box>

              {Object.values(docsType).map((dt, i) => {
                const findDoc = info?.docssupps?.find(
                  (doc) => doc.tipoDoc === dt.id
                );
                  	
                return (
                  <Box className="lae-flex lae-flex-row" sx={{ pr: 0.4 }}>
                    <Box>
                      {findDoc?.validado ? <img src={Tick} width={20} /> : findDoc?.pendiente ? <img src={Minus} width={20} /> : <img src={Cross} width={20} />}
                    </Box>
                    <Box sx={{ flex: 8 }}>
                      <picture></picture>
                      <Typography variant="body2">
                        {docsType[i].nombre}
                      </Typography>
                    </Box>
                    <Box
                      className="lae-flex lae-flex-row"
                      sx={{
                        flex: 2,
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        {findDoc?.fechaCaducidad !==
                        null && findDoc?.status ? (
                          <span
                            style={{
                              color:
                                daysDiff(
                                  new Date(
                                    findDoc?.fechaCaducidad
                                  ),
                                  new Date()
                                ) > 30
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {new Date(
                              findDoc?.fechaCaducidad
                            ).toLocaleDateString()}
                          </span>
                        ) : (
                          <span style={{ display:'block', width:60, textAlign: 'right' }}>----</span>
                        )}
                      </Typography>
                      <Iconify
                        icon={"solar:eye-line-duotone"}
                        height={24}
                        width={24}
                        style={{
                          textAlign: "center",
                          opacity: findDoc?.ruta ===undefined ? 0.4 : 1,
                        }}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDoc(
                            findDoc?.ruta?.replaceAll("/storage", "")
                          )
                        }
                      />
                      <Iconify
                        icon={"material-symbols:upload"}
                        height={24}
                        width={24}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => subirArchivo(
                          findDoc?.ruta,
                          findDoc?.id,
                          findDoc?.tipoDoc, 
                          user.rol, 
                          info.nif
                        )}
                      />
                    </Box>
                  </Box>
                );
              })}
              {/* {info?.docssupps?.map((doc) => {
                return (
                  <Box className="lae-flex lae-flex-row" sx={{ pr: 0.4 }}>
                    <Box sx={{ flex: 8 }}>
                      <picture></picture>
                      <Typography variant="body2">
                        {DOC_TYPES_NAMES[doc.tipoDoc]}
                      </Typography>
                    </Box>
                    <Box
                      className="lae-flex lae-flex-row"
                      sx={{
                        flex: 2,
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography>
                        {doc.fechaCaducidad
                          ? moment(doc.fechaCaducidad).format("DD/MM/YYYY")
                          : "----"}
                      </Typography>
                      <Iconify
                        icon={"solar:eye-line-duotone"}
                        height={24}
                        width={24}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleOpenDoc(doc.ruta.replaceAll("/storage", ""))
                        }
                      />
                      <Iconify
                        icon={"material-symbols:upload"}
                        height={24}
                        width={24}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => console.log("clickedIt")}
                      />
                    </Box>
                  </Box>
                );
              })} */}
            </Box>
          </Box>
          <Box sx={{ flex: 1 }}>
            <DeclRes nifProv={user.nif} />
          </Box>
        </Box>
        <input type="file" id="fileElem" hidden></input>
      </div>
    </>
  );
};

export default withRouter(Proveedor);
