import React, { useState } from 'react';
import { PDFViewer, Document, Page, Image, Text, View } from "@react-pdf/renderer";
import rgpd from '../../images/certificado_rgpd.png'
import { withRouter } from '../../providers/withRouter';
import { useEffect } from 'react';
import { getFinca } from '../../services';
import { decryptParameter, formatoFechasEs } from '../../functions/funciones';

const CertificadoVigilancia = (props) => {

    const backgroundImageUrl = rgpd; // Reemplaza con la URL de tu imagen de fondo

    
    const [finca, setFinca] = useState({})
    // const [finca, setFinca] = useState({})
    const fechaActual = new Date();
    const nuevaFecha = new Date(fechaActual);
    const fechaVenta = nuevaFecha.setFullYear(fechaActual.getFullYear() + 1);
    // const id = decryptParameter(props.params.id);
    // const nif = decryptParameter(props.params.nif);
    // console.log(id, nif)

    useEffect(()=>{

        fetchData()
    },[])

    const fetchData = async () => {
        console.log(props)
        const response = await getFinca(props.params.nif)
        setFinca(response)
        console.log(response)
    }
    return ( 
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page>
            <View>
                <Image src={backgroundImageUrl} />
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"345px",
                              color: 'black', 
                              fontSize: '12px'}}>
                  {finca.razonSocial}
                </Text>
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 170,
                              top:"670px",
                              color: 'white', 
                              fontSize: '12px'}}>
                  {formatoFechasEs(fechaVenta)}
                </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
     );
}
 
export default withRouter(CertificadoVigilancia);