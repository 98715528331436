import React, { useRef, useEffect, useState } from "react";

import "./style/DragNDrop.scss";
import classNames from "classnames";

const DefaultTitle = () => (
  <>
    "Hey, drop me some files"
    <span role="img" aria-label="emoji" className="area__icon">
      🐼
    </span>
  </>
);

function DragNDrop({
  onUpload,
  children,
  count,
  formats,
  title,
  label,
  openDialogOnClick,
}) {
  const drop = useRef(null);
  const drag = React.useRef(null);
  const input = React.useRef(null);
  const [dragging, setDragging] = useState(false);

  const [message, setMessage] = useState({
    show: false,
    text: null,
    type: null,
  });

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const showMessage = (text, type, timeout) => {
    setMessage({
      show: true,
      text,
      type,
    });

    setTimeout(
      () =>
        setMessage({
          show: false,
          text: null,
          type: null,
        }),
      timeout
    );
  };

  const handleDrop = (e) => {
    console.log(e)
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    // const files = [...e.dataTransfer.files];
    const files = [...e.target.files];

    console.log(files);

    if (count && count < files.length) {
      showMessage(
        `Nope, only ${count} file${
          count !== 1 ? "s" : ""
        } can be uploaded at a time`,
        "error",
        2000
      );
      return;
    }

    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      showMessage(
        `Nope, only following file formats are acceptable: ${formats.join(
          ", "
        )}`,
        "error",
        2000
      );
      return;
    }

    if (files && files.length) {
      showMessage("Yep, that's what I want", "success", 1000);
      onUpload(files);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) {
      setDragging(false);
    }
  };

  useEffect(() => {
    drop.current.addEventListener("dragover", handleDragOver);
    drop.current.addEventListener("drop", handleDrop);
    drop.current.addEventListener("dragenter", handleDragEnter);
    drop.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      if (drop.current) {
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
        drop.current.removeEventListener("dragenter", handleDragEnter);
        drop.current.removeEventListener("dragleave", handleDragLeave);
      }
    };
  }, []);

  const openFileDialog = () => {
    // @ts-ignore
    input && input.current.click();
  };

  return (
    <div
      ref={drop}
      className="DragNDrop"
      onClick={openDialogOnClick ? openFileDialog : undefined}
    >
      {openDialogOnClick && (
        <input
          ref={input}
          type="file"
          name="fileElement"
          accept={
            formats
              ? formats.map((format) => `.${format}`).join(", ")
              : undefined
          }
          multiple={!count || count > 1}
          onChange={handleDrop}
        />
      )}
      {label && (
        <div
          className={
            dragging
              ? "DragNDrop__label--placeholder"
              : message.show
              ? `DragNDrop__label--${message.type}`
              : "DragNDrop__label"
          }
        >
          {label}
        </div>
      )}
      {message.show && (
        <div
          className={classNames(
            "DragNDrop__placeholder",
            `DragNDrop__placeholder--${message.type}`
          )}
        >
          {message.text}
          <span role="img" aria-label="emoji" className="area__icon">
            {message.type === "error" ? <>&#128546;</> : <>&#128536;</>}
          </span>
        </div>
      )}
      {dragging && (
        <div className="DragNDrop__placeholder" ref={drag}>
          Drop that file down low
          <span role="img" aria-label="emoji" className="area__icon">
            &#128526;
          </span>
        </div>
      )}
      {children ?? (
        <div className="DragNDrop__area">{title ?? <DefaultTitle />}</div>
      )}
    </div>
  );
}

export default DragNDrop;
