import React from "react"
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import CheckControl from "../icons/controlComunidad.svg";
import VentasIcon from "../icons/ventas.svg";

import Inicio from "./pages/comercial/Inicio";
import AAFF from "./pages/comercial/AAFF";
import AAFFInformes from "./pages/comercial/AAFFInformes";
import Ventas from "./pages/general/Ventas";
/* import Comunidades from "./pages/comercial/Comunidades";
import Proveedores from "./pages/comercial/Proveedores";
import Proveedor from "./pages/comercial/Proveedor"; */

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff-informes" element={<AAFFInformes />} />
      <Route path="/ventas/" element={<Ventas />} />
      
{/*       <Route path="/aaff/:nif/comunidades" element={<Comunidades />} />
      <Route path="/aaff/:nif/proveedores" element={<Proveedores />} />
      <Route path="/aaff/:nifAAFF/proveedores/:nifProveedor" element={<Proveedor />} /> */}
    </Routes>
  )
}

export function MenuLinksClientes(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/aaff" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "",  color: props.showSidebar ? "#33383b" : "#fff"}}>
          <div className="menuBtn">
            <img src={Edificios} alt="Administradores de Fincas" />Administradores de Fincas
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/ventas"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn">
            <img src={VentasIcon} alt="Ventas" />
            Ventas
          </div>
        </NavLink>
      </li>
    </>
  )
}
export function MenuLinksListado(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/aaff-informes" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn">
            <img src={CheckControl} alt="Generar informes" />Generar informes
          </div>
        </NavLink>
      </li>
    </>
  )
}