import React, { useState, useEffect } from "react";
import { withRouter } from "../../providers/withRouter";
import { getDelegations } from "../../services/delegation.service";
import { Link } from "react-router-dom";


const Delegations = (props) => {
    
    const [delegaciones, setDelegaciones] = useState([]);
    
    const fetchData = async () => {
        console.log(props.nif)
        const response = await getDelegations(props.nif)
        const {data} = response
        console.log(data)
        setDelegaciones(data)
    }
    
    useEffect(() => {
        fetchData()
    },[])
    return ( 
        <>
        {delegaciones.length === 0 ?
            <p>No tiene delegaciones</p>
        :
            delegaciones.map(d => {
                return(
                    <p key={d.id}>
                        <Link to={`/proveedores/${d.nif}`} >{d.razonSocial}</Link>
                    </p>
                )
            })
        }
        </>
     );
}
 
export default withRouter(Delegations);