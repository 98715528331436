import axios from "axios";

const formHeaders = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

export const plantillaAAFF = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`aaff/file`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const plantillaFincas = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/create-salescae-multiple`, formData, {
      ...formHeaders,
    });
    // const response = await axios.post(`propertie/file`, formData, {
    //   ...formHeaders,
    // });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const plantillaRenovaciones = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/renovate-sales-multiple`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const plantillaFincasLOPD = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/xlsx`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const plantillaProveedores = async (data) => {
  console.log(data);
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`supplier/file`, formData, {
      ...formHeaders,
    });
    return response.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export const plantillaMatcheo = async (data) => {
  console.log(data);
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`aaff/matchFileProp`, formData, {
      ...formHeaders,
    });
    return response.data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
