import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { IoSave, IoDocumentText } from "react-icons/io5";
import {
    getAAFFs,
} from "../../../services";

const comunidadesRows = [
  {
    id: "aff_name",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR",
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "informeComunidades",
    numeric: false,
    disablePadding: false,
    label: "INFORME COMUNIDADES",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-comunidades`} target={"_blank"}>
          <IoDocumentText alt="Informe comunidades" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
  {
    id: "informeProveedores",
    numeric: false,
    disablePadding: false,
    label: "INFORME PROVEEDORES",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-proveedores`} target={"_blank"}>
          <IoDocumentText alt="Informe proveedores" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
  {
    id: "informeRiesgos",
    numeric: false,
    disablePadding: false,
    label: "INFORME RIESGOS",
    overrideFunc: (row) => (
      <div className="bigBtn">
        <Link to={`/doc-render/${row}/situacion-riesgos`} target={"_blank"}>
          <IoDocumentText alt="Informe riesgos" />
          <span style={{marginLeft:15}}>Generar</span>
        </Link>
      </div>
    ),
  },
];



const AAFFInformes = () => {
  const user = useContext(usuarioContext);
  const [recargar, setRecargar] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    totalItems: 0,
    totalPages: 0,
    rows: [],
    currentPage: 1,
  });
  useEffect(() => {
    fetchData();
    setRecargar(false);
  }, [recargar]);
console.log(user.id)
  const fetchData = async ({ page, size }) => {
    const payload = {
      commer_id: user.id,
      page,
      size,
    };

    try {
      setLoading(true);
      const response = await getAAFFs(payload);
      console.log(response)
      const { totalItems, totalPages, data } = response;
        console.log(data)
      setData({
        totalItems,
        totalPages,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  const formatData = (data) => {
    
    return data.map((row) => ({
        
      aff_name: <Link to={"/aaff/" + row.nif + "/comunidades"}>{row.razonSocial}</Link>,
      nif: row.nif,
      informeComunidades: row.nif,
      informeProveedores: row.nif,
      informeRiesgos: row.nif,
      
    }));
  };


  
  
  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff-informes', label:"Administradores de fincas"}]} style={{ margin: '0 10px'}} />
      <div className="pageContent tableContainer">
        <div className="tableWrapper">
            <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={comunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.currentPage}
                    totalItems={data.totalItems}
                    totalPages={data.totalPages}
                    mensaje={"No hay administradores de fincas"}
                />
                </Box>
            </Box>
        </div>
      </div>
      
    </>
  );
};

export default withRouter(AAFFInformes);
