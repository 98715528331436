import axios from "axios";

const urlPath = "technician";

export const createTec = async (data) => {
  const datos = {
    nombre: data.nombre,
    apellidos: data.apellidos,
    nif: data.nif,
    email: data.email,
    telefono: data.telefono,
    zona: data.zona,
  };
  try {
    const response = await axios.post(urlPath, datos);
    console.log(response.data);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const getTecnicos = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getTecnico = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const asignarTecnico = async (nif, id) => {
  const datos = {
    tech_id: id,
  };
  console.log(nif, id);
  try {
    const response = await axios.put(`aaff/${nif}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteTecnico = async (id) => {
  console.log(id);
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    //console.log(response.data)

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
