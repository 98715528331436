import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import { Box, Typography, Button } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { usuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { Iconify } from "../../../utilities";
import { BsDownload, BsUpload, BsSearch } from "react-icons/bs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import moment from "moment";
import {
  getAAFFs,
  editarAAFF,
  asignarTecnico,
  asignarConsultor,
  asignarComercial,
  getFincasAAFF,
  getProveedores,
} from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Tick from "../../../icons/tick.svg";
import Warning from "../../../icons/warning.svg";
import { docsPendientesProvs, formatoFechas, formatoFechasEs, numProveedoresAAFF } from "../../../functions/funciones";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";


const AAFF = () => {
  const user = useContext(usuarioContext);
  // const [recargar, setRecargar] = useState(0);
  //   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const [busqueda, setBusqueda] = useState({});
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
  }, [recargar]);

  const fetchData = async ({ offset, limit, filtros }) => {
    setLimite(limit)
    const payload = {
      // aaff_id: 7,
      filtros,
      params: { page: offset, limit },
    };

    try {
      setLoading(true);
      const response = await getAAFFs(payload);
      const { meta, data } = response;
      

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function reasignar(a, b) {
    Swal.fire({
      title: "¿Cambiar " + b + "?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      denyButtonText: `Desasignar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.replace("/aaff/" + a.nif + "/" + b);
      } else if (result.isDenied) {
        switch (b) {
          case "tecnico":
            asignarTecnico(a.nif, null).then((res) => {
              console.log(res);
            });
            break;
          case "consultor":
            asignarConsultor(a.nif, null).then((res) => {
              console.log(res);
            });
            break;
          case "comercial":
            asignarComercial(a.nif, null).then((res) => {
              console.log(res);
            });
            break;
          default:
        }
        fetchData({ offset: 1, limit: 5 });
        toast.success(b + " desasignado");
      }
    });
  }

  const formatData = (data) => {
    return data.map((row) => ({
      aff_name:
        (
          <Link to={"/aaff/" + row.nif + "/comunidades"}>
            {row.razonSocial}
          </Link>
        ) || row.propertie?.aaff?.nombreContacto,
      nif: row.nif || row.propertie?.nif,
      fechaEncargo: {fechaEncargo: row.fechaEncargo, nifAAFF: row.nif},
      id: row.id,
      comunidades: row.properties?.filter(objeto => objeto.status === true) ?? [],
      // comunidadesVisitadas: row.properties ?? [],
      // comunidadesRenovasVisitadas: row.properties ?? [],
      consultor:
        row.consultant?.id === "" ||
        row.consultant?.id === null ||
        row.consultant?.id === undefined ? (
          <Link to={"/aaff/" + row.nif + "/consultor"}>
            <div className="asignarButton">
              <img src={Add} alt="Más" />
              Asignar
            </div>
          </Link>
        ) : (
          <span
            onClick={() => reasignar(row, "consultor")}
            style={{ cursor: "pointer" }}
          >
            {row.consultant?.nombre + " " + row.consultant?.apellidos}
          </span>
        ),
      tecnico:
        row.technician?.id === "" ||
        row.technician?.id === null ||
        row.technician?.id === undefined ? (
          <Link to={"/aaff/" + row.nif + "/tecnico"}>
            <div className="asignarButton">
              <img src={Add} alt="Más" />
              Asignar
            </div>
          </Link>
        ) : (
          <span
            onClick={() => reasignar(row, "tecnico")}
            style={{ cursor: "pointer" }}
          >
            {row.technician?.nombre + " " + row.technician?.apellidos}
          </span>
        ),
      comercial:
        row.commercial?.id === "" ||
        row.commercial?.id === null ||
        row.commercial?.id === undefined ? (
          <Link to={"/aaff/" + row.nif + "/comercial"}>
            <div className="asignarButton">
              <img src={Add} alt="Más" />
              Asignar
            </div>
          </Link>
        ) : (
          <span
            onClick={() => reasignar(row, "comercial")}
            style={{ cursor: "pointer" }}
          >
            {row.commercial?.nombre + " " + row.commercial?.apellidos}
          </span>
        ),
      direction: row.direccion || row.propertie?.direccion,
      cp: row.codigoPostal || row.propertie?.codigoPostal,
      municipio: row.municipio || row.propertie?.municipio,
      provincia: row.provincia,
      empleados: (row.trabajadores || row.propertie?.trabajadores) ?? "No",
      servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
      hojasVisita: row.visitSheets,
      visitada: row.visitada || row.propertie?.visitada,
      proveedores: numProveedoresAAFF(row.properties),
      docsPendientes: docsPendientesProvs(row.properties),
      renovarComunidades: row,
    }));
  };

  const cambiarFechaEncargo = (nif) => {
    
    Swal.fire({
      title: "Cambiar fecha encargo",
      html: `<input type="date" id="fecha" className="swal2-input">`,
      confirmButtonText: "Cambiar",
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector("#fecha").value;
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`);
        }
        return { fecha: fecha };
      },
    }).then((result) => {
      let data = {
        nif: nif,
        fechaEncargo: formatoFechas(result.value.fecha),
      };
      
      editarAAFF(data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toast.success("Datos editados con éxito");
            
            setRecargar(!recargar);
          } else {
            let mensajes = [];

            res.data.error.map((err) => {
              mensajes = err;
              console.log(err.message);
              toast.error("error al editar");
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error al editar los datos");
        });
    });
  };

  const comunidadesRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "fechaEncargo",
      numeric: false,
      disablePadding: false,
      label: "FECHA ENCARGO",
      overrideFunc: (row) => (
        <Typography onClick={() => cambiarFechaEncargo(row.nifAAFF)} style={{ textAlign: 'left'}}>
            {row.fechaEncargo === null ? "-" : formatoFechasEs(row.fechaEncargo)}
        </Typography>
      ),
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "consultor",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR",
    },
    {
      id: "comercial",
      numeric: false,
      disablePadding: false,
      label: "COMERCIAL",
    },
    {
      id: "tecnico",
      numeric: false,
      disablePadding: false,
      label: "TECNICO",
    },
    {
      id: "comunidades",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES",
      overrideFunc: (row) => (
        <Typography>
          {row.length}
        </Typography>
      ),
    },
    // {
    //   id: "comunidadesVisitadas",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "COMUNIDADES VISITADAS",
    //   overrideFunc: (row) => (
    //     <ProgressBarWithColors
    //       value={
    //         (row?.filter((el) => el.visitada).length /
    //           (row?.filter((el) => el.pendiente).length +
    //             row?.filter((el) => el.visitada).length || 1)) *
    //         100
    //       }
    //       label={
    //         <Typography>
    //           {row?.filter((el) => el.visitada).length} /{" "}
    //           {row?.filter((el) => el.pendiente).length +
    //             row?.filter((el) => el.visitada).length}
    //         </Typography>
    //       }
    //     />
    //   ),
    // },
    // {
    //   id: "comunidadesRenovasVisitadas",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "COMUNIDADES RENOVADAS VISITADAS",
    //   overrideFunc: (row) => (
    //     <ProgressBarWithColors
    //       value={
    //         (row?.filter((el) => el.visitada).length /
    //           (row?.filter((el) => el.renovada).length || 1)) *
    //         100
    //       }
    //       label={
    //         <Typography>
    //           {row?.filter((el) => el.renovadaVisitada).length} /{" "}
    //           {row?.filter((el) => el.renovada).length}
    //         </Typography>
    //       }
    //     />
    //   ),
    // },
    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      overrideFunc: (row) => <Typography>{row.length}</Typography>,
    },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      overrideFunc: (row) => (
        <>
          {row > 0 ? 
            <img src={Warning} width={15} alt="pendientes"/>
          : null}
        </>
      ),
    },
    {
      id: "renovarComunidades",
      numeric: false,
      disablePadding: false,
      label: "RENOVAR COMUNIDADES",
      overrideFunc: (row) =>
        row?.properties?.filter((el) => el.renovada).length === row.length &&
        row?.properties.length > 0 ? (
          <img src={Tick} width="30px" alt="todas renovadas" />
        ) : (
          <Button className="btn-Secondary" variant="contained" color="secondary">
            <Link to={`/aaff/${row.nif}/renovar`} target={"_blank"} >Renovar</Link>
          </Button>
        ),
    },
  ];
  
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    fetchData({ offset: 1, limit: limite, filtros: filtros });
  } 

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  ADMINISTRADORES DE FINCAS
              </div>
          </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div className="contentBtns">
              {/* <div className="listadoBtn" style={{ marginLeft: 0}} >
                <BsUpload alt="subir listado" style={{ marginRight: 5 }} />
                listado
              </div>
              <div className="descargarBtn">
                <a href="files/plantillaAAFF.xlsx" download="plantillaAAFF.xlsx">
                  <BsDownload alt="Descargar plantilla" style={{ marginRight: 5 }} />
                  plantilla
                </a>
              </div> */}
              
          </div>
          <div className="contentButton">
            <Link to="/aaff/nuevo" target={"_blank"}>
              <img src={Add} alt="Más" />
            </Link>
          </div>
        </div>
        {/* <div className="buscador">
          <input
            type="text"
            name="razonSocial"
            value={busqueda.razonSocial}
            placeholder="razonSocial"
            onChange={handleChange}
            onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          ></input>
          <input
            type="text"
            name="nif"
            value={busqueda.nif}
            placeholder="Nif"
            onChange={handleChange}
            onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          ></input>
          <input
            type="text"
            name="emailContacto"
            value={busqueda.email}
            placeholder="Email"
            onChange={handleChange}
            onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
          ></input>
          <button onClick={() => getBusqueda()}>
            <BsSearch />
          </button>
        </div> */}
        <FiltrosAAFF onFilter={filtrarDatos} />  
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(AAFF);
