import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./App.scss";
import "./routes/pages/Page.css";
import "./../src/providers/pagination.css";
import "./routes/pages/proveedor/provStyles.css";
import Apagar from "./icons/apagar.svg";
import Menu from "./components/Menu/Menu";
import jwt_decode from "jwt-decode";
import ProtectedRoute from "./providers/ProtectedRoute";
import { logout } from "./services";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App(props) {
  let user = {
    exp: "",
    iat: "",
    sub: "",
    firstName: "",
    lastName: "",
    rol: "",
  };
  const state = localStorage.getItem("state");
  const token = localStorage.getItem("token");
  const expires = localStorage.getItem("expires");
  console.log(token);
  let f1 = new Date().getTime();
  let f2 = new Date(expires).getTime();
  let diff = f1 - f2;

  // console.log(diff/(1000*60*60) );
  let d = diff / (1000 * 60 * 60);
  // console.log(f1 , f2)
  // console.log(f1 > f2)
  if (d >= 24) {
    localStorage.removeItem("token");
    localStorage.removeItem("state");
    localStorage.removeItem("expires");
  }
  if (state && token !== null && expires !== null) {
    let decoded = jwt_decode(token);
    // console.log(jwt_decode(token).data.nombre)
    user = {
      exp: decoded.exp,
      iat: decoded.iat,
      email: decoded.email,
      rol: decoded.rol,
      nombre: decoded.data.nombre,
      //rol: 'admin'
    };
    //usuario();
  } else {
    logout();
  }
  const [mobile, setMobile] = useState(false);
  /* const [menuOpen, setMenuOpen] = useState(false); */
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    if (window.innerWidth <= 992) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }
  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {mobile ? (
          <div
            className="contenedor-global"
            style={{
              marginLeft: 0,
              /* display: 'grid',
          gridTemplateColumns: '170px auto',
          gridTemplateRows: '50px auto',
          height: '100vh',
          backgroundColor: '#eef3f8' */
            }}
          >
            <ProtectedRoute
              user={user}
              showSidebar={showSidebar}
              type={"menuMov"}
            />
          </div>
        ) : (
          <div
            className="contenedor-global"
            style={{ marginLeft: sidebar ? "300px" : "115px" }}
          >
            <ProtectedRoute
              user={user}
              showSidebar={showSidebar}
              type={"menu"}
            />
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}

export default App;
