import React from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createAAFF, getCommercials } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";

class NuevoAAFF extends React.Component {
  constructor() {
    super();
    this.state = {
      nif: "",
      razonSocial: "",
      municipio: "",
      email: "",
      telefono: "",
      provincia: "",
      contacto: "",
      nifComercial: null,
      comerciales: [],
      listadoMunicipios: []
    };
  }

  componentDidMount() {
    getCommercials().then((res) => {
      this.setState({ comerciales: res.data });
    });
  }

  crearAAFF = (e) => {
    e.preventDefault();
    console.log(this.state);
    createAAFF({
      nif: this.state.nif,
      razonSocial: this.state.razonSocial,
      municipio: this.state.municipio,
      provincia: this.state.provincia,
      email: this.state.email,
      telefono: this.state.telefono,
      contacto: this.state.contacto,
      commer_id: parseInt(this.state.nifComercial),
    });

    /* firestore.collection("aaff").doc(this.state.nif).get().then(snapshot => {
      if (snapshot.exists) {
        toast.error("Ya existe un Administrador de Fincas registrado con el NIF introducido")
      } else {
        firestore.collection("aaff").doc(this.state.nif?.toUpperCase()).set({ 
          nif: this.state.nif?.toUpperCase(), 
          razonSocial: this.state.razonSocial?.toUpperCase(), 
          municipio: this.state.municipio?.toUpperCase(), 
          email: this.state.email, 
          nifComercial: this.state.nifComercial?.toUpperCase(),
          telefono: this.state.telefono,
          contacto: this.state.contacto,
          provincia: this.state.provincia?.toUpperCase(), 
          nifTecnico: "", 
          nifConsultor: "",
        }).then(() => {
          toast.success("Administrador de Fincas creado correctamente")
          window.location.replace("/aaff/"+this.state.nif.toUpperCase()+"/comunidades")
        }).catch((err) => {
          toast.error("Ha ocurrido un error al crear el Administrador de Fincas")
          console.log(err)
        })
      } 
    })*/
  };

  
  seleccionarMunicipios(e){
    let provincia = ""
    let municipios = []
    provinces.map( p => {
      if(p.code === e.target.value){
        provincia = p.name
      }
    })
    this.setState({ provincia: provincia })
    console.log(e.target.value)    
    console.log(provincia)    
    console.log(municipalities)    
    municipalities.map( m => {
      if(m.provCode === e.target.value){
        console.log(m.code, e.target.value)
        municipios.push(m)
      }
    })
    this.setState({ listadoMunicipios: municipios})
    console.log(municipios)    
  }

  render() {
    return (
      <>
        {/* <div
          className="pageHeader"
          style={{
            backgroundImage: `url(${HeaderBackground})`,
            textAlign: "left",
          }}
        >
          Administradores de Fincas / <b>Nuevo</b>
        </div> */}
        <BreadCrumb rutas={[{path:'/aaff', label:"Administradores de fincas"}, {path:'/aaff', label:"Nuevo"}]} style={{ margin: '0 10px'}} />
        <div className="pageContent">
          <div className="gridHead">
            Crear nuevo Administrador de Fincas
          </div>
          <form className="nuevoAAFFForm" onSubmit={this.crearAAFF}>
            <Grid
              container
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="NIF"
                  pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                  title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
                  value={this.state.nif}
                  onChange={(e) => this.setState({ nif: e.target.value })}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Razón social"
                  value={this.state.razonSocial}
                  onChange={(e) =>
                    this.setState({ razonSocial: e.target.value })
                  }
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Telefono"
                  value={this.state.telefono}
                  onChange={(e) => this.setState({ telefono: e.target.value })}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <select
                  onChange={(e) =>
                    this.seleccionarMunicipios(e)
                  }
                >
                  <option value={this.state.provincia}>Provincia</option>
                  {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.code}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                {/* <input
                  type="text"
                  placeholder="Provincia"
                  value={this.state.provincia}
                  onChange={(e) => this.setState({ provincia: e.target.value })}
                ></input> */}
              </Grid>
              <Grid item xs={12} sm={6}>
              <select
                  onChange={(e) =>
                    this.setState({ municipio: e.target.value })
                  }
                >
                  <option value={this.state.municipio}>Municipio</option>
                  {this.state.listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                    return (
                      <option key={i} value={e.name}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
                {/* <input
                  type="text"
                  placeholder="Municipio"
                  value={this.state.municipio}
                  onChange={(e) => this.setState({ municipio: e.target.value })}
                ></input> */}
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  placeholder="Persona de contacto"
                  value={this.state.contacto}
                  onChange={(e) => this.setState({ contacto: e.target.value })}
                ></input>
              </Grid>
              <Grid item xs={12} sm={6}>
                <select
                  onChange={(e) =>
                    this.setState({ nifComercial: e.target.value })
                  }
                >
                  <option value={0}>Comercial</option>
                  {this.state.comerciales?.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.nombre + " " + e.apellidos}
                      </option>
                    );
                  })}
                </select>
              </Grid>
            </Grid>
            <input type="submit" value="Guardar y publicar"></input>
          </form>
        </div>
      </>
    );
  }
}

export default NuevoAAFF;
