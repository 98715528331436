import React, { useState } from 'react';
import { PDFViewer, Document, Page, Image, Text, View } from "@react-pdf/renderer";
import videovigilancia from '../../images/certificado_videovigilancia.png'
import { withRouter } from '../../providers/withRouter';
import { useEffect } from 'react';
import { getFinca } from '../../services';

const CertificadoVigilancia = (props) => {

    const backgroundImageUrl = videovigilancia; // Reemplaza con la URL de tu imagen de fondo

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    
    const [finca, setFinca] = useState({})
    
    useEffect(()=>{
        fetchData()
    },[])
    const customDocumentSize = {
        width: 460, // Ancho del documento
        height: 500, // Alto del documento
      };

    const fetchData = async () => {
        console.log(props.params)
        const response = await getFinca(props.params.nif)
        setFinca(response)
        console.log(response)
    }
    console.log(imageDimensions)
    return ( 
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document size={customDocumentSize}>
          <Page size={customDocumentSize}>
            <View>
                <Image src={backgroundImageUrl} style={{ width: 460, height: 500 }} />
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"240px",
                              color: 'black', 
                              fontSize: '11px'}}>
                  {finca.razonSocial}
                </Text>
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"292px",
                              color: 'black', 
                              fontSize: '11px'}}>
                  {finca.direccion}, {finca.codigoPostal}, {finca.municipio}, ({finca.provincia})
                </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
     );
}
 
export default withRouter(CertificadoVigilancia);